import DefaultPage from '../default-page';


class SchoolPage extends DefaultPage {


	prepare(element, done) {
		super.prepare(element, () => {
			const sectionActions = this.addNewComponent('SectionActions', {selector: this.dataSelector('sectionSchoolCover'), context: 'default'});
			const slideshow = this.addNewComponent('HeaderSlideshow', {selector: this.dataSelector('sectionSchoolCover'), context: 'default'});
			slideshow.setSectionActions(sectionActions);

			this.addNewComponent('ExclusionMenu', {selector: this.dataSelector('schoolFacts'), context: 'default'});

			this.infographicManager =  this.addNewComponent('InfographicManager', {selector: this.dataSelector('infographics'), context: 'default'});
			this.infographicManager.addNewComponent('BarInfographic', {selector: this.dataSelector('infographic', 'bar'), context: 'default'});
			this.infographicManager.addNewComponent('MapInfographic', {selector: this.dataSelector('infographic', 'map'), context: 'default'});


			this.addNewComponent('SchoolMore', {selector: this.dataSelector('schoolMore'), context: 'default'});

			this.addNewComponent('SchoolStudies', {selector: this.dataSelector('schoolStudies'), context: 'default'});

			done();
		});
	}

}


export default SchoolPage;
