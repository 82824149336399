import imagesLoaded from 'imagesloaded';
import PageComponent from '../page/page-component';


class Covers extends PageComponent {
	constructor({context = null, selector = true, config = {}, status = {}, safeMargin = 4} = {}) {
		super({context: context, selector: selector, config: config, status: status});
        this.safeMargin = safeMargin;
    }


    prepare(element, done) {
        this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
        this.covers = [];
		const covers = element.querySelectorAll('[data-cover]');
		for (let cover of covers) {
			if (cover.tagName.toLowerCase() === 'picture') {
				cover = cover.querySelector('img');
			}
			if (cover.tagName.toLowerCase() === 'img') {
				imagesLoaded(cover, () => {
					const entry = {
						element: cover,
						width: cover.naturalWidth,
						height: cover.naturalHeight,
					};
					entry.ratio = entry.width / entry.height;
					this.covers.push(entry);
					this.updateSingle(entry);
                });
			} else {
				let video;
				if (cover.tagName.toLowerCase() !== 'video') {
					video = cover.querySelector('video');
				} else {
					video = cover;
				}
				if (video) {
					const listener = this.events.on(video, 'loadedmetadata', () => {
						listener.destroy();
						const entry = {
							element: cover,
							width: video.videoWidth,
							height: video.videoHeight,
						};
						entry.ratio = entry.width / entry.height;
						this.covers.push(entry);
						this.updateSingle(entry);
					});
				}
			}
		}

        done();
    }


    clear(done) {
        this.resizeListener.destroy();
        this.covers = null;
        this.ratios = null;
    }


    onResize(event) {
        this.update();
    }


    update() {
        for (const entry of this.covers) {
			this.updateSingle(entry);
		}
    }


	updateSingle(entry) {
        const parentW = entry.element.parentNode.offsetWidth;
        const parentH = entry.element.parentNode.offsetHeight;
        const parentRatio = parentW / parentH;
        let newW;
        let newH;
        if (entry.ratio > parentRatio) {
            newH = parentH + this.safeMargin;
            newW = Math.round(newH * entry.ratio);
        } else {
            newW = parentW + this.safeMargin;
            newH = newW / entry.ratio;
        }
        entry.element.style.width = newW + 'px';
        entry.element.style.height = newH + 'px';
    }

}

export default Covers;
