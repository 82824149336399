import {dataAttr} from '../../global/utils/data-attr';
import {classList} from '../../global/utils/class-list';

import Factory from '../../global/di/factory';
import Events from '../../global/events/events';
import EventsAndGestures from '../../global/events/events-and-gestures';

import HistoryApi from '../../global/navigation/history-api';
import Navigation from '../../global/navigation/navigation';
import HashScroller from '../../global/navigation/hash-scroller';

import pageInteractionMixin from '../../global/utils/page-interaction-mixin';

import PageComponent from '../../global/page/page-component';
import pageComponentCollectionMixin from '../../global/page/page-component-collection-mixin';
import PageComponentCollection from '../../global/page/page-component-collection';
import PageComponentFactory from '../../global/page/page-component-factory';

import updateStatusMixin from '../../global/utils/update-status-mixin';

import Page from '../../global/page/page';
import PageFactory from '../../global/page/page-factory';
import PageLoader from '../../global/page/page-loader';
import PageManager from '../../global/page/page-manager';
import PageSlot from '../../global/page/page-slot';
import PageSlotFactory from '../../global/page/page-slot-factory';
import PageSlotManager from '../../global/page/page-slot-manager';
import PageTransition from '../../global/page/page-transition';
import PageTransitionFactory from '../../global/page/page-transition-factory';

import Context from '../../global/context/context';
import ContextFactory from '../../global/context/context-factory';
import ContextManager from '../../global/context/context-manager';

import Video from '../../global/media/video';
import Pictures from '../../global/media/pictures';
import Videos from '../../global/media/videos';
import VideoFactory from '../../global/media/video-factory';
import ExternalVideo from '../../global/media/external-video';
import Covers from '../../global/media/covers';
import MediaSequence from '../../global/media/media-sequence';

import Dialog from '../../global/dialog/dialog';

import App from '../../global/app/app';

import LoaderAnimation from '../../global/animation/loader-animation';

import FontChecker from '../../global/preload/font-checker';
import StyleChecker from '../../global/preload/style-checker';

import Slideshow from '../../global/slideshow/slideshow';
import Slide from '../../global/slideshow/slide';
import ImageSlide from '../../global/slideshow/image-slide';
import VideoSlide from '../../global/slideshow/video-slide';
import SlideFactory from '../../global/slideshow/slide-factory';
import SlideAnimator from '../../global/animation/slide-animator';
import ProgressAnimator from '../../global/animation/progress-animator';
import CircleProgressAnimator from '../../global/animation/circle-progress-animator';
import ProgressAnimatorFactory from '../../global/animation/progress-animator-factory';

import CoverGeometryTracker from '../../global/media/cover-geometry-tracker';
import MediaContrastTracker from '../../global/media/media-contrast-tracker';

import Animation from '../../global/animation/animation';

import GoogleMapApiLoader from '../../global/google/google-map-api-loader';
import GoogleMapFactory from '../../global/google/google-map-factory';
import GoogleMap from '../../global/google/google-map';
import YoutubeApiLoader from '../../global/google/youtube-api-loader';
import YoutubePlayerFactory from '../../global/google/youtube-player-factory';
import YoutubePlayer from '../../global/google/youtube-player';

import NavigationTracker from '../../global/navigation/navigation-tracker';
import PiwikNavigationTracker from '../../global/navigation/piwik-navigation-tracker';
import GoogleTagManagerNavigationTracker from '../../global/navigation/google-tag-manager-navigation-tracker';
import FbPixelNavigationTracker from '../../global/navigation/fb-pixel-navigation-tracker';
import ObPixelNavigationTracker from '../../global/navigation/ob-pixel-navigation-tracker';

import IntersectionTriggers from '../../global/animation/intersection-triggers';
import ParallaxAnimations from '../../global/animation/parallax-animations';
import DistancesDataParser from '../distances/distances-data-parser';

import LsaApp from '../app/lsa-app';
import LsaLoaderAnimation from '../animation/lsa-loader-animation';
import DefaultPage from '../page/default-page';
import HomePage from '../page/home/home-page';
import ReblogPage from '../page/reblog/reblog-page';
import ReblogArticlePage from '../page/reblog/reblog-article-page';
import SchoolPage from '../page/school/school-page';
import ManifestPage from '../page/manifest/manifest-page';
import FactPage from '../page/fact/fact-page';
import StudyPage from '../page/study/study-page';
import InfoPage from '../page/info/info-page';
import StoryPage from '../page/story/story-page';
import ErrorPage from '../page/error/error-page';
import LandingpagePage from '../page/landingpage/landingpage-page';
import ToolLandingPage from '../page/landingpage/tool-landing-page';
import DistancesPage from '../page/landingpage/distances-page.js';
import PersonalityDevelopmentPage from '../page/landingpage/personality-development';

import DefaultPageTransition from '../page/default-page-transition';
import DefaultDirectPageTransition from '../page/default-direct-page-transition';
import PanelPageTransition from '../page/panel-page-transition';

import DefaultContext from '../context/default-context';
import MenuContext from '../context/menu-context';
import PanelContext from '../context/panel-context';
import HomeMapContext from '../context/home-map-context';
import ContextSwitcher from '../context/context-switcher';

import MainMenu from '../menu/main-menu';

import SectionCollector from '../section/section-collector';
import SectionManager from '../section/section-manager';
import Section from '../section/section';
import SectionFactory from '../section/section-factory';
import SectionActions from '../section/section-actions';

import ExclusionMenu from '../menu/exclusion-menu';
import ColorSequence from '../effects/color-sequence';

import BasicSlideshow from '../slideshow/basic-slideshow';
import HeaderSlideshow from '../slideshow/header-slideshow';
import HomeSchools from '../page/home/home-schools';
import Teasers from '../page/home/teasers';

import Infographic from '../infographic/infographic';
import BarInfographic from '../infographic/bar-infographic';
import MapInfographic from '../infographic/map-infographic';
import InfographicManager from '../infographic/infographic-manager';

import SchoolMore from '../page/school/school-more';
import SchoolStudies from '../page/school/school-studies';
import Accordion from '../animation/accordion';
import Footnotes from '../footnotes/footnotes';

//import FaceFactory from '../face/face-factory';
// import CoverAnimation from '../cover-animation/cover-animation';
// import CoverAnimationController from '../cover-animation/cover-animation-controller';

import CoverVideoController from '../cover-animation/cover-video-controller';

//import faceConfig from '../face/face-config';
//import faceFiles from '../config/face-files';

import HomeGoogleMap from '../page/home/home-google-map';
import HomeMapManager from '../page/home/home-map-manager';
import googleMapConfig from './google-map-config';
import googleMapRegionConfig from './google-map-region-config';

import ReblogFeatured from '../page/reblog/reblog-featured';

import Distances from '../distances/distances';
import DistancesSlider from '../distances/distances-slider';
import DistancesMap from '../distances/distances-map';
import DistancesInfo from '../distances/distances-info';
import DistancesCircleIndicators from '../distances/distances-circle-indicators';


import ElternPage from '../../eltern/page/eltern/eltern-page';
import ElternSearchPage from '../../eltern/page/eltern-search/eltern-search-page';

import ElternSearch from '../../eltern/search/eltern-search';
import ElternSearchEngine from '../../eltern/search/eltern-search-engine';
import Search from '../../global/search/search';
import SearchEngine from '../../global/search/search-engine';


const config = (di) => {
	const prefix = 'lsa-';
	const classPrefix = 'lsa-js-';

	classList.prefix = classPrefix;
	dataAttr.prefix = prefix;

	const baseUrl = dataAttr(document.body).get('baseUrl');

	// generic config

	di
		.setType({type: Factory, params: {container: di}})

		.setType({type: Events})

		.setType({type: EventsAndGestures, parent: Events})

		.setType({
			type: HistoryApi, params: {
				events: di.lazyGet('events')
			}
		})

		.setType({
			type: Navigation, params: {
				events: di.lazyGet('events'),
				history: di.lazyGet('history'),
				baseUrl: baseUrl
			}
		})

		.setType({
			type: HashScroller, mixins: [pageInteractionMixin], params: {
				events: di.lazyGet('events')
			}
		})

		.setMixin({
			mixin: pageInteractionMixin, setters: {
				setEvents: di.lazyGet('events'),
				setPrefix: prefix,
				setClassPrefix: classPrefix,
				setClassList: classList,
				setDataAttr: dataAttr
			}
		})

		.setType({type: Context, name: 'Context', mixins: [pageInteractionMixin, pageComponentCollectionMixin]})

		.setType({type: ContextFactory, parent: Factory})

		.setType({
			type: ContextManager, mixins: [pageInteractionMixin], setters: {
				setContextFactory: di.lazyGet('contextFactory')
			}
		})

		.setType({
			type: PageComponent, mixins: [pageInteractionMixin], setters: {
				setContextManager: di.lazyGet('contextManager')
			}
		})

		.setType({type: PageComponentFactory, parent: Factory})

		.setMixin({
			mixin: pageComponentCollectionMixin, setters: {
				setComponentFactory: di.lazyGet('componentFactory')
			}
		})

		.setMixin({mixin: updateStatusMixin})

		.setType({type: PageComponentCollection, parent: PageComponent, mixins: [pageComponentCollectionMixin]})

		.setType({
			type: Page, name: 'Page', parent: PageComponentCollection, setters: {
				setPageSlotManager: di.lazyGet('pageSlotManager')
			}
		})

		.setType({type: PageFactory, parent: Factory})

		.setType({type: PageSlot, name: 'PageSlot'})

		.setType({type: PageSlotFactory, parent: Factory})

		.setType({
			type: PageSlotManager, params: {
				pageSlotFactory: di.lazyGet('pageSlotFactory')
			}
		})

		.setType({
			type: PageLoader, mixins: [pageInteractionMixin], setters: {
				setPageFactory: di.lazyGet('pageFactory')
			}
		})

		.setType({
			type: PageManager, params: {
				events: di.lazyGet('events'),
				navigation: di.lazyGet('navigation')
			}, setters: {
				setPageLoader: di.lazyGet('pageLoader'),
				setPageTransitionFactory: di.lazyGet('pageTransitionFactory')
			}
		})

		.setType({
			type: PageTransition, name: 'PageTransition', mixins: [pageInteractionMixin], setters: {
				setHistory: di.lazyGet('history'),
				setPageLoader: di.lazyGet('pageLoader'),
				setContextManager: di.lazyGet('contextManager')
			}
		})

		.setType({type: PageTransitionFactory, parent: Factory})

		.setType({
			type: App, parent: PageComponentCollection, setters: {
				setFontChecker: di.lazyNew(FontChecker),
				setStyleChecker: di.lazyNew(StyleChecker),
				setNavigation: di.lazyGet('navigation'),
				setPageManager: di.lazyGet('pageManager')
			}
		})

		.setType({type: LoaderAnimation, parent: PageComponent})

		.setType({type: FontChecker})
		.setType({type: StyleChecker})

		.setType({type: IntersectionTriggers, mixins: [pageInteractionMixin]})
		.setType({type: ParallaxAnimations, mixins: [pageInteractionMixin]})
		.setType({type: DistancesDataParser})

		.setType({
			type: Video, name: 'Video', mixins: [updateStatusMixin], params: {
				events: di.lazyGet('events'),
				prefix: prefix
			}
		})

		.setType({type: VideoFactory, parent: Factory})

		.setType({
			type: Videos, name: 'Videos', parent: PageComponent, setters: {
				setVideoFactory: di.lazyGet('videoFactory')
			}
		})

		.setType({type: ExternalVideo, name: 'ExternalVideo', parent: PageComponent})

		.setType({type: Pictures, name: 'Pictures', parent: PageComponent})

		.setType({type: Covers, name: 'Covers', parent: PageComponent})

		.setType({
			type: MediaSequence, name: 'MediaSequence', parent: PageComponent, params: {
				duration: 3
			}
		})


		.setType({type: ProgressAnimator})
		.setType({
			type: CircleProgressAnimator, parent: ProgressAnimator, name: 'CircleProgressAnimator', params: {
				lineWidth: 4,
				color: '#0FAAE6'
			}
		})
		.setType({type: ProgressAnimatorFactory, parent: Factory})
		.setType({type: Slide, mixins: [pageInteractionMixin]})
		.setType({type: ImageSlide, parent: Slide, name: 'ImageSlide'})
		.setType({type: VideoSlide, parent: Slide, name: 'VideoSlide'})
		.setType({type: SlideFactory, parent: Factory})
		.setType({type: SlideAnimator, mixins: [pageInteractionMixin]})
		.setType({
			type: Slideshow, parent: PageComponent, mixins: [updateStatusMixin], setters: {
				setSlideFactory: di.lazyGet('slideFactory'),
				setProgressAnimatorFactory: di.lazyGet('progressAnimatorFactory')
			}
		})

		.setType({type: CoverGeometryTracker, mixins: [pageInteractionMixin]})

		.setType({
			type: MediaContrastTracker, mixins: [pageInteractionMixin, updateStatusMixin], params: {
				darkThresholdPercent: 0.5,
				mixedThresholdPercent: 0.17,
				skipFrames: 10,
				samplingRate: 4,
				darkFlagName: 'coverDark',
				mixedFlagName: 'coverMixed',
				linkedElements: [document.body]
			}
		})

		.setType({type: Animation, mixins: [pageInteractionMixin, updateStatusMixin]})

		.setType({type: GoogleMapFactory, parent: Factory})

		.setType({
			type: GoogleMapApiLoader, params: {
				url: 'https://maps.googleapis.com/maps/api/js?key=%apiKey%&callback=%callback%',
				apiKey: 'AIzaSyBM_-gXVuY0fgqF3KIN-YiAbt_9wiWIJKU'
			}
		})

		.setType({
			type: GoogleMap, name: 'GoogleMap', parent: PageComponent, setters: {
				setApiLoader: di.lazyGet('googleMapApiLoader')
			}
		})

		.setType({type: YoutubePlayerFactory, parent: Factory})

		.setType({
			type: YoutubeApiLoader, params: {
				url: 'https://www.youtube.com/iframe_api'
			}
		})

		.setType({
			type: YoutubePlayer, name: 'YoutubePlayer', parent: PageComponent, setters: {
				setApiLoader: di.lazyGet('youtubeApiLoader')
			}
		})

		.setType({
			type: Dialog, mixins: [pageInteractionMixin], params: {
				element: document.body.querySelector(dataAttr().getSelector('dialog'))
			}, setters: {
				setContextManager: di.lazyGet('contextManager')
			}
		})

		.setType({
			type: NavigationTracker, params: {
				events: di.lazyGet('events')
			}
		})

		.setType({type: PiwikNavigationTracker, parent: NavigationTracker})

		.setType({type: GoogleTagManagerNavigationTracker, parent: NavigationTracker})

		.setType({
			type: FbPixelNavigationTracker, parent: NavigationTracker, params: {
				trackEventEnabled: true,
				clickTrackEnabled: true,
				clickTrackAttribute: dataAttr.prefix + 'fb-click-track'
			}
		})

		.setType({
			type: ObPixelNavigationTracker, parent: NavigationTracker, params: {
				trackEventEnabled: true,
				clickTrackEnabled: true,
				clickTrackAttribute: dataAttr.prefix + 'ob-click-track'
			}
		})
		;



	// project specific types:

	di
		.setType({
			type: LsaApp, parent: App, params: {
				requiredStyles: ['default'],
				requiredFonts: [
					{
						name: 'Neue Haas Unica W01',
						options: {weight: 'normal'}
					},
					{
						name: 'Neue Haas Unica W01',
						options: {weight: 'bold'}
					}
				]
			}, setters: {
				setLoaderAnimation: di.lazyGet('loaderAnimation'),
				setPageSlotManager: di.lazyGet('pageSlotManager'),
				setSectionManager: di.lazyGet('sectionManager'),
				setColorSequence: di.lazyGet('colorSequence'),
				setDialog: di.lazyGet('dialog'),
				setNavigationTracker: di.lazyNew(PiwikNavigationTracker),
				setGoogleTagManagerNavigationTracker: di.lazyNew(GoogleTagManagerNavigationTracker),
				setFbNavigationTracker: di.lazyNew(FbPixelNavigationTracker),
				setObNavigationTracker: di.lazyNew(ObPixelNavigationTracker),
				setHashScroller: di.lazyNew(HashScroller)
			}
		})

		.setType({
			type: LsaLoaderAnimation, name: 'LoaderAnimation', parent: LoaderAnimation, params: {
				selector: dataAttr().getSelector('loader')
			}
		})

		.setType({
			type: DefaultPage, name: 'DefaultPage', parent: Page, params: {
				pageSlot: 'default'
			}
		})

		.setType({type: HomePage, name: 'HomePage', parent: DefaultPage})

		.setType({type: ErrorPage, name: 'ErrorPage', parent: DefaultPage})

		.setType({type: LandingpagePage, name: 'LandingpagePage', parent: DefaultPage})

		.setType({type: ToolLandingPage, name: 'ToolLandingPage', parent: DefaultPage})

		.setType({type: PersonalityDevelopmentPage, name: 'PersonalityDevelopmentPage', parent: DefaultPage,
			setters: {
				injectIntersectionTriggers: di.lazyNew(IntersectionTriggers),
				injectParallaxAnimations: di.lazyNew(ParallaxAnimations)
			}
		})

		.setType({type: DistancesPage, name: 'DistancesPage', parent: DefaultPage})

		.setType({
			type: ReblogPage, name: 'ReblogPage', parent: DefaultPage, setters: {
				setLoaderAnimation: di.lazyGet('loaderAnimation')
			}
		})

		.setType({type: ReblogArticlePage, name: 'ReblogArticlePage', parent: DefaultPage})

		.setType({type: SchoolPage, name: 'SchoolPage', parent: DefaultPage})

		.setType({
			type: ManifestPage, name: 'ManifestPage', parent: DefaultPage, params: {
				pageSlot: 'panel'
			}
		})

		.setType({
			type: StoryPage, name: 'StoryPage', parent: DefaultPage, params: {
				pageSlot: 'panel'
			}
		})

		.setType({
			type: FactPage, name: 'FactPage', parent: DefaultPage, params: {
				pageSlot: 'panel'
			}
		})

		.setType({
			type: StudyPage, name: 'StudyPage', parent: DefaultPage, params: {
				pageSlot: 'panel'
			}
		})

		.setType({
			type: InfoPage, name: 'InfoPage', parent: DefaultPage, params: {
				pageSlot: 'panel'
			}
		})

		.setType({
			type: DefaultPageTransition, name: 'DefaultPageTransition', parent: PageTransition, setters: {
				setLoaderAnimation: di.lazyGet('loaderAnimation')
			}
		})
		.setType({
			type: PanelPageTransition, name: 'PanelPageTransition', parent: PageTransition, setters: {
				setLoaderAnimation: di.lazyGet('loaderAnimation')
			}
		})
		.setType({
			type: DefaultDirectPageTransition, name: 'DefaultDirectPageTransition', parent: PageTransition, setters: {
				setLoaderAnimation: di.lazyGet('loaderAnimation')
			}
		})

		.setType({
			type: DefaultContext, name: 'DefaultContext', parent: Context, params: {
				name: 'default',
				selector: dataAttr().getSelector('context', 'default')
			}
		})

		.setType({
			type: MenuContext, name: 'MenuContext', parent: Context, params: {
				name: 'menu',
				selector: dataAttr().getSelector('context', 'menu')
			}
		})
		.setType({
			type: PanelContext, name: 'PanelContext', parent: Context, params: {
				name: 'panel',
				selector: dataAttr().getSelector('context', 'panel')
			}, setters: {
				setNavigation: di.lazyGet('navigation')
			}
		})
		.setType({
			type: HomeMapContext, name: 'HomeMapContext', parent: Context, params: {
				name: 'homeMap',
				selector: dataAttr().getSelector('context', 'homeMap'),
				boxSelector: dataAttr().getSelector('sectionContext', 'homeMap'),
				mapSelector: dataAttr().getSelector('googleMapContainer')
			}
		})

		.setType({type: ContextSwitcher, name: 'ContextSwitcher', parent: PageComponent})

		.setType({
			type: MainMenu, name: 'MainMenu', parent: PageComponent, params: {
				selector: dataAttr().getSelector('context', 'menu'),
				context: 'menu'
			}
		})

		.setType({
			type: SectionCollector, name: 'SectionCollector', parent: PageComponent, setters: {
				setSectionManager: di.lazyGet('sectionManager')
			}
		})

		.setType({
			type: SectionManager, parent: PageComponent, setters: {
				setSectionFactory: di.lazyNew(SectionFactory)
			}
		})

		.setType({type: SectionFactory, parent: Factory})

		.setType({type: Section, name: 'Section', mixins: [pageInteractionMixin]})

		.setType({
			type: ColorSequence, parent: PageComponent, setters: {
				setElement: document.body
			}
		})

		.setType({
			type: ExclusionMenu, name: 'ExclusionMenu', parent: PageComponent, setters: {
				setColorSequence: di.lazyGet('colorSequence')
			}
		})

		.setType({
			type: HeaderSlideshow, parent: Slideshow, name: 'HeaderSlideshow', params: {
				slideSelector: dataAttr().getSelector('slide'),
				progressSelector: '.lsa-actions--slideshowControls [data-lsa-slideshow-control="next"] .progress'
			}, setters: {
				setSlideAnimator: di.lazyNew(SlideAnimator),
				setCoverGeometryTracker: di.lazyNew(CoverGeometryTracker),
				setMediaContrastTracker: di.lazyNew(MediaContrastTracker)
			}
		})

		.setType({
			type: BasicSlideshow, parent: Slideshow, name: 'BasicSlideshow', params: {
				slideSelector: dataAttr().getSelector('slide')
			}, setters: {
				setSlideAnimator: di.lazyNew(SlideAnimator)
			}
		})

		.setType({
			type: HomeSchools, name: 'HomeSchools', parent: ExclusionMenu, params: {
				context: 'default',
				selector: dataAttr().getSelector('homeSchools')
			}
		})

		.setType({type: Infographic, parent: PageComponent})
		.setType({type: BarInfographic, parent: Infographic, name: 'BarInfographic'})
		.setType({type: MapInfographic, parent: Infographic, name: 'MapInfographic'})
		.setType({type: InfographicManager, parent: PageComponentCollection, name: 'InfographicManager'})

		.setType({type: SchoolMore, name: 'SchoolMore', parent: PageComponent})

		.setType({type: SchoolStudies, name: 'SchoolStudies', parent: PageComponent})

		.setType({type: Accordion, name: 'Accordion', parent: PageComponent})

		.setType({type: Footnotes, name: 'Footnotes', parent: PageComponent})

		.setType({type: SectionActions, name: 'SectionActions', parent: PageComponent})

		// .setType({type: FaceFactory, params: {
		//         basePath: baseUrl + '/assets/img/masks/',
		//         faceConfig: faceConfig,
		// 		faceFiles: faceFiles
		//     }
		// })


		// .setType({type: CoverAnimation, parent: Animation, params: {
		//         faceFactory: di.lazyGet('faceFactory'),
		//         autoLoad: true,
		//         autoPlay: false,
		//         repeat: -1,
		//         repeatDelay: 8,
		//         speed: 1,
		//         eyesTrackAnimation: true,
		//         onResizeThreshold: 150,
		//         onMouseMoveThreshold: 50
		//     }
		// })

		.setType({type: CoverVideoController, name: 'CoverVideoController', parent: PageComponent})

		.setType({
			type: HomeGoogleMap, name: 'HomeGoogleMap', parent: GoogleMap, setters: {
				setMapConfig: googleMapConfig,
				setRegionConfig: googleMapRegionConfig
			}
		})

		.setType({
			type: HomeMapManager, name: 'HomeMapManager', parent: PageComponent, setters: {
				setGoogleMapFactory: di.lazyGet('googleMapFactory')
			}
		})


		.setType({
			type: Teasers, name: 'Teasers', parent: PageComponent, params: {
				context: 'default'
			}
		})

		.setType({
			type: ReblogFeatured, name: 'ReblogFeatured', parent: PageComponent, params: {
				context: 'default'
			}, setters: {
				setCoverGeometryTracker: di.lazyNew(CoverGeometryTracker),
				setMediaContrastTracker: di.lazyNew(MediaContrastTracker)
			}
		})

		.setType({type: Distances, name: 'Distances', parent: PageComponentCollection, params: {
			context: 'default',
				}, setters: {
					injectDataParser: di.lazyNew(DistancesDataParser)
				}
		})

		.setType({type: DistancesSlider, name: 'DistancesSlider', parent: PageComponent, mixins: [updateStatusMixin], params: {
			context: 'default',
				}, setters: {
				}
		})

		.setType({type: DistancesMap, name: 'DistancesMap', parent: PageComponent, params: {
			context: 'default',
				}, setters: {
				}
		})

		.setType({type: DistancesInfo, name: 'DistancesInfo', parent: PageComponent, params: {
			context: 'default',
				}, setters: {
				}
		})

		.setType({type: DistancesCircleIndicators, name: 'DistancesCircleIndicators', parent: PageComponentCollection, params: {
			context: 'default',
				}, setters: {
					setProgressAnimatorFactory: di.lazyGet('progressAnimatorFactory')
			}
		})

		.setType({type: ElternPage, name: 'ElternPage', parent: ReblogArticlePage, setters: {
			injectIntersectionTriggers: di.lazyNew(IntersectionTriggers),
			injectParallaxAnimations: di.lazyNew(ParallaxAnimations)
		}})

		.setType({type: ElternSearchPage, name: 'ElternSearchPage', parent: DefaultPage})

		.setType({type: ElternSearch, name: 'ElternSearch', parent: Search, params: {
				// relPath: di.lazyValue('relPath'),
				// projectUrls: di.lazyValue('projectUrls')
			}
		})

		.setType({type: ElternSearchEngine, name: 'ElternSearchEngine', parent: SearchEngine})

		.setType({type: Search, name: 'Search', parent: PageComponent, setters: {
				// injectTemplate: di.lazyGet('template/template'),
				injectSearchEngine: di.lazyGet('search/engine'),
				injectHistory: di.lazyGet('history')
			}
		})

		.setType({type: SearchEngine, name: 'SearchEngine'})

		;



	// setting services

	di
		.set('app', di.lazyNew(LsaApp))
		.set('history', di.lazyNew(HistoryApi))
		.set('navigation', di.lazyNew(Navigation))
		.set('events', di.lazyNew(EventsAndGestures))
		.set('componentFactory', di.lazyNew(PageComponentFactory))
		.set('pageFactory', di.lazyNew(PageFactory))
		.set('pageLoader', di.lazyNew(PageLoader))
		.set('pageManager', di.lazyNew(PageManager))
		.set('pageSlotFactory', di.lazyNew(PageSlotFactory))
		.set('pageSlotManager', di.lazyNew(PageSlotManager))
		.set('contextFactory', di.lazyNew(ContextFactory))
		.set('contextManager', di.lazyNew(ContextManager))
		.set('loaderAnimation', di.lazyNew(LsaLoaderAnimation))
		.set('pageTransitionFactory', di.lazyNew(PageTransitionFactory))
		.set('sectionManager', di.lazyNew(SectionManager))
		.set('videoFactory', di.lazyNew(VideoFactory))
		.set('colorSequence', di.lazyNew(ColorSequence))
		.set('slideFactory', di.lazyNew(SlideFactory))
		.set('progressAnimatorFactory', di.lazyNew(ProgressAnimatorFactory))
		//		.set('faceFactory', di.lazyNew(FaceFactory))
		.set('googleMapFactory', di.lazyNew(GoogleMapFactory))
		.set('googleMapApiLoader', di.lazyNew(GoogleMapApiLoader))
		.set('youtubePlayerFactory', di.lazyNew(YoutubePlayerFactory))
		.set('youtubeApiLoader', di.lazyNew(YoutubeApiLoader))
		.set('dialog', di.lazyNew(Dialog))
		//.set('IntersectionTriggers', di.lazyNew(IntersectionTriggers))
		//.set('ParallaxAnimations', di.lazyNew(ParallaxAnimations))
		.set('search/engine', di.lazyNew(ElternSearchEngine))
		;
};


export default config;
