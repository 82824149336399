import {isString} from '../utils/types';


class ProgressAnimator {

    constructor({element}) {
        if (isString(element)) {
            element = document.querySelector(element);
        }
        this.value = 0;
        this.element = element;
        this.animation = null;
    }


    getProgress() {
        return this.value;
    }


    setProgress(value, duration = 0) {
        this.animateTo(value, duration);
        return this;
    }


    fullRound(duration) {
        this.animate(0, 1, duration, false);
        return this;
    }


    animateTo(value, duration) {
        this.animate(this.value, value, duration, false);
        return this;
    }


    startLoop(duration) {
        this.animate(0, 1, duration, true);
        return this;
    }


    stop(value = null) {
    }


    animate(begin, end, duration, loop = false) {
    }

}


export default ProgressAnimator;
