import PageComponent from '../page/page-component';


class ExternalVideo extends PageComponent {

	constructor({
		context = null,
		selector = true,
		playerDataSelector = 'externalVideoPlayer',
		buttonDataSelector = 'externalVideoBtn',
		visibleClass = 'visiblePlayer'
	} = {}) {
		super({context: context, selector: selector});
		this.playerDataSelector = playerDataSelector;
		this.buttonDataSelector = buttonDataSelector;
		this.visibleClass = visibleClass;
		this.videoVisible = false;
	}


	prepare(element, done) {
		console.log('ExternalVideo prepare', element);
		this.clickListener = this.events.on(this.element, this.dataSelector(this.buttonDataSelector), 'click', this.onClick.bind(this));
		this.player = element.querySelector(this.dataSelector(this.playerDataSelector));
		done();
	}


	clear(done) {
		this.clickListener.destroy();
		done();
	}


	onClick(event) {
		console.log('onClick', event);
		event.preventDefault();
		if (!this.videoVisible) {
			this.player.innerHTML = this.dataAttr(this.player).get(this.playerDataSelector);
			this.classList(this.element).add(this.visibleClass);
			this.videoVisible = true;
		}
	}
}


export default ExternalVideo;
