import GoogleMap from '../../../global/google/google-map';


class HomeGoogleMap extends GoogleMap {

    setRegionConfig(regionConfig) {
        this.regionConfig = regionConfig;
    }


	prepare(element, done) {
		this.regionRect = null;
		this.scale = 1;
		super.prepare(element, done);
	}


    setMapViewportElement(element) {
        this.mapViewportElement = element;
        return this;
    }


    setMapShapeElement(element) {
        this.mapShapeElement = element;
        return this;
    }


    updateGeometry(done) {
        this.addIdleCallback(() => {
            const regionRect = this.fetchRegionGeometry();
            const center = this.latLngToPoint(this.map.getCenter());
            const shapeRect = this.mapShapeElement.getBoundingClientRect();
            const shapeRectX = shapeRect.left + shapeRect.width / 2;
            const shapeRectY = shapeRect.top + shapeRect.height / 2;
            const shapeToRegionX = shapeRectX - regionRect.x;
            const shapeToRegionY = shapeRectY - regionRect.y;
			console.log(shapeRectX, regionRect.x, shapeToRegionX, this.map.getCenter().lng(), this.pointToLatLng({x: center.x - shapeToRegionX, y: center.y - shapeToRegionY}).lng());
            const newCenter = this.pointToLatLng({x: center.x - shapeToRegionX, y: center.y - shapeToRegionY});
            this.map.setCenter(newCenter);
            this.addIdleCallback(done);
        });
    }


    fetchRegionGeometry() {
        this.regionRect = {};
        const leftTop = this.latLngToPoint(this.regionConfig.leftTop);
        const rightBottom = this.latLngToPoint(this.regionConfig.rightBottom);
        this.regionRect.left = leftTop.x;
        this.regionRect.top = leftTop.y;
        this.regionRect.right = rightBottom.x;
        this.regionRect.bottom = rightBottom.y;
        this.regionRect.width = this.regionRect.right - this.regionRect.left;
        this.regionRect.height = this.regionRect.bottom - this.regionRect.top;
        this.regionRect.ratio = this.regionRect.width / this.regionRect.height;
        this.regionRect.x = this.regionRect.left + (this.regionRect.width / 2);
        this.regionRect.y = this.regionRect.top + (this.regionRect.height / 2);
        return this.regionRect;
    }


    // onTrackedElementsUpdate(forceUpdate) {
    //     this.updateRegionGeometry();
    //     // const trackedRect = this.elementTracker.getValues();
    //     // const fromCenterX = trackedRect.x - trackedRect.parentX;
    //     // const fromCenterY = trackedRect.y - trackedRect.parentY;
    //     // const regionOffsetX = Math.round(this.regionRect.x - fromCenterX);
    //     // const regionOffsetY = Math.round(this.regionRect.y - fromCenterY);
    //     // // this.map.setCenter(this.regionConfig.center);
    //     // const newCenter = this.pointToLatLng({x: regionOffsetX, y: regionOffsetY});
    //     // this.forceUpdate = forceUpdate;
    //     // //this.map.setCenter(newCenter);
    //     // console.log('setting center', regionOffsetX, regionOffsetY, newCenter.lat(), newCenter.lng());
    // }
    //
    //
    // onMapIdle() {
    //     super.onMapIdle();
    //     console.log('idle');
    //     if (this.forceUpdate) {
    //         this.onTrackedElementUpdate(false);
    //     }
    // }



}

export default HomeGoogleMap;
