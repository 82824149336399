import {TweenMax} from 'gsap';
import PageComponent from '../../../global/page/page-component';


class SchoolStudies extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, transitionDuration = 0.4} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.transitionDuration = transitionDuration;
	}


    prepare(element, done) {
        this.filterListener = this.events.on(element, this.dataSelector('buttonMode', 'filter'), 'click', this.onFilterClick.bind(this), {capture: true});
        this.collapseListener = this.events.on(element, this.dataSelector('buttonMode', 'collapse'), 'click', this.onCollapseClick.bind(this), {capture: false});
        done();
    }


    clear(done) {
        this.filterListener.destroy();
        this.collapseListener.destroy();
        done();
    }


    onCollapseClick(event, target) {
        const category = target.closest(this.dataSelector('category'));
        const collapsed = this.dataAttr(category).get('collapsed');
        event.stopPropagation();
        if (collapsed) {
            let filter = this.dataAttr(category).get('filter');
            let button;
            if (filter) {
                button = category.querySelector(this.dataSelector('filter', filter));
            } else {
                button = category.querySelector(this.dataSelector('filter'));
                filter = this.dataAttr(button).get('filter');
            }
            this.openStudies(category, button, filter);
        } else {
            this.closeStudies(category);
        }
        this.dataAttr(category).set('collapsed', !collapsed);
    }


    onFilterClick(event, target) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (!this.dataAttr(target).get('current')) {
            const category = target.closest(this.dataSelector('category'));
            this.openStudies(category, target, this.dataAttr(target).get('filter'));
        }
    }


    openStudies(category, button, filter) {
        const body = category.querySelector(this.dataSelector('categoryBody'));
        this.dataAttr(category).set('filter', filter);
        const currentHeight = body.offsetHeight;
        const studies = body.querySelector(this.dataSelector('type', filter));
        const studiesHeight = studies.offsetHeight;
        for (const element of category.querySelectorAll(this.dataSelector('current', true))) {
            this.dataAttr(element).set('current', false);
        }
        this.dataAttr(studies).set('current', true);
        this.dataAttr(button).set('current', true);
        TweenMax.fromTo(body, this.transitionDuration, {height: currentHeight + 'px'}, {
            height: studiesHeight + 'px',
            ease: 'Power2.easeOut',
            onComplete: () => {
                body.style.removeProperty('height');
            }
        });
    }


    closeStudies(category) {
        const body = category.querySelector(this.dataSelector('categoryBody'));
        const currentHeight = body.offsetHeight;
        for (const element of category.querySelectorAll(this.dataSelector('current', true))) {
            this.dataAttr(element).set('current', false);
        }
        TweenMax.fromTo(body, this.transitionDuration, {height: currentHeight + 'px'}, {
            height: 0,
            ease: 'Power2.easeOut',
            onComplete: () => {
                body.style.removeProperty('height');
            }
        });
    }

}


export default SchoolStudies;
