import {getScrollTop} from 'get-scroll';
import queryString from 'query-string';
import PageComponent from '../page/page-component';
import {wait} from '../utils/wait';


class Search extends PageComponent {

	constructor({
		context = null, selector = true, config = {}, status = {},
		// element,
		// root,
		// resultsTpl = 'search/results',
		waitTime = 400, // ms
		qsChar = '?',
		qsSearchName = 's',
		qsPageName = 'p',
		qsTagsName = 't',
		delayedTrack = 2000,
		tagsSelector = 'searchTags',
		busyClass = 'busy',
		animationMinDuration = 1
	}) {
		super({context: context, selector: selector, config: config, status: status});


		this.queryString = queryString;
		// this.resultsTpl = resultsTpl;
		this.waitTime = waitTime;
		this.qsChar = qsChar;
		this.qsSearchName = qsSearchName;
		this.qsPageName = qsPageName;
		this.qsTagsName = qsTagsName;
		this.delayedTrack = delayedTrack;
		this.waitTimeout = null;
		this.trackTimeout = null;
		this.promise = Promise.resolve();
		this.lastValue = null;
		this.lastTags = [];
		this.tags = [];
		this.lastPage = 1;
		this.searchActive = false;
		this.eventsEnabled = false;
		this.urlChanged = false;
		this.tagsSelector = tagsSelector;
		this.busyClass = busyClass;
		this.animationMinDuration = animationMinDuration;
	}


	injectTemplate(template) {
		this.template = template;
	}


	injectSearchEngine(searchEngine) {
		this.searchEngine = searchEngine;
	}


	injectHistory(history) {
		this.history = history;
		this.historySupported = this.history.isSupported();
	}


	prepare(element, done) {
		this.baseUrl = this.dataAttr(element).get('baseUrl');
		// this.texts = this.dataAttr(element).get('texts');
		// this.tagsLimit = data.get('tagsLimit');
		this.input = element.querySelector(this.dataSelector('searchInput'));
		this.results = element.querySelector(this.dataSelector('searchResults'));
		this.tagsNode = element.querySelector(this.dataSelector(this.tagsSelector));
		this.listeners = {};
		// this.listeners.focus = this.events.on(this.input, 'focusin', this.onFocus.bind(this), {capture: true});
		// this.listeners.blur = this.events.on(this.input, 'focusout', this.onBlur.bind(this), {capture: true});
		this.listeners.change = this.events.on(this.input, 'input', this.onChange.bind(this));
		this.listeners.close = this.events.on(document.body, this.dataSelector('searchClose'), 'click', this.onClose.bind(this));
		this.listeners.click = this.events.on(element, this.dataSelector('searchResults') + ' a', 'click', this.onResultClick.bind(this), {capture: true});
		this.listeners.pageClick = this.events.on(element, this.dataSelector('toPage'), 'click', this.onPageClick.bind(this));
		this.listeners.tagClick = this.events.on(element, this.dataSelector('tagId'), 'click', this.onTagClick.bind(this));
		this.listeners.navigate = this.events.on(document, 'search:navigate', this.onNavigate.bind(this));
		this.listeners.searchFormSubmit = this.events.on(element, this.dataSelector('searchForm'), 'submit', this.onSearchFormSubmit.bind(this));

		done();
	}


	getInput() {
		return this.input;
	}


	clear() {
		return this.disableSearch();
	}


	onClose(event) {
		if (this.eventsEnabled) {
			if (this.urlChanged) {
				this.urlChanged = false;
				this.history.push(this.baseUrl, {}, document.title);
			}
			this.close();
		}
	}


	close() {
		this.disableSearch();
	}


	enableEvents() {
		this.eventsEnabled = true;
		return this;
	}


	disableEvents() {
		this.eventsEnabled = false;
		return this;
	}


	enableSearch(immediate = false) {
		if (!this.searchActive) {
			return this.setSearchActive(true, immediate);
		}
		return Promise.resolve();
	}


	disableSearch(immediate = false) {
		if (this.searchActive) {
			return this.setSearchActive(false, immediate);
		}
		return Promise.resolve();
	}


	setSearchActive(active = true, immediate = false) {
		this.searchActive = active;
		return Promise.resolve();
	}


	onChange(event) {
		if (this.eventsEnabled) {
			if (!this.searchActive && this.getInput().value.length) {
				this.enableSearch();
			}
			if (this.waitTimeout) {
				clearTimeout(this.waitTimeout);
			}
			this.waitTimeout = setTimeout(this.submit.bind(this), this.waitTime);
		}
	}


	submit(pushUrl = true, page = 1) {
		const value = this.getInput().value;
		const tags = this.tags;

		if (value !== this.lastValue || tags.join(',') !== this.lastTags.join(',') || page !== this.lastPage) {
			if (this.delayedTrack > 0 && this.trackTimeout) {
				clearTimeout(this.trackTimeout);
				this.trackTimeout = null;
			}
			this.lastValue = value;
			this.lastTags = tags.slice();
			this.classList(this.element).add(this.busyClass);

			this.promise = Promise.all([
				this.promise
					.then(() => this.searchEngine.search(value, tags, page))
					.then((results) => this.processResults(results, pushUrl)),
				wait(this.animationMinDuration)
			]).then(() => {
				this.classList(this.element).remove(this.busyClass);
			});
		}
		return this.promise;
	}


	processResults(results, pushUrl = true) {
		return new Promise((resolve) => {
			const input = this.getInput();
			if (input.value === results.params.inputSearch) {
				const hasTrailSpace = input.value.length && input.value.substr(-1) === ' ';
				input.value = results.params.sanitizedSearch + (hasTrailSpace ? ' ' : '');
			}
			this.lastPage = results.params.page;
			if (this.historySupported) {
				const params = {};
				if (results.params.sanitizedSearch.length) {
					params[this.qsSearchName] = results.params.sanitizedSearch;
				}
				if (this.lastPage !== 1) {
					params[this.qsPageName] = this.lastPage;
				}
				if (this.tags.length) {
					params[this.qsTagsName] = this.tags.join('.');
				}
				const qs = this.queryString.stringify(params);
				const url = this.baseUrl + (qs.length ? this.qsChar + qs : '');
				if (pushUrl) {
					this.urlChanged = true;
					this.history.push(url, {}, document.title);
				} else {
					this.history.replace(url, {}, document.title);
				}
			}
			// results.params.tagsLimit = this.tagsLimit;
			// results.params.texts = this.texts;
			this.results.innerHTML = results.elementsContent;
			this.tagsNode.innerHTML = results.tagsContent;
			this.dataAttr(this.tagsNode).set(this.tagsSelector, results.params.tags.length);
			// this.results.innerHTML = this.template.render(this.resultsTpl, results.elements, results.tags, results.params);
			window.scrollTo(0, 0);
			resolve();
			// if (this.delayedTrack > 0) {
			// 	if (this.trackTimeout) {
			// 		clearTimeout(this.trackTimeout);
			// 		this.trackTimeout = null;
			// 	}
			// 	this.trackTimeout = setTimeout(() => {
			// 		const tags = [];
			// 		for (const tag of results.tags) {
			// 			if (parseInt(tag.selected, 10)) {
			// 				tags.push(tag.id);
			// 			}
			// 		}
			// 		this.searchEngine.track(results.params.inputSearch, tags, results.params.page);
			// 	}, this.delayedTrack);
			// }
		});
	}


	onSearchFormSubmit(event, target) {
		event.preventDefault();
	}

	onPageClick(event, target) {
		const page = this.dataAttr(target).get('toPage');
		this.submit(true, page);
	}


	onTagClick(event, target) {
		const id = String(this.dataAttr(target).get('tagId'));
		const currentIndex = this.tags.indexOf(id);
		if (currentIndex >= 0) {
			this.tags.splice(currentIndex, 1);
		} else {
			this.tags.push(id);
			this.tags.sort((a, b) => a - b);
		}
		this.submit(true);
		event.preventDefault();
	}


	// onFocus(event) {
	// 	if (this.eventsEnabled) {
	// 		this.focus(event);
	// 	}
	// }


	// onBlur(event) {
	// 	if (this.eventsEnabled) {
	// 		this.blur(event);
	// 	}
	// }


	onNavigate(event) {
		const requestType = event.detail.request.navigationType;
		const params = this.getUrlParams();
		if (requestType !== 'current' && this.eventsEnabled && params !== null) {
			event.preventDefault();
			this.processUrlParams(params);
		}
	}


	processUrlParams(params) {
		if (params !== null && (this.qsSearchName in params && params[this.qsSearchName].length || this.qsTagsName in params)) {
			const search = this.qsSearchName in params ? params[this.qsSearchName] : '';
			const page = params[this.qsPageName];
			const tags = this.qsTagsName in params ? params[this.qsTagsName].split('.') : [];
			const state = this.historySupported ? this.history.getState() : {};

			this.disableEvents();
			const input = this.getInput();
			input.focus();
			input.value = search;
			this.tags = tags;
			this.urlChanged = true;
			this.enableSearch(true)
				.then(() => this.submit(false, page))
				.then(() => input.focus())
				.then(() => this.enableEvents())
				.then(() => {
					if (this.historySupported && 'searchScrollTop' in state) {
						window.scrollTo(0, state.searchScrollTop);
						this.history.mergeState({searchScrollTop: state.searchScrollTop});
					}
				})
				;
		} else {
			this.enableEvents();
		}
	}


	getUrlParams() {
		const params = this.queryString.parse(location.search);
		if ((this.qsSearchName in params) || (this.qsTagsName in params)) {
			return params;
		}
		return null;
	}


	start() {
		this.enableEvents();
		// this.processUrlParams(this.getUrlParams());
	}


	stop() {
		this.disableEvents();
		this.disableSearch();
	}


	onResultClick(event) {
		if (this.eventsEnabled) {
			this.disableEvents();
			this.firstStart = true;
			if (this.historySupported) {
				this.history.mergeState({searchScrollTop: Math.round(getScrollTop())});
			}

			this.close();
		} else {
			event.preventDefault();
			event.stopPropagation();
		}
	}
}


export default Search;
