import DefaultPage from '../default-page';


class StudyPage extends DefaultPage {

    // setFootnotesFactory(footnotesFactory) {
    //     this.footnotesFactory = footnotesFactory;
    // }
	//
	//
    // init(event, done, target, params) {
    //     super.init(event, () => {
    //         this.footnotes = this.footnotesFactory.newInstance();
    //         this.footnotes.init(event, () => {
    //             done();
    //         }, target, params);
    //     }, target, params);
    // }
	//
	//
    // deinit(event, done, target, params) {
    //     super.deinit(event, () => {
    //         this.footnotes.deinit(event, () => {
    //             done();
    //         }, target, params);
    //     }, target, params);
    // }

}


export default StudyPage;
