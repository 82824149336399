// top: 53.04781795911469 11.612548828125
// bottom 50.93420001306366 12.227783203125
// left 52.01193653675364 10.557861328125
// right 51.713416052417614 13.18359375

const regionConfig = {
    leftTop: {
        lat: 53.04781795911469,
        lng: 10.557861328125
    },

    rightTop: {
        lat: 53.04781795911469,
        lng: 13.18359375
    },

    leftBottom: {
        lat: 50.93420001306366,
        lng: 10.557861328125
    },

    rightBottom: {
        lat: 50.93420001306366,
        lng: 13.18359375
    }
};

regionConfig.center = {
    lat: (regionConfig.leftTop.lat + regionConfig.leftBottom.lat) / 2,
    lng: (regionConfig.leftTop.lng + regionConfig.rightTop.lng) / 2,
};


export default regionConfig;
