import DefaultPageTransition from './default-page-transition';

class DefaultDirectPageTransition extends DefaultPageTransition {
	match(event) {
		return event.detail.navigationType === 'link' &&
			this.dataAttr(event.detail.originalTarget).get('contextTarget') === 'defaultDirect';
	}

	beginAnimation(done) {
		this.loaderAnimation.play(done, {cover: false});
	}
}

export default DefaultDirectPageTransition;
