import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import pageInteractionMixin from '../utils/page-interaction-mixin';
import {getScrollTop} from 'get-scroll';


class HashScroller extends pageInteractionMixin() {

    constructor({events, duration = 2, durationShort = 1, thresholdDistance = 1500}) {
		super();
		this.events = events;
		this.duration = duration;
		this.durationShort = durationShort;
		this.thresholdDistance = thresholdDistance;
		this.events.on(document, 'history:hashchange', this.onHashChange.bind(this));
    }


	onHashChange(event) {
		const hash = event.detail.hash;
		if (hash !== null) {
			let element = document.querySelector('#' + hash);
			if (!element) {
				element = document.querySelector(this.dataSelector('hash', hash));
			}
			if (element) {
				requestAnimationFrame(() => {
					const position = getScrollTop() + element.getBoundingClientRect().top;
					const duration = Math.abs(element.getBoundingClientRect().top) > this.thresholdDistance ? this.duration : this.durationShort;
					TweenMax.to(window, duration, {scrollTo: {y: position, autoKill: true}});
				});
			}
		}
	}

}


export default HashScroller;
