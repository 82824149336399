import Factory from '../../global/di/factory';


class SectionFactory extends Factory {

	constructor({container, prefix = '', suffix = 'Section'}) {
		super({container: container, prefix: prefix, suffix: suffix});
    }

}

export default SectionFactory;
