import PageComponent from '../../global/page/page-component';


class Infographic extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {played: false, available: false}} = {}) {
		super({context: context, selector: selector, config: config, status: status});
	}


    prepare(element, done) {
		this.initGraph();
		done();
    }


    clear(done) {
        this.deinitGraph();
		done();
    }


    run() {
        if (!this.status.played) {
            this.status.played = true;
            this.play();
        }
        return this;
    }


    isPlayed() {
        return this.status.played;
    }


    // method to be overriden in child classes

    initGraph() {
    }


    deinitGraph() {
    }


    play() {
    }

}


export default Infographic;
