import Context from '../../global/context/context';
import threeStateTransition from '../../global/utils/three-state-transition';


class MenuContext extends Context {

	constructor({name, selector, fixedClass = 'fixed', inClass = 'active'}) {
        super({name: name, selector: selector});
        this.fixedClass = fixedClass;
        this.inClass = inClass;

        this.nextSibling = null;
        this.parentNode = null;
    }


    beforeSwitchIn(done, otherContext) {
        this.raiseLevel();
        super.beforeSwitchIn(done, otherContext);
    }


    switchIn(done, otherContext) {
        const element = this.getElement();
		threeStateTransition.add(element, this.inClass, () => {
			super.switchIn(done, otherContext);
		});
    }


    switchOut(done, otherContext) {
        const element = this.getElement();
        this.classList(element).add(this.fixedClass);

		threeStateTransition.remove(element, this.inClass, () => {
			this.classList(element).remove(this.fixedClass);
            this.restoreLevel();
			super.switchOut(done, otherContext);
		});
    }


    raiseLevel() {
        const element = this.getElement();
        this.nextSibling = element.nextSibling;
        this.parentNode = element.parentNode;
        element.parentNode.removeChild(element);
        document.body.appendChild(element);
        return this;
    }


    restoreLevel() {
        const element = this.getElement();
        document.body.removeChild(element);
        if (this.nextSibling) {
            this.parentNode.insertBefore(element, this.nextSibling);
        } else {
            this.parentNode.appendChild(element);
        }
        return this;
    }

}


export default MenuContext;
