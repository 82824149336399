import 'dom4';
import {classList} from './class-list';
import transitionEnd from './transition-end';
import {ucFirst} from './string';
import {isString} from './types';


const applyThreeStateTransition = (element, name, done, add) => {
	let beforeName;
	let afterName;
	let duringName;
	if (isString(name)) {
		afterName = name;
		beforeName = 'before' + ucFirst(afterName);
		duringName = 'during' + ucFirst(afterName);
	} else {
		beforeName = name.before;
		afterName = name.after;
		duringName = name.during;
	}
	if (!add) {
		const swap = afterName;
		afterName = beforeName;
		beforeName = swap;
	}

	const classes = classList(element);
	requestAnimationFrame(() => {
		classes.toggle(beforeName, add);
		requestAnimationFrame(() => {
			classes.toggle(duringName, add);
			transitionEnd(element, () => {
				requestAnimationFrame(() => classes.toggle(afterName));
				done();
			});
		});
	});
};



const threeStateTransition = {
	add: (element, name, done = () => {}) => applyThreeStateTransition(element, name, done, true),
	remove: (element, name, done = () => {}) => applyThreeStateTransition(element, name, done, false)
};

export default threeStateTransition;
