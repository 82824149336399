import escape from '../../../global/utils/escape';

function item(data) {
    return `
        <li class="lsa-infographicGrid__item" style="bottom: ${data.percent}%">
            <span class="lsa-infographicGrid__itemLabel">${escape(data.value)}</span>
        </li>
    `;
}


export default function tpl(data) {
    return `
        <ul class="lsa-infographicGrid__items">
    		${data.map(item).join('')}
    	</ul>
    `;
}
