import PageComponent from '../../global/page/page-component';


class Footnotes extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, forceLinkTarget = '_blank', hover = true} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.forceLinkTarget = forceLinkTarget;
		this.hover = hover;
	}


    prepare(element, done) {
        this.current = null;
        if (this.forceLinkTarget) {
            for (const link of this.element.querySelectorAll(this.dataSelector('footnote') + ' a')) {
                link.setAttribute('target', this.forceLinkTarget);
            }
        }
		const events = this.hover ? 'mouseover touchend' : 'click';
		console.log('preparing footnotes', events);
        this.openListener = this.events.on(this.element, this.dataSelector('footnoteLink'), events, this.onOver.bind(this), {passive: true});
        this.closeListener = this.events.on(document, 'click', this.onClose.bind(this));
        done();
    }


    clear(done) {
        this.openListener.destroy();
        this.closeListener.destroy();
        this.current = null;
        this.openListener = null;
        this.closeListener = null;
        done();
    }


    onOver(event, target) {
        target = target.closest(this.dataSelector('footnoteLink'));
        if (target !== this.current) {
            if (this.current) {
                this.classList(this.current).remove('active');
            }
            this.classList(this.element).add('footnoteActive');
            this.current = target;
            this.classList(this.current).add('active');
        }
		if (!this.hover) {
			event.stopImmediatePropagation();
			event.stopPropagation();
		}
    }


    onClose(event, target) {
        if (this.current && !event.target.closest(this.dataSelector('footnoteLink'))) {
            this.classList(this.current).remove('active');
            this.classList(this.element).remove('footnoteActive');
            this.current = null;
        }
    }

}


export default Footnotes;
