import ReblogArticlePage from '../../../lsa/page/reblog/reblog-article-page.js';


class ElternPage extends ReblogArticlePage {


	injectIntersectionTriggers(intersectionTriggers) {
		this.intersectionTriggers = intersectionTriggers;
	}


	injectParallaxAnimations(parallaxAnimations) {
		this.parallaxAnimations = parallaxAnimations;
	}


	prepare(element, done) {
		this.classList(document.body).add('elternBgColors');

		super.prepare(element, () => {
			this.addNewComponent('Accordion', {selector: this.dataSelector('accordion'), context: 'default'});
			this.addNewComponent('ExclusionMenu', {selector: this.dataSelector('elternTopicIntro'), context: 'default'});
			this.intersectionTriggers.init(this.element, [this.dataSelector('intersect')]);
			this.parallaxAnimations.init(this.element.querySelectorAll(this.dataSelector('parallaxSpeed')));

			this.addNewComponent('Distances',  {selector: this.dataSelector('distances'), context: 'default'});
			// const el = element.querySelector(this.dataSelector('distances'));
			// const distances = this.addNewComponent('Distances');
			// distances.setElement(el);

			done();
		});
	}


	clear(done) {
		this.classList(document.body).remove('elternBgColors');
		this.intersectionTriggers.destroy();
		this.parallaxAnimations.destroy();
		done();
	}
}


export default ElternPage;
