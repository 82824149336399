import httpRequest from 'superagent';
import DefaultPage from '../default-page';


const removePageParameterFromUrl = (url = window.location.href) => (
	url
		.replace(/\/page[:;]\d/, '')
		.replace(/\/$/, '') // trailing slash
);


class ReblogPage extends DefaultPage {
	setLoaderAnimation(loaderAnimation) {
		this.loaderAnimation = loaderAnimation;
	}

	prepare(element, done) {
		super.prepare(element, () => {
			this.initPagination(element);
			const featuredElement = element.querySelector(this.dataSelector('reblogFeatured'));
			if (featuredElement) {
				const featured = this.addNewComponent('ReblogFeatured');
				featured.setElement(featuredElement);
			}
			done();
		});
	}


	clear(done) {
		super.clear(() => {
			this.moreClickListener.destroy();
			done();
		});
	}


	initPagination(element) {
		this.pagingElement = element.querySelector(this.dataSelector('reblogPagination'));
		this.pagingContainerElement = this.pagingElement.querySelector(this.dataSelector('reblogPaginationContainer'));

		const pagingOptions = this.dataAttr(this.pagingElement).get('reblogPagination');
		this.currentPage = pagingOptions.page;
		this.lastPage = pagingOptions.pages;
		this.separator = this.dataAttr(this.pagingElement).get('reblogSeparator');

		this.moreClickListener = this.events.on(this.pagingElement, this.dataSelector('reblogPaginationMoreLink'), 'click', this.onMoreClick.bind(this), {capture: true, passive: false});
	}


	onMoreClick(event) {
		event.preventDefault();
		event.stopPropagation();
		this.loadMore();
	}


	loadMore() {
		if (this.isBusy) {
			return;
		}

		this.isBusy = true;
		this.loaderAnimation.play();

		const url = removePageParameterFromUrl();
		httpRequest
			.get(`${url}/page${this.separator}${this.currentPage + 1}`)
			.timeout(10000)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', 'application/json')
			.query({json: true, articlesOnly: true})
			.end((error, response) => {
				this.isBusy = false;
				this.loaderAnimation.pause();

				if (error || !response.ok) {
					if (!error.response) {
						error.response = response;
					}

					this.onLoadMoreError(error, response);
				} else {
					this.onLoadMoreSuccess(response.body);
				}
			});

		this.updateLoadMoreButtons();
	}


	updateLoadMoreButtons() {
		for (const loadMoreButton of this.pagingElement.queryAll(this.dataSelector('reblogPaginationMoreLink'))) {
			this.classList(loadMoreButton).toggle('disabled', this.isBusy);
			this.classList(loadMoreButton).toggle('hidden', this.currentPage === this.lastPage);
		}
	}


	onLoadMoreSuccess(response) {
		this.currentPage = response._meta.page;
		this.lastPage = response._meta.pages;
		this.pagingContainerElement.innerHTML += response.content;
		this.updateLoadMoreButtons();
	}


	onLoadMoreError(error, response) {
		this.updateLoadMoreButtons();
	}

}

export default ReblogPage;
