import DefaultPage from '../default-page';

class ReblogArticlePage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('Footnotes', {context: 'default', hover: false});

			const playerElements = element.querySelectorAll(this.dataSelector('player'));
			if (playerElements.length) {
				// youtube video, 16:9 format
				const width = element.getBoundingClientRect().width;
				const height = Math.round(width * 9 / 16);
				for (const playerElement of playerElements) {
					const player = this.addNewComponent('YoutubePlayer', {
						context: 'default',
						width: width,
						height: height,
						videoOptions: {
							autoplay: 0,
							enablejsapi: 1,
							hl: 'de',
							loop: 0,
							modestbranding: 1,
							playsinline: 1,
							rel: 0,
							showinfo: 0,
						}
					});
					player.setElement(playerElement);
				}
			}
			done();
		});
	}

}

export default ReblogArticlePage;
