import random from '../../global/utils/random';
import PageComponent from '../../global/page/page-component';



class ColorSequence extends PageComponent {

	prepare(element, done) {
        this.limit = this.dataAttr(element).get('colorLimit');
        this.current = random(0, this.limit - 1);
        this.next();
    }

    next() {
        this.current = (this.current + 1) % this.limit;
        this.dataAttr(this.element).set('colorIndex', this.current);
        return this;
    }
}


export default ColorSequence;
