// needed to interact with youtube api
/*eslint-disable piggyback/no-restricted-global-extend*/


class YoutubeApiLoader {

    constructor({url, callbackName = 'onYouTubeIframeAPIReady'}) {
        this.url = url;
        this.callbackName = callbackName;
        this.script = null;
        this.loaded = false;
        this.loading = false;
        this.youtube = null;
        this.pendingCallbacks = [];
    }


    onReady(callback) {
        if (this.loaded) {
            callback(this.youtube);
        } else {
            this.pendingCallbacks.push(callback);
            if (!this.loading) {
                this.load();
            }
        }
        return this;
    }


    load() {
        this.loading = true;
        window[this.callbackName] = this.onYouTubeIframeAPIReady.bind(this);
        this.script = document.createElement('script');
        this.script.async = true;
        this.script.defer = true;
        this.script.setAttribute('src', this.url);
        document.body.appendChild(this.script);
        return this;
    }


    onYouTubeIframeAPIReady() {
        this.youtube = window.YT;
        this.loading = false;
        this.loaded = true;
        this.processPendingCallbacks();
    }


    processPendingCallbacks() {
        for (const callback of this.pendingCallbacks) {
            callback(this.youtube);
        }
        this.pendingCallbacks = [];
        return this;
    }


    isLoading() {
        return this.loading;
    }


    isLoaded() {
        return this.loaded;
    }

}


export default YoutubeApiLoader;
