// //import {getScrollTop} from 'get-scroll';
import asq from 'asynquence';
import {trimUrl} from '../../global/utils/string';
import PageTransition from '../../global/page/page-transition';


class DefaultPageTransition extends PageTransition {

	setLoaderAnimation(loaderAnimation) {
		this.loaderAnimation = loaderAnimation;
	}


	start(done) {
		const currentContext = this.contextManager.getCurrentContext();
        if (
			this.request.navigationType === 'pop' &&
			currentContext.getName() === 'panel' &&
			trimUrl(this.dataAttr(currentContext.getElement()).get('backUrl')) === trimUrl(this.request.url)
		) {
			const sequence = asq();
			sequence
				.then((stepDone) => this.contextManager.pop(() => this.commit(stepDone)))
				.then(() => this.reset(done))
			;
        } else {
            super.start(done);
        }
	}


	rollback(done) {

	}


	beginAnimation(done) {
		this.loaderAnimation.play(done, {cover: true});
	}


	endAnimation(done) {
		this.loaderAnimation.pause(done);
	}

}


export default DefaultPageTransition;
