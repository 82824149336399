import PageComponent from '../page/page-component';


class GoogleMap extends PageComponent {

	constructor({context = null, selector = true, config = {async: true}, status = {working: true, available: false}}) {
		super({context: context, selector: selector, config: config, status: status});
		this.mapElement = null;
		this.google = null;
        this.map = null;
		this.idleCallbacks = new Map();
	}


	setMapConfig(mapConfig) {
		this.mapConfig = mapConfig;
	}

	setApiLoader(apiLoader) {
		this.apiLoader = apiLoader;
	}


    prepare(element, done) {
        this.mapElement = this.element.querySelector(this.dataSelector('googleMap'));
        this.apiLoader.onReady((google) => {
            this.onApiReady(google, done);
        });
    }


    clear(done) {

    }


    addIdleCallback(callback, once = true) {
        if (!this.status.working) {
            callback();
            if (once) {
                return this;
            }
        }
        this.idleCallbacks.set(callback, once);
        return this;
    }


    onApiReady(google, done) {
        this.google = google;
        this.addIdleCallback(done, true);
        this.initMap();
    }


    initMap() {
        this.map = new this.google.maps.Map(this.mapElement, this.mapConfig);
        this.google.maps.event.addListener(this.map, 'bounds_changed', this.onMapChange.bind(this));
        this.google.maps.event.addListener(this.map, 'center_changed', this.onMapChange.bind(this));
        this.google.maps.event.addListener(this.map, 'resize', this.onMapChange.bind(this));
        this.google.maps.event.addListener(this.map, 'idle', this.onMapIdle.bind(this));
    }


    getLatLng(lat, lng) {
        return new this.google.maps.LatLng(lat, lng);
    }


    // http://stackoverflow.com/questions/1538681/how-to-call-fromlatlngtodivpixel-in-google-maps-api-v3
    latLngToPoint(position) {
        if (!(position instanceof this.google.maps.LatLng)) {
            position = this.getLatLng(position.lat, position.lng);
        }

        const scale = Math.pow(2, this.map.getZoom());
        const bounds = this.map.getBounds();

        const nw = this.projection.fromLatLngToPoint(this.getLatLng(
            bounds.getNorthEast().lat(),
            bounds.getSouthWest().lng()
        ));
        const point = this.projection.fromLatLngToPoint(position);

        return new this.google.maps.Point(
            Math.floor((point.x - nw.x) * scale),
            Math.floor((point.y - nw.y) * scale)
        );
    }


    // http://stackoverflow.com/questions/1538681/how-to-call-fromlatlngtodivpixel-in-google-maps-api-v3
    pointToLatLng(position) {
        const scale = Math.pow(2, this.map.getZoom());
        const bounds = this.map.getBounds();

        const nw = this.projection.fromLatLngToPoint(new this.google.maps.LatLng(
            bounds.getNorthEast().lat(),
            bounds.getSouthWest().lng()
        ));
        const point = new this.google.maps.Point();

        point.x = position.x / scale + nw.x;
        point.y = position.y / scale + nw.y;

        return this.projection.fromPointToLatLng(point);
    }


    onMapChange() {
        this.status.working = true;
		console.log(this.map.getCenter().lng());
    }


    onMapIdle() {
        if (!this.status.available) {
            this.status.available = true;
            this.projection = this.map.getProjection();
            this.events.trigger(this.mapElement, 'map:ready', {
                wrapper: this
            });
        }

        const onces = [];
        for (const [callback, once] of this.idleCallbacks) {
            callback();
            if (once) {
                onces.push(callback);
            }
        }

        for (const callback of onces) {
            this.idleCallbacks.delete(callback);
        }

        this.events.trigger(this.mapElement, 'map:idle', {
            wrapper: this
        });

        this.status.working = false;
    }

}

export default GoogleMap;
