import {TimelineMax} from 'gsap';
import PageComponent from '../page/page-component';


class MediaSequence extends PageComponent {

    constructor({context = null, selector = true, config = {}, status = {}, slidesSelector = '> *', selectedClass = 'mediaSelected', duration = 3} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.slidesSelector = slidesSelector;
        this.duration = duration;
		this.selectedClass = selectedClass;
    }


    prepare(element, done) {
		this.isMobile = !!document.querySelector('html.mobile');
        if (this.dataAttr(element).has('sequenceDuration')) {
            this.duration = this.dataAttr(element).get('sequenceDuration');
        }
		if (this.dataAttr(element).has('sequenceSlideSelector')) {
            this.slidesSelector = this.dataAttr(element).get('sequenceSlideSelector');
        }
        this.slides = element.querySelectorAll(this.slidesSelector);
        this.videos = new Map();
		this.played = {};
        this.currentIndex = 0;
		this.currentVideo = null;
        this.inside = false;
		this.timeline = null;
        if (this.slides.length) {
            this.classList(this.slides[this.currentIndex]).add(this.selectedClass);

			this.observer = new IntersectionObserver(this.onInside.bind(this), {
				threshold: [0]
			});
			this.observer.observe(element);
        }
        done();
    }


    clear(done) {
		this.observer.disconnect();
        this.pauseTimer();
        this.pause();
        this.deinitTimer();
        this.videos = null;
        done();
    }


	start() {
		if (this.slides.length) {
			if (!this.timeline) {
				this.initTimer();
			}
            this.update(true);
        }
	}


	stop() {
		this.pauseTimer();
		this.pause();
	}


    initTimer() {
        this.timeline = new TimelineMax({paused: true, repeat: -1});
        this.timeline.addCallback(this.onTimeout.bind(this), this.duration);
        return this;
    }


    deinitTimer() {
        this.timeline.pause().kill();
        this.timeline = null;
        return this;
    }


	onInside(entries, observer) {
		for (const entry of entries) {
			const inside = entry.intersectionRatio > 0;
			if (inside !== this.inside) {
				this.inside = inside;
				this.update();
			}
			break;
		}
	}


    update(force = false) {
		if (this.status.active || force) {
			if (this.inside) {
	            this.play();
	            this.playTimer();
	        } else {
	            this.pause();
	            this.pauseTimer();
	        }
		}
		return this;
    }


    play() {
        if (this.inside) {
            const video = this.getVideo(this.currentIndex);
            if (video) {
				this.currentVideo = video;
				const playVideo = () => {
					if (video && video === this.currentVideo) {
						video.play();
					}
				};
				if (!this.isMobile || this.currentIndex in this.played) {
					playVideo();
				} else {
					setTimeout(playVideo, 150);
				}
				this.played[this.currentIndex] = true;
            }
        }
        return this;
    }


    pause() {
        const video = this.getVideo(this.currentIndex);
        if (video) {
			if (this.isMobile) {
				setTimeout(() => {
					if (video && this.currentVideo !== video) {
						video.pause();
					}
				}, 100);
			} else {
				video.pause();
			}
        }
        return this;
    }


    playTimer() {
        this.timeline.play();
        return this;
    }


    pauseTimer() {
        this.timeline.pause();
        return this;
    }


    restartTimer() {
        this.timeline.restart();
        return this;
    }


    getVideo(index) {
        if (!this.videos.has(index)) {
            const video = this.slides[this.currentIndex].querySelector('[data-media="video"]');
            this.videos.set(index, (video ? video.wrapper : null));
        }
        return this.videos.get(index);
    }


    onTimeout() {
        //this.pause();
        this.next();
        if (this.inside) {
            this.play();
        }
    }


    getIndex() {
        return this.currentIndex;
    }


	getLength() {
		return this.slides.length;
	}


    setIndex(index) {
		this.classList(this.slides[this.currentIndex]).remove(this.selectedClass);
        this.currentIndex = index;
		this.classList(this.slides[this.currentIndex]).add(this.selectedClass);
        return this;
    }


    next() {
        return this.setIndex((this.currentIndex + 1) % this.slides.length);
    }

}


export default MediaSequence;
