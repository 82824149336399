import pageInteractionMixin from '../utils/page-interaction-mixin';


class CoverGeometryTracker extends pageInteractionMixin() {
    constructor({autoRun = true} = {}) {
		super();
        this.objectFitSupported = !document.querySelector('html').classList.contains('no-object-fit');
        this.autoRun = autoRun;
        this.running = false;
		this.eventRunning = false;
        this.element = null;
        this.currentSrc = '';
        this.values = {
            width: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            visibleWidth: 0,
            visibleHeight: 0,
            scale: 1
        };
        this.intrinsicWidth = 0;
        this.intrinsicHeight = 0;
        this.intrinsicRatio = 0;
        this.type = null;
    }


    setElement(element) {
        const wasRunning = this.running;
        this.stop();
        this.element = element;
		if (element) {
			this.intrinsicWidth = 0;
			this.intrinsicHeight = 0;
			this.type = (this.element instanceof HTMLImageElement ? 'image' : 'video');
			this.currentSrc = (this.type === 'image' ? this.element.currentSrc : '');
			if (wasRunning || this.autoRun) {
	            //this.values = {};
	            this.start();
	        }
		}
        return this;
    }


    unsetElement() {
        return this.setElement(null);
    }


    start() {
        if (!this.running && this.element) {
            this.running = true;
            this.initEvents();
            this.update();
        }
        return this;
    }


    stop(stopEventListener = false) {
        if (this.running) {
            this.running = false;
        }
		if (stopEventListener) {
			this.deinitEvents();
		}
        return this;
    }


    onResize(event) {
		const check = () => {
			if (this.running) {
				if (this.type === 'image' && !this.element.currentSrc) {
					setTimeout(check, 0);
				} else {
					this.update();
				}
			}
		};

		if (this.running) {
			check();
		}
    }


    initEvents() {
		if (!this.eventRunning) {
			this.eventRunning = true;
			this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		}
        return this;
    }


    deinitEvents() {
		if (this.eventRunning) {
			this.eventRunning = false;
			this.resizeListener.destroy();
		}
        return this;
    }


    update() {
        const rect = this.element.getBoundingClientRect();
        const parent = this.element.parentNode.closest(':not(picture)');
        const parentRect = parent.getBoundingClientRect();
        if (this.objectFitSupported) {
            // TODO: take into account object-position property, now considering only centered elements
            if (this.intrinsicWidth === 0 || (this.type === 'image' && this.currentSrc !== this.element.currentSrc)) {
                if (this.type === 'image') {
                    this.currentSrc = this.element.currentSrc;
                    this.intrinsicWidth = this.element.naturalWidth;
                    this.intrinsicHeight = this.element.naturalHeight;
                } else {
                    this.intrinsicWidth = this.element.videoWidth;
                    this.intrinsicHeight = this.element.videoHeight;
                }
                this.intrinsicRatio = this.intrinsicWidth / this.intrinsicHeight;
            }
            const parentRatio = rect.width / rect.height;
            if (this.intrinsicRatio >= parentRatio) {
                this.values.height = parentRect.height;
                this.values.width = this.values.height * this.intrinsicRatio;
                this.values.top = 0;
                this.values.left = (parentRect.width - this.values.width) / 2;
                this.values.visibleHeight = this.values.height;
                this.values.visibleWidth = parentRect.width;
            } else {
                this.values.width = parentRect.width;
                this.values.height = this.values.width / this.intrinsicRatio;
                this.values.left = 0;
                this.values.top = (parentRect.height - this.values.height) / 2;
                this.values.visibleWidth = this.values.width;
                this.values.visibleHeight = parentRect.height;
            }
            this.values.intrinsicWidth = this.intrinsicWidth;
            this.values.intrinsicHeight = this.intrinsicHeight;
            this.values.scale = this.values.intrinsicWidth / this.values.width;
        } else {
            this.values.width = rect.width;
            this.values.height = rect.height;
            this.values.left = rect.left - parentRect.left;
            this.values.top = rect.top - parentRect.top;
            this.values.visibleWidth = parentRect.width;
            this.values.visibleHeight = parentRect.height;
        }

        this.values.right = this.values.left + this.values.width;
        this.values.bottom = this.values.top + this.values.height;
        this.raiseEvent();
    }


    raiseEvent() {
        this.events.trigger(this.element, 'media:resize', Object.assign({}, this.values));
        return this;
    }


    getValues() {
        return this.values;
    }


    getValue(name) {
        return this.values[name];
    }


    getWidth() {
        return this.values.width;
    }


    getHeight() {
        return this.values.height;
    }


    getTop() {
        return this.values.top;
    }


    getBottom() {
        return this.values.bottom;
    }


    getLeft() {
        return this.values.left;
    }


    getRight() {
        return this.values.right;
    }

}


export default CoverGeometryTracker;
