import PageComponent from '../../global/page/page-component';


class ContextSwitcher extends PageComponent {

	prepare(element, done) {
		this.pushListener = this.events.on(this.dataSelector('contextPush'), 'click', this.onPush.bind(this));
		this.popListener = this.events.on(this.dataSelector('contextPop'), 'click', this.onPop.bind(this));
		done();
	}


	clear(done) {
		this.pushListener.destroy();
		this.popListener.destroy();
		done();
	}


	onPush(event, target) {
		this.contextManager.push(this.dataAttr(target).get('contextPush'));
	}


	onPop() {
		this.contextManager.pop();
	}

}


export default ContextSwitcher;
