import PageComponent from '../../global/page/page-component';

class DistancesMap extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {},
			visibleClass = 'visible',
			sourceSvgId = 'Source',
			sourceSvgNode = 'circle'
		} = {}) {
			super({context: context, selector: selector, config: config, status: status});
			this.visibleClass = visibleClass;
			this.sourceSvgId = sourceSvgId;
			this.sourceSvgNode = sourceSvgNode;
	}

	prepare(element, done) {
		this.positions = [];
		this.createClickableDots();
		this.sliderListener = this.events.on(element, this.dataSelector('cityButton'), 'click', this.onCityButton.bind(this));

		done();
	}

	createClickableDots() {
		this.sources = this.element.querySelector('#'+this.sourceSvgId).querySelectorAll(this.sourceSvgNode);

		const mapRect = this.element.getBoundingClientRect();
		const mapWidth = mapRect.width;
		const mapHeight = mapRect.height;

		this.cityButton = this.element.querySelector(this.dataSelector('cityButton'));

		for(let i = 0; i<this.sources.length; i++) {
			const source = this.sources[i];
			const cityRect = source.getBoundingClientRect();
			const clone = this.cityButton.cloneNode();
			const left = (cityRect.left - mapRect.left + (cityRect.right-cityRect.left)/2) / mapWidth * 100;
			const top = (cityRect.top - mapRect.top + (cityRect.bottom-cityRect.top)/2) / mapHeight * 100;
			const id = source.getAttribute('class');
			clone.style.left = left + '%';
			clone.style.top = top + '%';
			this.dataAttr(clone).set('cityId', id);

			this.positions[id] = {x: left, y: top};

			this.element.appendChild(clone);
		}

		this.cityButton.remove();
	}


	onCityButton(e) {
		this.events.trigger(this.element, 'map:cityclick', {
			cityid: this.dataAttr(e.target).get('cityId')
		});
		return this;
	}


	showCurrentConnection(currentData) {
		if(this.currentElement) {
			this.classList(this.currentElement).remove(this.visibleClass);
		}
		//target svg element: <g id="M-KOE">
		this.currentElement = this.element.querySelector('#'+currentData.from+'-'+currentData.to);
		if(this.currentElement) {
				this.classList(this.currentElement).add(this.visibleClass);
		}
	}

	getCityPosition(currentData) {
		return this.positions[currentData.from];
	}
}

export default DistancesMap;
