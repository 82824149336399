import {TimelineMax} from 'gsap';
import getPointerPosition from '../../global/utils/get-pointer-position';
import Infographic from './infographic';
import graphTemplate from './templates/bar-infographic';
import gridTemplate from './templates/bar-infographic-grid';


class BarInfographic extends Infographic {

	constructor({context = null, selector = true, config = {}, status = {played: false, available: false}, duration = 0.8, delay = 0.05, autoHighlightFirst = true, selectedClass = 'selected'} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.duration = duration;
        this.delay = delay;
        this.autoHighlightFirst = autoHighlightFirst;
		this.selectedClass = selectedClass;
	}


    initGraph() {
        this.current = null;
        this.dataContainer = this.element.querySelector(this.dataSelector('data'));
        this.graphContainer = this.element.querySelector(this.dataSelector('graph'));
        this.gridContainer = this.element.querySelector(this.dataSelector('grid'));
        this.gridMax = this.dataAttr(this.element).get('gridMax');
        this.gridStep = this.dataAttr(this.element).get('gridStep');

		if (this.dataAttr(this.element).has('duration')) {
            this.duration = this.dataAttr(this.element).get('duration');
        }

		if (this.dataAttr(this.element).has('delay')) {
            this.delay = this.dataAttr(this.element).get('delay');
        }

        this.fetchData();
        this.render();
        this.initEvents();
    }


    deinitGraph() {
        this.deinitEvents();
        this.data = null;
        this.bars = null;
        this.dataContainer = null;
        this.graphContainer = null;
    }


    fetchData() {
        this.data = [];
        for (const element of this.dataContainer.querySelectorAll(this.dataSelector('city'))) {
            this.data.push(this.dataAttr(element).get('city'));
        }
        return this;
	}


	render() {
        this.renderGrid();
		this.graphContainer.innerHTML = graphTemplate(this.data);
        this.graphContainer = this.graphContainer.querySelector(this.dataSelector('graphContainer'));
		this.bars = this.graphContainer.querySelectorAll(this.dataSelector('bar'));
        return this;
	}


    renderGrid() {
        const gridData = [];
        for (let i = 0; i <= this.gridMax; i += this.gridStep) {
            gridData.push({value: i, percent: i / this.gridMax * 100});
        }
        this.gridContainer.innerHTML = gridTemplate(gridData);
        return this;
    }


    initEvents() {
        this.moveHandler = this.events.on(this.graphContainer, 'touchstart touchmove mousemove', this.onMove.bind(this));
        this.outHandler = this.events.on(this.graphContainer, 'mouseout', this.onOut.bind(this));
        this.touchHandler = this.events.on(window, 'touchstart', this.onOut.bind(this));
    }


    deinitEvents() {
        this.moveHandler.destroy();
        this.outHandler.destroy();
        this.touchHandler.destroy();
    }


	play() {
        const timeline = new TimelineMax();
		for (let i = 0, end = this.bars.length; i < end; i++) {
            const bar = this.bars[i];
			const barBg = bar.querySelector(this.dataSelector('barBg'));
			const barTooltip = bar.querySelector(this.dataSelector('tooltip'));
			const perc = this.data[i].percent + '%';

			barBg.style.height = perc;
			barTooltip.style.bottom = perc;

            timeline.to(barBg, this.duration, {scaleY: 1, ease: 'Power4.easeOut'}, this.delay * i);
		}

        timeline
            .eventCallback('onComplete', () => {
                this.status.available = true;
                if (this.autoHighlightFirst) {
                    this.selectElement(0);
                }
            })
            .play();
	}


	onOut(event) {
        if (this.status.available && (event.type.toLowerCase() === 'touchstart' || event.target === this.graphContainer)) {
            this.deselectCurrentElement();
        }
	}


	onMove(event) {
        if (this.status.available) {
            const x = getPointerPosition(event).x;
            let min = Number.MAX_VALUE;
            let minId = -1;
            for(let i = 0; i < this.bars.length; i++) {
                const bar = this.bars[i];
                const rect = bar.getBoundingClientRect();
                const diff = Math.abs(rect.left + rect.width / 2 - x);
                if(diff < min) {
                    min = diff;
                    minId = i;
                }
            }
            if (minId >= 0) {
                this.selectElement(minId);
            }
        }
	}


	selectElement(id) {
		if(this.current === id) {
            return;
        }
		this.classList(this.graphContainer).add(this.selectedClass);
		if (this.current !== null) {
			this.classList(this.bars[this.current]).remove(this.selectedClass);
		}
		this.current = id;
		this.classList(this.bars[this.current]).add(this.selectedClass);
	}


	deselectCurrentElement() {
		this.classList(this.graphContainer).remove(this.selectedClass);
		if (this.current !== null) {
			this.classList(this.bars[this.current]).remove(this.selectedClass);
		}
        this.current = null;
	}

}

export default BarInfographic;
