import {TweenMax, TimelineMax} from 'gsap';
import ProgressAnimator from './progress-animator';
import {toRad} from '../utils/angles';


class CircleProgressAnimator extends ProgressAnimator {

    constructor({element, negateOnLoop = true, lineWidth = 5, color = 'black', angleOffset = 0}) {
        super({element: element});
        this.negateOnLoop = negateOnLoop;

		this.lineWidth = lineWidth;
		this.color = color;
		this.angleOffset = angleOffset;

		this.canvas = document.createElement('canvas');
		this.width =  this.element.getBoundingClientRect().width;
		this.height = this.width;
		this.canvas.width = this.width;
		this.canvas.height = this.height;
		this.context = this.canvas.getContext('2d');
		this.element.appendChild(this.canvas);
		this.x = this.canvas.width / 2;
		this.y = this.canvas.height / 2;
		this.radius = this.x - (this.lineWidth / 2);
		this.counterClockwise = false;
    }

		recreate() {
			this.width =  this.element.getBoundingClientRect().width;
			this.height = this.width;
			this.canvas.width = this.width;
			this.canvas.height = this.height;
			this.x = this.canvas.width / 2;
			this.y = this.canvas.height / 2;
			this.radius = this.x - (this.lineWidth / 2);
			console.log('recreate');
			console.log(this.canvas);
			console.log(this.context);
		}


	plot(begin, end) {
		const beginAngle = toRad(360 * begin + this.angleOffset);
		const endAngle = toRad(360 * end + this.angleOffset);
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this.context.beginPath();
		if (this.radius >= 0) {
			this.context.arc(this.x, this.y, this.radius, beginAngle, endAngle, this.counterClockwise);
			this.context.lineWidth = this.lineWidth;
			this.context.strokeStyle = this.color;
			this.context.stroke();
		}
	}


    stop(value = null) {
        if (this.animation) {
            this.animation.pause();
        }
        if (value !== null) {
            this.animateTo(value, 0);
        }
    }

		getCurrentValue() {
			return this.value;
		}



    animate(begin, end, duration, loop = false, ease = 'Linear.easeNone') {
        if (this.animation) {
            this.animation.pause();
        }
		if (!duration) {
			this.value = end;
			this.plot(0, this.value);
		} else {
			const dummy = {progress: begin};
			this.animation = TweenMax.to(dummy, duration, {
	            progress: end,
	            ease: ease,
	            onUpdate: () => {
	                // const offset = Math.ceil(this.dashLen * (1 - dummy.progress));
	                // this.cursor.setAttribute('stroke-dashoffset', offset);
	                this.value = dummy.progress;
					this.plot(0, this.value);
	            },
	            repeat: (loop ? -1 : 0)
	        });
		}
    }


    startLoop(duration) {
        if (!this.negateOnLoop) {
            return super.startLoop(duration);
        }
        if (this.animation) {
            this.animation.pause();
        }
        const dummy = {progress: 0};
        this.animation = new TimelineMax({repeat: -1});
        this.animation.to(dummy, duration, {
            progress: 1,
            ease: 'Linear.easeNone',
            onUpdate: () => {
                // const offset = Math.ceil(this.dashLen * (1 - dummy.progress));
                // this.cursor.setAttribute('stroke-dashoffset', offset);
                this.value = dummy.progress;
				this.plot(0, this.value);
            }
        }).to(dummy, duration, {
            progress: 0,
            ease: 'Linear.easeNone',
            onUpdate: () => {
                // const offset = Math.ceil(-this.dashLen * (1 - dummy.progress));
                // this.cursor.setAttribute('stroke-dashoffset', offset);
                this.value = dummy.progress;
				this.plot(1 - this.value, 1);
            }
        });
        return this;
    }

}


export default CircleProgressAnimator;
