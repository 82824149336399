import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {getViewportWidth, getViewportHeight} from '../../global/utils/size';
import PageComponent from '../../global/page/page-component';


class CoverVideoController extends PageComponent {

	constructor({context = null, selector = true, config = {async: true}, status = {available: false, waiting: false}, scrollAnimationDuration = 0.5, scrollOffset = -4} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.scrollAnimationDuration = scrollAnimationDuration;
        this.scrollOffset = scrollOffset;
	}


    prepare(element, done) {
		// we need to do this to avoid content jumps on mobile with chrome when the address bar is collapsing
		this.viewportWidth = getViewportWidth();
		this.viewportHeight = getViewportHeight();
		this.isMobile = document.querySelectorAll('html.mobile').length > 0;
		if (this.isMobile) {
			const rect = element.getBoundingClientRect();
			TweenMax.set(element, {height: rect.height + 'px'});
		}


		this.videos = {
			landscape: element.querySelector(this.dataSelector('coverVideoVersion', 'landscape')).wrapper,
			portrait: element.querySelector(this.dataSelector('coverVideoVersion', 'portrait')).wrapper
		};
		this.currentVideo = null;

		this.resize();
		this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.observer = new IntersectionObserver(this.onInside.bind(this), {
			threshold: [0, 0.2]
		});
		this.inside = null;
		this.isLandscape = null;
		this.observer.observe(element);

		done();
    }


    clear(done) {
		this.observer.disconnect();
		this.resizeListener.destroy();
        done();
    }


	start() {
		this.update();
		// this.inside = false;
		// this.update();
	}


	stop() {
		this.update();
	}


	onInside(entries, observer) {
		for (const entry of entries) {
			const inside = entry.intersectionRatio > 0.1;
			if (inside !== this.inside) {
				this.inside = inside;
				this.update();
			}
		}
	}


	play() {
		if (this.status.ready && this.currentVideo) {
			this.currentVideo.play();
		}
        // if (this.status.available) {
        //     this.animation.play();
        //     if (this.status.waiting) {
        //         this.progressTimeline.play();
        //     }
        // }
        return this;
    }

    pause() {
		if (this.currentVideo) {
			this.currentVideo.pause();
		}
        // if (this.status.available) {
        //     this.animation.pause();
        //     if (this.status.waiting) {
        //         this.progressTimeline.pause();
        //     }
        // }
        return this;
    }


	onResize() {
		this.resize();
	}


	resize() {
		const viewportWidth = getViewportWidth();
		const viewportHeight = getViewportHeight();
		this.updateFormat(viewportWidth >= viewportHeight);

		if (this.isMobile && (viewportWidth !== this.viewportWidth || Math.abs(viewportHeight - this.viewportHeight) > this.viewportHeight * 0.15)) {
			this.viewportWidth = viewportWidth;
			this.viewportHeight = viewportHeight;
			TweenMax.set(this.element, {height: viewportHeight + 'px'});
		}
	}


	updateFormat(isLandscape) {
		if (isLandscape !== this.isLandscape) {
			this.isLandscape = isLandscape;
			const oldVideo = this.currentVideo;
			this.currentVideo = this.videos[isLandscape? 'landscape' : 'portrait'];
			if (oldVideo) {
				const position = oldVideo.time();
				if (position !== null && position !== undefined) {
					this.currentVideo.time(position);
				}
				oldVideo.pause();
			}
			this.update();
		}
	}


	update() {
        if (this.inside && this.status.active) {
            this.play();
        } else {
            this.pause();
        }
	}

}


export default CoverVideoController;
