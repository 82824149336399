import PageComponent from '../page/page-component';
import updateStatusMixin from '../utils/update-status-mixin';
import {isNumber} from '../utils/types';


class Slideshow extends updateStatusMixin(PageComponent) {
    constructor({slideSelector, context = null, selector = true, config = {}, status = {loading: true, available: false, busy: false, paused: false}, autoSlideDefaultDuration = 0, stopAutoSlideOnInteraction = true, progressLoopDuration = 1}) {
		super({context: context, selector: selector, config: config, status: status});
        this.slideSelector = slideSelector;
        this.autoSlideDefaultDuration = autoSlideDefaultDuration;
        this.autoSliding = !!autoSlideDefaultDuration;
        this.stopAutoSlideOnInteraction = stopAutoSlideOnInteraction;
        this.progressAnimator = null;
        this.progressLoopDuration = progressLoopDuration;
        this.slides = [];
        this.slidesCount = 0;
        this.slidesLoadedCount = 0;
        this.manualNext = true;
        this.currentSlide = null;
		this.linkedElements = [];
    }

	setSlideFactory(slideFactory) {
		this.slideFactory = slideFactory;
	}


	setProgressAnimatorFactory(progressAnimatorFactory) {
		this.progressAnimatorFactory = progressAnimatorFactory;
	}


	setSlideAnimator(slideAnimator) {
		this.slideAnimator = slideAnimator;
	}


    prepare(element, done) {
        this.slideListener = this.events.on(element, this.slideSelector, 'slide:load', this.onSlideLoad.bind(this));
        const slideElements = this.element.querySelectorAll(this.slideSelector);
        this.slidesCount = slideElements.length;
        if (this.slidesCount && this.progressAnimator) {
            this.progressAnimator.startLoop(this.progressLoopDuration);
        }
		let i = 0;
        for (const slideElement of slideElements) {
			const type = this.dataAttr(slideElement).get('slideType');
			const subtype = this.dataAttr(slideElement).get('slideSubtype');
			const duration = this.dataAttr(slideElement).get('slideDuration');
            const slide = this.slideFactory.newInstance(type, {slideshow: this, element: slideElement, index: i, duration: duration, subtype: subtype});
            if (i === 0) {
				this.setCurrentSlide(slide);
				slide.setCurrent(true);
            }
            this.slides.push(slide);
            slide.load();
            i++;
        }
        this.linkedElements = [element];
        this.updateStatus(true);
        done();
    }


    clear(done) {
        this.slideListener.destroy();
        this.stopAutoSlide();
        this.autoSliding = false;
        if (this.progressAnimator) {
            this.progressAnimator.stop(0);
            this.progressAnimator = null;
        }
        this.slides = [];
        done();
    }


	setCurrentSlide(slide) {
		this.currentSlide = slide;
		this.dataAttr(this.element).set('currentSlideIndex', slide.getIndex());
		this.dataAttr(this.element).set('currentSlideType', slide.getType());
		this.dataAttr(this.element).set('currentSlideSubtype', slide.getSubtype());
		return this;
	}


    play() {
		if (this.status.active) {
			if (this.status.paused) {
	            this.updateStatus('paused', false);
	            if (this.currentSlide && this.status.available) {
	                if (this.autoSliding) {
	                    this.autoSlide();
	                }
	            }
	        }
			if (this.currentSlide) {
				this.currentSlide.play();
			}
		}
        return this;
    }


    pause() {
        if (!this.status.paused) {
            this.updateStatus('paused', true);
            if (this.status.available && this.autoSliding) {
                if (this.autoSlideTimeout) {
                    clearTimeout(this.autoSlideTimeout);
                    this.autoSlideTimeout = null;
                }
                if (this.progressAnimator) {
                    this.progressAnimator.stop(0);
                }
            }
        }
		if (this.currentSlide) {
			this.currentSlide.pause();
		}
        return this;
    }


    paused() {
        return this.status.paused;
    }


    onSlideLoad(event) {
        this.slidesLoadedCount++;
        if (this.slidesLoadedCount === this.slidesCount) {
            this.updateStatus({loading: false, available: true});
            if (this.progressAnimator) {
                this.progressAnimator.stop(0);
            }

            this.events.trigger(this.element, 'slideshow:load', {
                slideshow: this
            });

            if (this.slides.length > 1 && this.autoSlideDefaultDuration) {
                this.startAutoSlide();
            }
        }
    }


    next(done = () => {}) {
        if (!this.status.available || this.status.busy || this.slides.length < 2) {
            return this;
        }
        if (this.autoSliding && this.stopAutoSlideOnInteraction && this.manualNext) {
            this.stopAutoSlide();
        }
        const currentIndex = this.currentSlide.getIndex();
        const nextIndex = (currentIndex + 1) % this.slidesCount;
        this.goTo(this.slides[nextIndex], done);
        return this;
    }


    prev(done = () => {}) {
        if (!this.status.available || this.status.busy || this.slides.length < 2) {
            return this;
        }
        const currentIndex = this.currentSlide.getIndex();
        const prevIndex = currentIndex === 0 ? this.slidesCount - 1 : currentIndex - 1;
        this.goTo(this.slides[prevIndex], done);
        return this;
    }


    goTo(newSlide, done = () => {}) {
		if (isNumber(newSlide)) {
			newSlide = Math.max(0, Math.min(this.slides.length - 1, newSlide));
			if (newSlide === this.currentSlide.getIndex()) {
				return this;
			}
			newSlide = this.slides[newSlide];
		}
        this.switch(this.currentSlide, newSlide, done);
        return this;
    }


    switch(currentSlide, newSlide, done = () => {}) {
        if (this.status.busy || this.slides.length < 2) {
            return this;
        }
        this.updateStatus('busy', true);
        this.slideAnimator.switch(currentSlide, newSlide, () => {
            this.setCurrentSlide(newSlide);
            this.events.trigger(this.element, 'slideshow:change', {
                slideshow: this,
                previousSlide: currentSlide,
                current: newSlide
            });
            this.updateStatus('busy', false);
            done();
        });
        return this;
    }


    startAutoSlide() {
        if (this.autoSlideDefaultDuration && this.slides.length >= 2) {
            this.autoSliding = true;
            this.autoSlide();
        }
        return this;
    }


    stopAutoSlide() {
        this.autoSliding = false;
        if (this.autoSlideTimeout) {
            clearTimeout(this.autoSlideTimeout);
            this.autoSlideTimeout = null;
        }
        if (this.progressAnimator) {
            this.progressAnimator.stop(0);
        }
    }


    autoSlide () {
        if (!this.status.paused) {
            const duration = this.currentSlide.getDuration() || this.autoSlideDefaultDuration;
            if (this.progressAnimator) {
                this.progressAnimator.fullRound(duration);
            }
            this.autoSlideTimeout = setTimeout(() => {
                if (this.autoSliding && !this.status.paused) {
                    this.manualNext = false;
                    this.next(() => {
                        this.manualNext = true;
                        this.autoSlide();
                    });
                }
            }, duration * 1000);
        }
    }

}

export default Slideshow;
