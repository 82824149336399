import {TweenMax} from 'gsap';
import {getScrollTop} from 'get-scroll';
import {isString} from '../../global/utils/types';
import threeStateTransition from '../../global/utils/three-state-transition';
import PageComponent from '../../global/page/page-component';

class MainMenu extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, scrollDuration = 0.4, scrollDelay = 0, showClass = 'show'} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.scrollDuration = scrollDuration;
		this.scrollDelay = scrollDelay;
		this.showClass = showClass;
	}


    prepare(element, done) {
        this.currentList = this.element.querySelector(this.classSelector('show') + this.dataSelector('menu'));
        this.stack = [];
        this.linkListener = this.events.on(this.element, this.dataSelector('menuLink'), 'click', this.onLinkClick.bind(this), {capture: true});
		this.submenuListener = this.events.on(this.element, this.dataSelector('menuSubmenu'), 'click', this.openSubmenu.bind(this), {capture: true});
		this.backListener = this.events.on(this.element, this.dataSelector('menuBack'), 'click', this.back.bind(this), {capture: true});
		this.busy = false;
		done();
    }


	clear(done) {
		this.linkListener.destroy();
		this.submenuListener.destroy();
		this.backListener.destroy();
		done();
	}


	stop() {
		this.restoreMain();
	}


    onLinkClick(event, target) {
        this.contextManager.pop(() => this.restoreMain());
    }


	restoreMain() {
        for (let i = 0, end = this.stack.length; i < end; i++) {
            this.back();
        }
	}


    openSubmenu(event, target) {
		if (!this.busy) {
			this.stack.push(this.currentList);
	        this.switchTo(this.dataAttr(target).get('menuSubmenu'));
		}
    }


    back() {
        if (!this.busy && this.stack.length) {
            const newList = this.stack.pop();
            this.switchTo(newList);
        }
    }


    switchTo(newList) {
        if (isString(newList)) {
            newList = this.getList(newList);
        }
		this.busy = true;
		const scrollTop = getScrollTop();
		let currentHeight;
		if (scrollTop > 0) {
			currentHeight = this.currentList.getBoundingClientRect().height;
			TweenMax.set(document.body, {height: currentHeight + 'px'});
			TweenMax.set(newList, {y: scrollTop + 'px'});
		}
		threeStateTransition.remove(this.currentList, this.showClass);
		threeStateTransition.add(newList, this.showClass, () => {
			this.currentList = newList;
			if (scrollTop > 0) {
				document.body.style.removeProperty('height');
				TweenMax.set(newList, {y: 0});
				window.scrollTo(0, 0);
			}
			this.busy = false;
		});

        return this;
    }


    getList(name) {
        return this.element.querySelector(this.dataSelector('menu', name));
    }

}


export default MainMenu;
