import DefaultPage from '../default-page';


class HomePage extends DefaultPage {


	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('CoverVideoController', {selector: this.dataSelector('coverVideo'), context: 'default'});

			this.addNewComponent('ExclusionMenu', {selector: this.dataSelector('manifests'), context: 'default'});

			this.mediaSequence = this.addNewComponent('MediaSequence', {selector: this.dataSelector('homeSchools'), slidesSelector: this.dataSelector('bg'), context: 'default'});
			this.homeSchools = this.addNewComponent('HomeSchools', {selector: this.dataSelector('homeSchools'), context: 'default'});
			this.homeSchools.setMediaSequence(this.mediaSequence);

			// const mapSectionActions = this.addNewComponent('SectionActions', {selector: this.dataSelector('homeSchoolsSection'), context: 'default'});
			// const homeMapManager = this.addNewComponent('HomeMapManager', {selector: this.dataSelector('homeSchoolsSection'), context: 'default'});
			// homeMapManager.setSectionActions(mapSectionActions);

			this.addNewComponent('Teasers', {selector: this.dataSelector('teasers')});

			done();
		});
	}
}


export default HomePage;
