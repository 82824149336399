import NavigationTracker from './navigation-tracker';


class ObPixelNavigationTracker extends NavigationTracker {


	init() {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.tracker = ('obApi' in window ? window.obApi : null);
	}

	doTrack(url, title) {
		if (this.tracker) {
			this.tracker('track', 'PAGE_VIEW');
		} else {
			console.log('ob page change:', url, title);
		}
	}
}


export default ObPixelNavigationTracker;
