class SearchEngine {


	setAdditionalParams(params) {
		this.additionalParams = params;
		return this;
	}


	search(query, tags = [], page = 1) {
		return Promise.resolve({});
	}


	track(search, tags, page) {
		return Promise.resolve({});
	}

}


export default SearchEngine;
