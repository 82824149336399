class DistancesDataParser {

	parseCarKmArray(dataRaw) {
		this.carKmData = this.rebuildData(dataRaw);
		this.carKmData.sort(this.sortFunction);
		this.connectionsNo = this.carKmData.length;
		for(let i=0; i<this.connectionsNo; i++) {
			this.carKmData[i].position = i;
		}
	}

	parseCarTimeArray(dataRaw) {
		this.carTimeData = this.rebuildData(dataRaw);
	}

	parseTrainTimeArray(dataRaw) {
		this.trainTimeData = this.rebuildData(dataRaw);
	}

	parseIdCityArray(dataRaw) {
		this.idCityData = this.rebuildIdCityData(dataRaw);
	}

	getCityNameById(id) {
		return this.idCityData[id];
	}

	getDataByPosition(position) {
		return this.carKmData[position];
	}

	//returns a random path from a chosen city
	getDataByFromCityId(from) {
		const connectionsFromCity = [];
		for(let i=0; i<this.connectionsNo; i++) {
			const connection = this.carKmData[i];
			if(connection.from === from) {
				connectionsFromCity.push(connection);
			}
		}
		if(this.currentFrom === from) {
			this.currentCityId++;
		} else {
			this.currentCityId = 0;
		}
		this.currentFrom = from;
		console.log(from, this.currentCityId);
		return connectionsFromCity[this.currentCityId%connectionsFromCity.length];
	}

	getConnectionsNo() {
		return this.connectionsNo;
	}


	findLongestJourney() {
		let max = 0;
		for(let i=1; i<this.connectionsNo; i++) {
			const timeInMinutes = this.timeToMinutes(this.carTimeData[i].val);
			this.carTimeData[i].minutes = timeInMinutes;
			max = Math.max(max, timeInMinutes);
		}
		for(let i=1; i<this.connectionsNo; i++) {
			const timeInMinutes = this.timeToMinutes(this.trainTimeData[i].val);
			this.trainTimeData[i].minutes = timeInMinutes;
			max = Math.max(max, timeInMinutes);
		}
		this.longestJourney = max;
	}

	getTrainDataById(id) {
		return this.trainTimeData[id];
	}

	getCarDataById(id) {
		return this.carTimeData[id];
	}

	getMaxTime() {
		return this.longestJourney;
	}

	sortFunction(a, b) {
		if(a.val === b.val) {
			return 0;
		} else {
			return (parseFloat(a.val) < parseFloat(b.val)) ? -1 : 1;
		}
	}

	/*
		rebuilds CSV data to a more convinient form
		an array of objecs with the following parameters:
			val: value - e.g. kilometers or hours,
			from: from city abbreviation,
			to: to coty abbreviation,
			basePosition: position in the array before sorting
				this allows easily refer to other, not sorted arrays.
	*/
	rebuildData(dataRaw) {
		const data = [];
		const rowsNo = dataRaw.length;
		const columnsNo = dataRaw[0].length;
		let pos = 0;
		for(let i=1; i<rowsNo; i++) {
			for(let j=1; j<columnsNo; j++) {
				data.push({
				val: dataRaw[i][j],
				from: dataRaw[i][0],
				to: dataRaw[0][j],
				basePosition: pos++,
				});
			}
		}
		return data;
	}


	rebuildIdCityData(dataRaw) {
		const idCityObj = {};
		for(let i=0; i<dataRaw.length; i++) {
			idCityObj[dataRaw[i][0]] = dataRaw[i][1];
		}
		return idCityObj;
	}

	//change H:MM to minutes
	timeToMinutes(time) {
		const array = String(time).split(':');
		return parseInt(array[0], 10)*60 + parseInt(array[1], 10);
	}
}


export default DistancesDataParser;
