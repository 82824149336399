// import {TweenMax} from 'gsap';
// import keymaster from 'keymaster';
// import {getScrollTop} from 'get-scroll';

// import getOffsetTop from '../../global/utils/offset-top';
// import onTransitionEnd from '../../common/dom/transition-end';
import Search from '../../global/search/search';
// import componentConstants from '../../common/component/_constants';


class ElternSearch extends Search {

	constructor({
		context = null, selector = true, config = {}, status = {},
		// element,
		// root,
		// relPath,
		// projectUrls,
		// resultsTpl = 'search/results',
		waitTime = 400, // ms
		qsChar = '?',
		qsSearchName = 's',
		qsPageName = 'p',
		qsTagsName = 't',
		qsBaseUrlName = 'u',
		// bodySearchModeClass = 'searchMode',
		// searchReadyClass = 'searchReady',
		// searchPreparingClass = 'searchPreparing',
		// placeholderClass = 'placeholder',
		// hiddenClass = 'hidden',
		// overlayClass = 'overlay',
		closeOnKey = 'esc',
		// thumbOptions = [400]
	}) {
		super({
			context: context, selector: selector, config: config, status: status,
			waitTime: waitTime,
			qsChar: qsChar,
			qsSearchName: qsSearchName,
			qsPageName: qsPageName,
			qsTagsName: qsTagsName
		});
		this.qsBaseUrlName = qsBaseUrlName;
	}


	prepare(element, done) {
		super.prepare(element, () => {
			this.searchEngine.setAdditionalParams({
				[this.qsBaseUrlName]: this.dataAttr(element).get('baseUrl', '')
			});

			this.results = element.querySelector(this.dataSelector('searchResults'));

			const form = element.querySelector(this.dataSelector('elternSearchForm'));
			this.searchEngine.setSearchUrl(form.getAttribute('action'));

			const selectedTags = form.querySelectorAll(this.dataSelector('elternSearchTagSelected'));
			for (const tag of selectedTags) {
				const tagId = String(this.dataAttr(tag).get('tagId'));
				this.tags.push(tagId);
			}

			done();
		});
	}


	canStart() {
		return true;
	}


	canStop() {
		return true;
	}
}


export default ElternSearch;
