import PageComponent from '../page/page-component';


class YoutubePlayer extends PageComponent {

	constructor({context = null, selector = true, config = {async: false}, status = {}, videoId = null, width = 640, height = 390, videoOptions = {}} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.player = null;
		this.youtube = null;
		this.videoId = videoId;
		this.width = width;
		this.height = height;
		this.videoOptions = videoOptions;
		this.videoElement = null;
	}


	setApiLoader(apiLoader) {
		this.apiLoader = apiLoader;
	}


    prepare(element, done) {
		this.videoElement = element.querySelector(this.dataSelector('playerIframe'));
		if (this.dataAttr(element).has('videoId')) {
			this.videoId = this.dataAttr(element).get('videoId');
		}
        this.apiLoader.onReady((youtube) => {
            this.onApiReady(youtube, done);
        });
    }


    clear(done) {
		try {
			this.player.stopVideo();
			this.player.destroy();
		} catch(e) {
			console.log(e);
		}
		done();
    }


    onApiReady(youtube, done) {
		try {
	        this.youtube = youtube;
			this.initPlayer(done);
		} catch(e) {
			console.log(e);
		}
        // this.addIdleCallback(done, true);
        // this.initMap();
    }


	initPlayer(done) {
		if (this.videoId !== null) {
			this.player = new this.youtube.Player(this.videoElement, {
				width: this.width,
				height: this.height,
				videoId: this.videoId,
				playerVars: Object.assign({}, this.videoOptions),
				events: {
					onReady: () => this.onReady(done),
					onStateChange: this.onPlayerStateChange.bind(this)
				}
			});
		} else {
			done();
		}
	}


	onReady(done) {
		this.videoElement = this.element.querySelector(this.dataSelector('playerIframe'));
		done();
	}


	onPlayerStateChange() {
	}


	setSize(width, height) {
		this.width = width;
		this.height = height;
		return this;
	}


	getPlayer() {
		return this.player;
	}


}

export default YoutubePlayer;
