import SearchEngine from '../../global/search/search-engine';
import httpRequest from 'superagent';


class ElternSearchEngine extends SearchEngine {


	setSearchUrl(url) {
		this.searchUrl = url;
	}


	search(query, tags = [], page = 1) {
		const params = Object.assign({s: query, 't[]': tags, p: page}, ('additionalParams' in this && this.additionalParams ? this.additionalParams : {}));

		console.log('params', params);

		return httpRequest
			.get(this.searchUrl)
			.query(params)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', 'application/json')
			.then((response) => (response.body))
			// .catch((error) => {
			// 	//TODO @sr: error handling ?? or delete...
			// })
		;
	}
}


export default ElternSearchEngine;
