import {TimelineMax} from 'gsap';


import Context from '../../global/context/context';


class HomeMapContext extends Context {

    constructor({name, selector, boxSelector, mapSelector, outClass = 'inactive'}) {
        super({name: name, selector: selector});
        this.boxSelector = boxSelector;
        this.mapSelector = mapSelector;
        this.prevScrollPos = 0;
        this.outClass = 'inactive';
    }


    switchIn(done, otherContext) {
        const element = this.getElement();
        const box = element.querySelector(this.boxSelector);
        const map = element.querySelector(this.mapSelector);
        // const elementRect = element.getBoundingClientRect();
        // const boxRect = box.getBoundingClientRect();
        const mapRect = map.getBoundingClientRect();
        const boxRect = box.getBoundingClientRect();
        this.mapRect = mapRect;
        this.boxRect = boxRect;

        const timeline = new TimelineMax();
        timeline.fromTo(map, 0.5, {
                clip: 'rect(' + boxRect.top + 'px, ' + boxRect.right + 'px, ' + boxRect.bottom + 'px, 0px)'
            },
            {
                clip: 'rect(0px, '+ mapRect.right + 'px, ' + mapRect.bottom + 'px, 0px)',
                ease: 'Power2.easeOut'
            },
            0
        ).fromTo(map, 0.4, {
                opacity: 0,
                visibility: 'hidden'
            },
            {
                opacity: 1,
                visibility: 'visible',
                ease: 'Power2.easeOut'
            },
            0.01
        ).eventCallback('onComplete', () => {
            super.switchIn(done, otherContext);
        }).play();

        // element.classList.remove(this.outClass);
		// element.style.removeProperty('top');
		// window.scrollTo(0, this.prevScrollPos);
    }


    switchOut(done, otherContext) {
        const map = this.getElement().querySelector(this.mapSelector);
        const timeline = new TimelineMax();
        timeline.fromTo(map, 0.4, {
                clip: 'rect(0px, ' + this.mapRect.right + 'px, ' + this.mapRect.bottom + 'px, 0px)'
            },
            {
                clip: 'rect(' + this.boxRect.top + 'px, '+ this.boxRect.right + 'px, ' + this.boxRect.bottom + 'px, 0px)',
                ease: 'Power2.easeOut'
            },
            0
        ).fromTo(map, 0.4, {
                opacity: 1,
                visibility: 'visible'
            },
            {
                opacity: 0,
                visibility: 'hidden',
                ease: 'Power2.easeOut'
            }
        ).eventCallback('onComplete', () => {
            super.switchOut(done, otherContext);
        }).play();

        // const element = this.getElement();
        // const currentOffset = getOffsetTop(element);
        // const scrollPos = getScrollTop();
        // this.prevScrollPos = scrollPos;
        // element.classList.add(this.outClass);
        // element.style.top = (currentOffset - scrollPos) + 'px';
        // window.scrollTo(0, 0);
        super.switchOut(done, otherContext);
    }

}


export default HomeMapContext;
