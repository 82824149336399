import NavigationTracker from './navigation-tracker';


class FbPixelNavigationTracker extends NavigationTracker {


	init() {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.tracker = ('fbq' in window ? window.fbq : null);
	}


	doTrack(url, title) {
		if (this.tracker) {
			this.tracker('track', 'PageView');
		} else {
			console.log('fb page change:', url, title);
		}
	}


	doTrackEvent(category, action, name = null, value = null) {
		if (this.tracker) {
			// fbq('track', 'Lead', {
			//   content_name: 'reflect'
			//  });
			const params = ['track', category, action];
			if (name !== null) {
				params.push(name);
			}
			if (value !== null) {
				params.push(value);
			}
			this.tracker(...params);
		} else {
			console.log('fb tracking event', category, action, name, value);
		}
	}

}


export default FbPixelNavigationTracker;
