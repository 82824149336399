import escape from './escape';


export default function svgIcon(id, attributes = {}) {
	const symbol = document.body.querySelector('svg #' + id);
	if (!symbol) {
		return '<!-- svg ' + id + ' not found -->';
	}
	const viewBox = symbol.getAttribute('viewBox');
	if (viewBox) {
		attributes.viewBox = viewBox;
		if ('height' in attributes) {
			if (attributes.height !== true && attributes.height !== false) {
				attributes.style = 'height: ' + attributes.height;
			}
			delete attributes.height;
		} else {
			const [startX, startY, endX, endY] = viewBox.split(' ');
			const width = Number(endX) - Number(startX);
			const height = Number(endY) - Number(startY);
			const heightPercent = Math.round(height / width * 100 * 10000) / 10000;
			attributes.preserveAspectRatio = 'xMidYMin slice';
			attributes.style = 'width: 100%; padding-bottom: ' + heightPercent + '%; height: 1px; overflow: visible';
		}
	}
	let attrs = '';
	for (const name in attributes) {
		if (attributes.hasOwnProperty(name)) {
			attrs += name + '="' + escape(attributes[name]) + '"';
		}
	}

	return '<svg ' + attrs + '><use xlink:href="#' + id + '"></use></svg>';
}
