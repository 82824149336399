import imagesLoaded from 'imagesloaded';
import Slide from './slide';


class VideoSlide extends Slide {

    constructor({slideshow, element, index, type = 'video', subtype, duration}) {
        super({slideshow: slideshow, element: element, index: index, type: type, subtype: subtype, duration: duration});
        this.videoElement = this.element.querySelector('[data-media="video"]');
        this.video = this.videoElement.wrapper;
    }


    load() {
		let called = false;
		this.listener = null;
		const onLoad = () => {
			if (!called) {
				called = true;
				super.load();
//				console.log('video load', this.index, this.current, this.slideshow.paused());
	            if (this.current && !this.slideshow.paused()) {
					if (this.listener) {
						this.listener.destroy();
					}
					this.listener.destroy();
	                this.video.play();
	            }
			}
        };

		this.listener = this.events.on(this.videoElement, 'video:canplay', () => {
			onLoad();
			if (this.current && this.video && !this.slideshow.paused()) {
				this.video.play();
				this.listener.destroy();
			}
		});

        if (this.video.status.canplay) {
            onLoad();
        } else {
			// we should wait until the image src is ready (dut to async load and responsive setup)
			const load = () => {
				const img = this.element.querySelector('img');
				if (img && img.currentSrc && img.currentSrc.length) {
					const i = new Image();
		            i.src = img.currentSrc;
					imagesLoaded([i], () => {
			            onLoad();
			        });
				} else {
					setTimeout(load, 20);
				}
			};
			load();
        }
        return this;
    }


    setCurrent(value) {
        super.setCurrent(value);
        if (this.video) {
            if (this.current && !this.slideshow.paused()) {
                this.video.play();
            } else {
                this.video.pause();
            }
        }
        return this;
    }


    play() {
        if (this.video && this.current) {
            this.video.play();
        }
        return super.play();
    }


    pause() {
        if (this.video && this.current) {
            this.video.pause();
        }
        return super.pause();
    }


    getVideo() {
        return this.video;
    }


	getImage() {
        return this.element.querySelector('img');
    }


}

export default VideoSlide;
