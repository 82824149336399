import Context from '../../global/context/context';
import {trimUrl} from '../../global/utils/string';
import threeStateTransition from '../../global/utils/three-state-transition';


class PanelContext extends Context {

	constructor({name, selector, inClass = 'active'}) {
        super({name: name, selector: selector});
        this.inClass = inClass;
    }


	setEvents(events) {
		super.setEvents(events);
		this.events.on(this.getElement(), 'click', this.onClick.bind(this));
	}


    onClick(event) {
        const element = this.getElement();
        if (event.target === element || event.target === element.querySelector(this.dataSelector('pageSlot'))) {
            this.contextManager.pop();
        }
    }


    setNavigation(navigation) {
        this.navigation = navigation;
    }


    switchIn(done, otherContext) {
        const element = this.getElement();
		const backUrl = trimUrl(this.dataAttr(element).get('backUrl'));
		const history = this.navigation.getHistory();
		if (history.isSupported() && trimUrl(location.href) !== backUrl) {
			history.mergeState({title: document.title, backUrl: backUrl});
		}
		threeStateTransition.add(element, this.inClass, () => super.switchIn(done, otherContext));
    }


    switchOut(done, otherContext) {
        const element = this.getElement();
		const backUrl = trimUrl(this.dataAttr(element).get('backUrl'));
		const backTitle = this.dataAttr(element).get('backTitle');

        if (backUrl !== trimUrl(location.href)) {
			this.navigation.push(backUrl, {title: backTitle}, backTitle);
			this.events.trigger(document, 'history:pagechange', {url: backUrl, title: backTitle});
        }
        this.dataAttr(element).set('backUrl', '');
		threeStateTransition.remove(element, this.inClass, () => super.switchOut(done, otherContext));
    }


	deactivate() {
		if (this.active) {
			super.deactivate();
			this.deinitComponents(() => {});
		}
		return this;
	}
}


export default PanelContext;
