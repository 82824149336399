import PageComponent from '../../global/page/page-component';


class SectionActions extends PageComponent {

	setActionState(actionName, stateName) {
		const state = this.getActionStateElement(actionName, stateName);
		if (state) {
			const oldState = this.getActionElement(actionName).querySelector(this.classSelector('current'));
			if (oldState) {
				this.classList(oldState).remove('current');
			}
			this.classList(state).add('current');
		}
		return this;
	}


	getActionElement(actionName) {
		return this.element.querySelector(this.dataSelector('action', actionName));
	}


	getActionStateElement(actionName, stateName) {
		return this.element.querySelector(this.dataSelector('action', actionName) + ' ' + this.dataSelector('state', stateName));
	}


	hideAction(actionName) {
		const action = this.getActionElement(actionName);
		if (action) {
			this.classList(action).add('hidden');
		}
		return this;
	}


	unhideAction(actionName) {
		const action = this.getActionElement(actionName);
		if (action) {
			this.classList(action).remove('hidden');
		}
		return this;
	}


}


export default SectionActions;
