import 'scroll-restoration-polyfill';
import {trimUrl} from '../utils/string';
import {getScrollTop} from 'get-scroll';

//import data from '../utils/data';

/*eslint-disable piggyback/no-restricted-global-extend*/
// const ga = window.ga;

class Navigation {

    constructor({events, history, baseUrl, scrollPollingDelay = 350, fileExtensions = ['pdf', 'zip', 'jpg', 'jpeg', 'gif', 'png', 'svg', 'txt']}) {
		this.events = events;
		this.history = history;
        this.baseUrl = baseUrl;
		this.scrollPollingDelay = scrollPollingDelay;
		this.fileExtensions = fileExtensions;

        if (this.history.isSupported()) {
			this.scrollTop = -1;
            this.initPageNavigation();
			this.history.mergeState({title: document.title});
        }
    }


	getHistory() {
		return this.history;
	}


	isHistoryApiSupported() {
		return this.history.isSupported();
	}


    isLocalUrl(url) {
        return (url.indexOf(this.baseUrl) === 0);
    }


	isFileUrl(url) {
		const dotPos = url.lastIndexOf('.');
		if (dotPos > -1) {
			const ext = url.substr(dotPos + 1).toLowerCase();
			return this.fileExtensions.indexOf(ext) > -1;
		}
		return false;
	}


    initPageNavigation() {
        window.history.scrollRestoration = 'manual';

        let popping = false;
		this.scrollPollingInterval = setInterval(() => {
			if (!popping) {
				const scrollTop = getScrollTop();
				if (scrollTop !== this.scrollTop) {
					this.history.mergeState({scrollTop: scrollTop});
					this.scrollTop = scrollTop;
				}
			}
		}, this.scrollPollingDelay);

        // this.events.on(window, 'window:scroll', (event) => {
		// 	if (scrollTimeout) {
		// 		clearTimeout(scrollTimeout);
		// 	}
		// 	scrollTimeout = setTimeout(onEndScroll, this.scrollPollingDelay);
        // });

        this.events.on(document, 'history:navigate', (event) => {
            if (event.detail.navigationType === 'pop') {
                popping = false;
            }
        });

        this.events.on(document, 'history:popstate', (event) => {
            const customData = {
                url: trimUrl(location.href),
                state: this.history.getState(),
                navigationType: 'pop',
				hash: location.hash.length ? location.hash.substr(1) : null
            };
            popping = true;
            this.events.trigger(document.body, 'history:navigate', customData);
        }, {capture: true});

        this.events.on(document, 'a', 'click', (event) => {
            let ret = true;
            if (!event.defaultPrevented) {
                let url = trimUrl(event.delegateTarget.href);
                // event.button check needed to avoid to catch right button clicks on FF
                if (
					event.button === 0 &&
					this.isLocalUrl(url) &&
					!this.isFileUrl(url) &&
					!event.delegateTarget.classList.contains('download') &&
					!event.delegateTarget.hasAttribute('target') &&
					!event.delegateTarget.classList.contains('noHistoryApi')) {
                    // event.preventDefault();

					let hash = null;
					let raiseNavigateEvent = true;
					if (url.indexOf('#') !== -1) {
						[url, hash] = url.split('#');
						url = trimUrl(url);
						if (url === trimUrl(location.href)) {
							this.history.push(location.href + '#' + hash, {title: document.title}, document.title);
							this.events.trigger(document.body, 'history:hashchange', {hash: hash});
							raiseNavigateEvent = false;
							event.preventDefault();
						}
					}

					if(raiseNavigateEvent) {
						const customData = {
	                        originalEvent: event,
							originalTarget: event.delegateTarget,
	                        previousUrl: location.href,
	                        url: url,
							hash: hash,
	                        state: {},
	                        navigationType: 'link'
	                    };
	                    this.events.trigger(document.body, 'history:navigate', customData);
					}

                    ret = false;
                }
            }
            return ret;
        }, {capture: false});

		return this;
    }


	push(url, state = {}, title = null) {
		if (this.history.isSupported()) {
			this.history.push(url, state, title);
		} else {
			setTimeout(() => {
				location.href = url;
			}, 10);
		}
		return this;
	}

}


export default Navigation;
