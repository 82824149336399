import PageComponent from '../../../global/page/page-component';


class HomeMapManager extends PageComponent {

	setGoogleMapFactory(googleMapFactory) {
		this.googleMapFactory = googleMapFactory;
	}


	setSectionActions(sectionActions) {
		this.sectionActions = sectionActions;
	}


    prepare(element, done) {
        this.googleMap = this.googleMapFactory.newInstance('Home', {context: 'homeMap'});
		this.googleMap.setElement(element.querySelector(this.dataSelector('googleMapContainer')));
	    this.googleMap.setMapViewportElement(this.element.querySelector(this.dataSelector('homeSchools')));
        this.googleMap.setMapShapeElement(this.element.querySelector(this.dataSelector('shapeMap')));
        this.googleMapContainer = null;
		this.mapTriggerButton = this.sectionActions.getActionElement('maps');
		this.mapTriggerListener = this.events.on(this.mapTriggerButton, 'click', this.onMapTriggerClick.bind(this));
        //this.googleMapContainer = this.element.querySelector('[data-google-map-container]');
        // super.prepare(element, () => {
        //     this.mediaSequence.init(event, done, target, {selector: '[data-bg]'});
        //     // this.googleMap = this.googleMapFactory.newInstance('Home');
        //     // this.googleMap.setMapViewportElement(this.element);
        //     // this.googleMap.setMapShapeElement(this.element.querySelector('[data-shape-map]'));
        //     // this.googleMap.init(event, () => {}, this.googleMapContainer, data(this.googleMapContainer));
        //     // this.events.addListener(this.element.closest('[data-section]'), '[data-map-trigger]', 'click', this.onMapTrigger.bind(this));
        // }, target, params);
		done();
    }


    clear(done) {
		done();
    }


    onMapTriggerClick(event) {
		// const callback = () => {
		// 	this.contextManager.push('homeMap');
		// };
		//
		// if (this.googleMap.status.ready) {
		// 	console.log('already ready');
		// 	this.googleMap.updateGeometry(callback);
		// } else {
		// 	console.log('not ready');
		// 	this.googleMap.init(
		// 		this.googleMap.getElement(),
		// 		() => this.googleMap.updateGeometry(callback)
		// 	);
		// }
        // this.mediaSequence
        //     .pause()
        //     .pauseTimer()
        // ;
    }


}


export default HomeMapManager;
