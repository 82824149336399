import {TweenMax} from 'gsap';
import Draggable from 'gsap/Draggable';
//import getPointerPosition from '../../global/utils/get-pointer-position';
import updateStatusMixin from '../../global/utils/update-status-mixin';

import PageComponent from '../../global/page/page-component';


class DistancesSlider extends updateStatusMixin(PageComponent) {

	constructor({context = null, selector = true, config = {}, status = {interacting: false, hover: false},
		stepAnimationDuration = 0.2, firstAnimationDuration = 0.6, dragOnly = 'mobile', startFromRight = false,
		isUsedClass ='used',
		values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.stepAnimationDuration = stepAnimationDuration;
		this.firstAnimation = true;
		this.firstAnimationDuration = firstAnimationDuration;
		this.isMobile = !!document.querySelector('html.mobile');
		this.startFromRight = startFromRight;
		this.values = values;
		if (dragOnly === 'mobile') {
			this.dragOnly = this.isMobile;
		} else {
			this.dragOnly = !!dragOnly;
		}
		this.firstStart = true;
		this.isUsed = false;
		this.isUsedClass = isUsedClass;
	}


	prepare(element, done) {
		this.rect = null;
		this.linkedElements = [element];
		this.id = this.dataAttr(element).get('offset');
		this.stepWidthPercent = 100 / (this.values.length - 1);
		this.value = 0;
		this.minValue = this.values[0];
		this.maxValue = this.values[this.values.length - 1];
		this.defaultValue = this.value;
		this.lastPos = null;
		this.track = element.querySelector(this.dataSelector('track'));
		this.control = element.querySelector(this.dataSelector('control'));
		this.status.interacting = false;
		this.updateStatus(true);
		this.outTimeout = null;
		this.leavingTimeout = null;
		this.controlButtonListener = this.events.on(this.element, this.dataSelector('controlButton'), 'click', this.onControlButtonClick.bind(this), {passive: true});
		this.label = element.querySelector(this.dataSelector('labelValue'));

		done();
	}

	/* author: ts */
	createGraduationScale() {
		this.dash = this.element.querySelector(this.dataSelector('dash'));
		for(let i=0; i<this.values.length; i++) {
			const clone = this.dash.cloneNode();
			this.control.appendChild(clone);
		}

		this.dash.remove();
	}

	setValueLabel(val) {
		this.label.innerHTML = val;
	}

	setUsedClass() {
		if(!this.isUsed) {
			this.isUsed = true;
			this.classList(this.element).add(this.isUsedClass);
		}
	}

	/* author: fo */
	start() {
		if (this.firstStart) {
			this.firstStart = false;

			this.createGraduationScale();

			if (this.startFromRight) {
				const tempX = this.track.getBoundingClientRect().width - this.control.getBoundingClientRect().width;
				TweenMax.set(this.control, {left: 0, right: 'auto', x: tempX});
			}

			this.draggable = Draggable.create(this.control, {
				type: 'x',
				bounds: this.track,
				zIndexBoost: false,
				edgeResistance: 1,
				cursor: 'ew-resize',
				onDragStart: this.onDragStart.bind(this),
				onDrag: this.onDrag.bind(this),
				onDragEnd: this.onDragEnd.bind(this)
			})[0];
			this.resetValue(this.value, true);
		}
		this.draggable.enable();
	}


	stop() {
		this.draggable.disable();
	}


	onDragStart(event) {
		this.oldValue = this.value;

		this.setUsedClass();
	}

	onDrag(event) {
		const newValue = this.getValueByCurrentPosition();
		if (newValue !== this.oldValue) {
			this.setValue(newValue);
			this.raiseValue(newValue);
		}
	}


	onDragEnd(event) {
		const newValue = this.getValueByCurrentPosition();
		const newPos = this.getPositionByValue(newValue);
		this.move(newPos, this.stepAnimationDuration, () => {
			if (newValue !== this.oldValue) {
				this.setValue(newValue);
				this.raiseValue(newValue);
			}
		});
	}


	getControl() {
		return this.control;
	}


	move(newPos, duration = 0.001, callback = () => {}) {
		TweenMax.to(this.control, duration, {x: newPos + 'px', onComplete: () => {
			this.draggable.update();
			callback();
		}});
	}


	resetValue(value = null, forceSlowAnimation = false, normalized = false) {
		if (!this.status.ready) {
			return this;
		}
		if (value === null) {
			value = this.defaultValue;
		}
		if (normalized) {
			value = Math.round(value * this.maxValue);
		}
		this.value = value;
		if (this.status.active) {
			this.move(this.getPositionByValue(value), (this.firstAnimation || forceSlowAnimation ? this.firstAnimationDuration : this.stepAnimationDuration));
			this.firstAnimation = false;
		}
		return this;
	}


	onControlButtonClick(event, target) {
		const offset = this.dataAttr(target).get('controlButton');
		const currentIndex = this.values.indexOf(this.value);
		const newIndex = currentIndex + offset;
		if (newIndex >= 0 && newIndex < this.values.length) {
			const newValue = this.values[newIndex];
			const newPos = this.getPositionByValue(newValue);
			this.setValue(newValue);
			this.move(newPos, this.stepAnimationDuration);
			setTimeout(() => this.raiseValue(newValue), this.stepAnimationDuration * 1000);
		}

		this.setUsedClass();
	}


	getValueByCurrentPosition() {
		const x = this.draggable.x;
		//const maxX = this.draggable.maxX;
		const minX = this.draggable.minX;
		const percent = Math.max(0, Math.min(1, x / minX));
		const index = Math.round((this.values.length - 1) * percent);
		return this.values[index];
	}


	getPositionByValue(value) {
		const index = this.values.indexOf(value);
		const valuePercent = index / (this.values.length - 1);
		return this.draggable.minX * valuePercent;
	}


	raiseValue(value) {
		this.events.trigger(this.element, 'slider:change', {
			wrapper: this,
			offset: this.id,
			value: value,
			normalizedValue: value / this.maxValue
		});
		return this;
	}


	setValue(value) {
		if (!this.status.ready) {
			return this;
		}
		this.value = value;
		return this;
	}


	getValue() {
		return this.value;
	}


	getNormalizedValue() {
		return this.value / this.maxValue;
	}

}


export default DistancesSlider;
