import DefaultPage from '../default-page';


class PersonalityDevelopmentPage extends DefaultPage {

  injectIntersectionTriggers(intersectionTriggers) {
    this.intersectionTriggers = intersectionTriggers;
  }


  injectParallaxAnimations(parallaxAnimations) {
    this.parallaxAnimations = parallaxAnimations;
  }


  prepare(element, done) {
		super.prepare(element, () => {
      this.intersectionTriggers.init(this.element, [this.dataSelector('intersect')]);
      this.parallaxAnimations.init(this.element.querySelectorAll(this.dataSelector('parallaxSpeed')));

			done();
		});
	}

  clear(done) {
    this.intersectionTriggers.destroy();
    this.parallaxAnimations.destroy();
    done();
  }
}
export default PersonalityDevelopmentPage;
