import Slideshow from '../../global/slideshow/slideshow';


class BasicSlideshow extends Slideshow {
	constructor({slideSelector, context = null, selector = true, config = {}, status = {loading: true, available: false, busy: false, paused: false}}) {
		super({slideSelector: slideSelector, context: context, selector: selector, config: config, status: status, autoSlideDefaultDuration: 0, progressLoopDuration: 0});
		this.config.async = true;
	}


	setSectionActions(sectionActions) {
		this.sectionActions = sectionActions;
	}


	prepare(element, done) {
		// to be called when the first slide is ready
		this.firstDone = done;
		this.allLoadListener = this.events.on(element, 'slideshow:load', this.onSlideshowLoad.bind(this));
		this.prevListener = this.events.on(this.sectionActions.getActionStateElement('prev', 'ready'), 'click', this.onPrevClick.bind(this));
		this.nextListener = this.events.on(this.sectionActions.getActionStateElement('next', 'ready'), 'click', this.onNextClick.bind(this));
		this.swipeListener = this.events.on(this.element, this.dataSelector('slide'), 'swipe', this.onSwipe.bind(this));
		this.pause();
		super.prepare(element, () => {});
	}


	clear(done) {
		this.allLoadListener.destroy();
		this.prevListener.destroy();
		this.nextListener.destroy();
		this.swipeListener.destroy();
		super.clear(done);
	}


	onSlideshowLoad(event) {
		this.enableButtons();
	}


	onPrevClick(event) {
		event.preventDefault();
		this.prev();
	}


	onNextClick(event) {
		event.preventDefault();
		this.next();
	}


	onSwipe(event) {
		event.preventDefault();
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
			this.prev();
		} else {
			this.next();
		}
	}


	onSlideLoad(event, target) {
		if (event.detail.index === 0) {
			if (this.firstDone) {
				this.firstDone();
			}
		}
		super.onSlideLoad(event, target);
	}


	enableButtons() {
		if (this.slides.length < 2) {
			this.sectionActions.hideAction('prev');
			this.sectionActions.hideAction('next');
			return this;
		}
		this.sectionActions.unhideAction('prev');
		this.sectionActions.unhideAction('next');
		return this.toggleButtons(true);
	}


	disableButton() {
		return this.toggleButtons(false);
	}


	toggleButtons(value) {
		this.sectionActions.setActionState('prev', value ? 'ready' : 'default');
		this.sectionActions.setActionState('next', value ? 'ready' : 'default');
		return this;
	}

}


export default BasicSlideshow;
