import 'matchmedia-polyfill';
import asq from 'asynquence';
import {TimelineMax} from 'gsap';
import {isArray} from '../utils/types';
import {getViewportWidth, getViewportHeight} from '../utils/size';
import pageInteractionMixin from '../utils/page-interaction-mixin';
import updateStatusMixin from '../utils/update-status-mixin';



class Animation extends updateStatusMixin(pageInteractionMixin()) {

    constructor({
            autoLoad = true,
            autoPlay = false,
            repeat = 0,
            repeatDelay = 0,
            speed = 1,
            mediaQueries = [],
            onResizeThreshold = 150,
            canRepeat = true,
            canSeek = true,
            canReverse = true
        }) {
        super();

        this.autoLoad = !!autoLoad;
        this.autoPlay = !!autoPlay;
        this.canRepeat = !!canRepeat;
        this.canSeek = !!canSeek;
        this.canReverse = !!canReverse;
        this.repeat = (repeat === true ? -1 : repeat);
        this.repeatDelay = repeatDelay;

        this.dryRun = false;
        this.totalDuration = 0;
        this.readyDuration = 0;
        this.asq = asq();

        this.status = {
            playing: false,
            complete: false,
            paused: true,
            loading: true,
            waiting: true
        };

        this.speedValue = speed;

        this.sections = [];

        this.onUpdateSet = false;
        // actual repeat and repeatDelay will be set only after fully loaded to avoid to loop an incomplete timeline
        this.timeline = new TimelineMax({
            paused: true,
            repeat: 0,
            repeatDelay: 0,
            onStart: this.onStart.bind(this),
            onRepeat: this.onRepeat.bind(this),
            onComplete: this.onComplete.bind(this)
        });
        this.timeline.timeScale(this.speedValue);


        this.readyToResize = false;
        if (mediaQueries.length) {
            this.mediaQueries = mediaQueries;
        }
        this.onResizeThreshold = onResizeThreshold;
    }


    setup(element, linkedElements = []) {
        this.element = element;
        if (!isArray(linkedElements)) {
            linkedElements = [linkedElements];
        }
        this.linkedElements = linkedElements;

        this.loaded = 0;
        if (this.autoLoad) {
            this.load();
        }

        this.readyToResize = false;
        if (this.mediaQueries.length) {
            this.events.on(window, 'resize', this.onResize.bind(this), {throttle: this.onResizeThreshold});
        }
    }


    // called automatically in case you pass autoLoad = true to the constructor
    load() {
        this.updateLinkedElements();
        this.prepareLoad();
        this.total = this.sections.length;
        this.asq.then((done) => {
            if (this.canRepeat && this.repeat) {
                this.timeline.repeat(this.repeat).repeatDelay(this.repeatDelay);
            }
            this.updateStatus('loading', false);
            this.emit('load');
        });
    }


    // methods to control the playback:

    play() {
        if (!this.status.playing) {
            if (this.status.complete) {
                this.restart();
            } else {
                this.updateStatus({playing: true, paused: false});
                this.timeline.play();
            }
        }
        return this;
    }


    pause() {
        if (!this.status.paused) {
            this.updateStatus({playing: false, paused: true});
            this.timeline.pause();
        }
        return this;
    }


    restart() {
        this.timeline.pause();
        this.beforeRestart();
        this.updateStatus({playing: true, paused: false, complete: false});
        this.timeline.restart();
        return this;
    }


    speed(value) {
        this.speedValue = value;
        this.timeline.timeScale(value);
        return this;
    }


    reverse() {
        if (this.canReverse) {
            this.beforeReverse();
            this.timeline.reverse();
        }
        return this;
    }


    seek(value, suppressEvents = true) {
        if (this.canSeek) {
            this.timeline.seek(value, suppressEvents);
        }
        return this;
    }


    duration() {
        return this.totalDuration;
    }


    time() {
        return Math.round(this.timeline.time() * 1000) / 1000;
    }


    progress() {
        return Math.round(this.timeline.time() / this.totalDuration * 1000) / 1000;
    }


    // method to be ovveriden in inherited classes:

    applyMediaQuery(view, query) {
        // override this to make the animation responsive
    }


    prepareLoad() {
        // override this to add sections and prepare everything needed
    }


    beforeRestart() {
        // override this to prepare the animation to restart (manually or because is looping)
    }


    beforeReverse() {
        // override this to prepare the animation to reverse
    }


    // method to be used from inherited classes only:

    addSection(params, loadingCallback, prepareCallback) {
        const index = this.sections.length;
        const section = {
            index: index,
            ready: false,
            load: loadingCallback,
            prepare: prepareCallback
        };
        this.sections[index] = section;

        this.dryRun = true;
        const timeline = section.prepare(section, params);
        const sectionDuration = timeline.totalDuration();
        this.totalDuration += sectionDuration;
        section.duration = sectionDuration;
        this.dryRun = false;
        this.asq.then((done) => {
            section.load(done, section, params);
        }).then((done) => {
            this.loaded++;
            this.emit('progress', {total: this.total, loaded: this.loaded});
            section.ready = true;
            this.readyDuration += section.duration;
            this.timeline.add(section.prepare(section, params));
            if (this.status.waiting) {
                this.updateStatus('waiting', false);
            }
            if (index === 0) {
                this.emit('canplay');
                if (this.autoPlay && !this.status.playing) {
                    this.play();
                }
            }
            done();
        });
    }


    resize() {
        if (!this.readyToResize) {
            return this;
        }
        const view = {
            width: getViewportWidth(),
            height: getViewportHeight()
        };

        for (const query of this.mediaQueries) {
            if (!query.media || matchMedia(query.media).matches) {
                this.applyMediaQuery(view, query);
                break;
            }
        }
        return this;
    }


    // internal methods. No need to use this in inherited classes nor from public interface:

    emit(eventName, data = {}) {
        data = Object.assign({wrapper: this}, data);
        this.events.trigger(this.element, 'animation:' + eventName, data);
        return this;
    }



    // internal event handlers:

    onResize(event) {
        this.resize();
    }


    onStart() {
        this.emit('start');
    }


    onRepeat() {
        this.beforeRestart();
        this.updateStatus({playing: true, paused: false, complete: false});
        this.emit('repeat');
    }


    onComplete() {
        if (this.totalDuration > this.readyDuration) {
            this.updateStatus('waiting', true);
        } else {
            this.updateStatus({paused: true, playing: false, complete: true});
        }
    }


    // available for debug purposes only, it is better to not access the timeline directly
    getTimeline() {
        return this.timeline;
    }

}


export default Animation;
