import updateStatusMixin from '../utils/update-status-mixin';
import pageInteractionMixin from '../utils/page-interaction-mixin';


class MediaContrastTracker extends pageInteractionMixin(updateStatusMixin()) {

    constructor({
        autoRun = true,
        darkThresholdPercent = 0.5,
        mixedThresholdPercent = 0.2,
        skipFrames = 3,
        samplingRate = 2,
        darkFlagName = 'dark',
        mixedFlagName = 'mixed',
        linkedElements = []
    } = {}) {
		super();
        this.autoRun = autoRun;
        this.linkedElements = linkedElements;
        this.started = false;
        this.running = false;
        this.element = null;
        this.type = null;
        this.sourceParams = {left: 0, top: 0, width: 0, height: 0, scale: 1};
        this.targetParams = {left: 0, top: 0, width: 0, height: 0};
        this.canvas = document.createElement('canvas');
        this.context = this.canvas.getContext('2d');
        this.ready = false;
        this.darkThresholdPercent = darkThresholdPercent;
        this.mixedThresholdPercent = mixedThresholdPercent;
        // 0-255 is the contrast range provided by the evaluation
        this.darkThreshold = 255 * this.darkThresholdPercent;
        this.mixedThreshold = 255 * this.mixedThresholdPercent;
        this.darkFlagName = darkFlagName;
        this.mixedFlagName = mixedFlagName;
        this.status = {};
        this.status[darkFlagName] = false;
        this.status[mixedFlagName] = false;
        this.framesThreshold = skipFrames + 1;
        this.framesCount = 0;
        this.samplingRate = samplingRate;
    }


    setLinkedElements(elements) {
        this.linkedElements = elements;
        return this;
    }


    getLinkedElements() {
        return this.linkedElements;
    }


    setSourceParams(left, top, width, height, scale) {
        this.sourceParams = {
            left: left,
            top: top,
            width: width,
            height: height,
            scale: scale
        };
        return this;
    }


    setTargetParams(left, top, width, height) {
        this.targetParams = {
            left: left,
            top: top,
            width: width,
            height: height
        };
        this.canvas.width = width;
        this.canvas.height = height;
        return this;
    }


    setCanvas(canvas) {
        this.canvas = canvas;
        this.canvas.width = this.targetParams.width;
        this.canvas.height = this.targetParams.height;
        this.context = this.canvas.getContext('2d');
        return this;
    }


    getCanvas() {
        return this.canvas;
    }


    setElement(element) {
        const wasRunning = this.running;
        this.stop();
        this.element = element;
        if ((element && wasRunning) || (this.autoRun && !this.started)) {
            this.started = true;
            this.type = (this.element instanceof HTMLImageElement ? 'image' : 'video');
            this.start();
        }
        return this;
    }


    unsetElement() {
        return this.setElement(null);
    }


    start() {
        if (!this.running && this.element) {
            this.running = true;
            this.update();
        }
        return this;
    }


    stop() {
        if (this.running) {
            this.running = false;
        }
        return this;
    }


    getRawData() {
        return this.context.getImageData(0, 0, this.targetParams.width, this.targetParams.height).data;
    }


    refreshCanvas() {
        if (!this.element || !this.sourceParams.width || !this.targetParams.width) {
            return this;
        }
        const sourceLeft = this.sourceParams.left * this.sourceParams.scale;
        const sourceTop = this.sourceParams.top * this.sourceParams.scale;
        const sourceWidth = this.sourceParams.width * this.sourceParams.scale;
        const sourceHeight = this.sourceParams.height * this.sourceParams.scale;
        this.context.drawImage(
            this.element,
            sourceLeft,
            sourceTop,
            sourceWidth,
            sourceHeight,
            this.targetParams.left,
            this.targetParams.top,
            this.targetParams.width,
            this.targetParams.height
        );
        return this;
    }

    // reference: https://24ways.org/2010/calculating-color-contrast/
    getPixelContrast(red, green, blue) {
        return ((red * 299) + (green * 587) + (blue * 114)) / 1000;
    }


    update() {
        const callback = () => {
            this.framesCount++;
            if (this.framesCount % this.framesThreshold === 0) {
                this.framesCount = 0;
                this.refreshCanvas();
                try {
                    if (this.element && this.sourceParams.width && this.targetParams.width) {
						let data;
						try {
							data = this.getRawData();
						} catch (e) {
							// Edge could be buggy, failing here if not using https
						}
						if (data && data.length) {
							let values = [];
	                        let sum = 0;
	                        // each pixel's r,g,b,a are stored in separate sequential array elements
	                        const step = 4 * this.samplingRate;
	                        for (let i = 0; i < data.length; i += step) {
	                            const pixelContrast = this.getPixelContrast(
	                                data[i],        // red
	                                data[i + 1],    // green
	                                data[i + 2]     // blue
	                            );
	                            sum += pixelContrast;
	                            values.push(pixelContrast);
	                        }
	                        const average = sum / (data.length / step);
	                        // reference: https://24ways.org/2010/calculating-color-contrast/
	                        const dark = (average < this.darkThreshold);
	                        let mixed = null;
	                        if (this.mixedThreshold) {
	                            let deviation = 0;
	                            for (const value of values) {
	                                deviation += Math.pow(value - average, 2);
	                            }
	                            deviation = Math.sqrt(deviation / values.length);
	                            mixed = (deviation > this.mixedThreshold);
	                            //console.log(Math.round(this.mixedThreshold * 100) / 100, Math.round(deviation * 100) / 100, mixed);
	                        }
							if (this.running) {
	                            let changed = false;
	                            if (this.darkThreshold > 0 && this.status[this.darkFlagName] !== dark) {
	                                changed = true;
	                                this.updateStatus(this.darkFlagName, dark);
	                            }
	                            if (this.mixedThreshold > 0 && this.status[this.mixedFlagName] !== mixed) {
	                                changed = true;
	                                this.updateStatus(this.mixedFlagName, mixed);
	                            }
	                            if (changed) {
	                                this.raiseEvent();
	                            }
	                        }
							data = null;
	                        values = null;
						}
                    }
                } catch(error) {
                    //console.error(error);
                    // it's ok to silently fail
                }
            }
            if (this.running) {
                requestAnimationFrame(callback);
            }
        };
        callback();
    }


    resetFlags() {
        this.updateStatus(this.darkFlagName, false);
        this.updateStatus(this.mixedFlagName, false);
        return this;
    }


    raiseEvent() {
        this.events.trigger(this.element, 'contrast:change', Object.assign({}, this.status));
        return this;
    }


    isDark() {
        return this.status.dark;
    }

}


export default MediaContrastTracker;
