import escape from '../../utils/escape';
import svgIcon from '../../utils/svg-icon';

export default function tpl(buttons) {
    return `
		${buttons.map((button, index) => `
			<button class="lsa-dialog__button lsa-button${button.modifier ? ' lsa-dialog__button--' + escape(button.modifier) + ' lsa-button--' + escape(button.modifier) : ''}" data-lsa-dialog-button-index="${index}">
				${'preIcon' in button ? `<span class="lsa-button__icon">${svgIcon(button.preIcon)}</span>` : ''}
				<span class="lsa-button__label">${escape(button.label)}</span>
				${'postIcon' in button ? `<span class="lsa-button__icon">${svgIcon(button.postIcon)}</span>` : ''}
			</button>
		`).join('')}
    `;
}
