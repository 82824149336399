import isVisible from '../../global/utils/is-visible';
import Slideshow from '../../global/slideshow/slideshow';


class HeaderSlideshow extends Slideshow {
    constructor({slideSelector, progressSelector, context = null, selector = true, config = {}, status = {loading: true, available: false, busy: false, paused: false}, autoSlideDefaultDuration = 0, stopAutoSlideOnInteraction = true, progressLoopDuration = 1}) {
		super({slideSelector: slideSelector, context: context, selector: selector, config: config, status: status, autoSlideDefaultDuration: autoSlideDefaultDuration, stopAutoSlideOnInteraction: stopAutoSlideOnInteraction, progressLoopDuration: progressLoopDuration});
        this.progressSelector = progressSelector;
		this.config.async = true;
    }

	setCoverGeometryTracker(coverGeometryTracker) {
		this.coverGeometryTracker = coverGeometryTracker;
	}


	setMediaContrastTracker(mediaContrastTracker) {
		this.mediaContrastTracker = mediaContrastTracker;
	}


	setSectionActions(sectionActions) {
		this.sectionActions = sectionActions;
	}


    prepare(element, done) {
		// to be called when the first slide is ready
		this.firstDone = done;
        this.allLoadListener = this.events.on(element, 'slideshow:load', this.onSlideshowLoad.bind(this));
        this.buttonListener = this.events.on(this.sectionActions.getActionStateElement('next', 'ready'), 'click', this.onNextClick.bind(this));
        this.autoSlideDefaultDuration = this.dataAttr(element).get('autoSlideDefaultDuration');
        this.progressAnimator = this.progressAnimatorFactory.newInstance('circle', {element: this.progressSelector});
        this.sectionEnabled = false;
		this.inside = false;
		this.observer = new IntersectionObserver(this.onInside.bind(this), {
			threshold: [0]
		});
		this.observer.observe(element);
        this.coverTitle = this.element.closest(this.dataSelector('sectionContext')).querySelector(this.dataSelector('coverTitle'));
        this.coverTitleText = this.coverTitle.querySelector(this.dataSelector('coverTitleLabel'));

        this.slideshowChangeListener = this.events.on(this.element, 'slideshow:change', this.onSlideshowChange.bind(this));
        this.mediaResizeListener = this.events.on(this.element, 'media:resize', this.onMediaResize.bind(this));

        this.swipeListener = this.events.on(this.element, this.dataSelector('slide'), 'swipe', this.onSwipe.bind(this));

		this.loadedmetaListener = this.events.on(this.element, '[data-media="video"]',  'video:loadedmetadata', this.onLoadedmeta.bind(this));
		this.pause();
        super.prepare(element, () => {});
    }


    clear(done) {
		this.observer.disconnect();
        this.coverGeometryTracker.stop(true);
        this.mediaContrastTracker.stop();
        this.mediaContrastTracker.resetFlags();
        this.allLoadListener.destroy();
        this.buttonListener.destroy();
        this.swipeListener.destroy();
		this.loadedmetaListener.destroy();
        this.progressAnimator = null;
        super.clear(done);
    }


	start() {
		this.update();
	}


	stop() {
		this.pause();
	}


    onSlideshowLoad(event) {
        this.enableButton();
    }


	onNextClick(event) {
        event.preventDefault();
        this.next();
    }


    onSwipe(event) {
        event.preventDefault();
        if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
            this.prev();
        } else {
            this.next();
        }
    }


	onSlideLoad(event, target) {
        if (event.detail.index === 0) {
			this.forwardCurrentMedia();
            this.coverGeometryTracker.start();
            this.mediaContrastTracker.start();
            //.setLinkedElements([this.element.closest('[data-section]')])
            // .setCanvas(document.querySelector('#testCanvas'))
            // .setSourceParams(0, 0, 350, 50)
            // .setTargetParams(0, 0, 350, 50)
			if (this.firstDone) {
				this.firstDone();
			}
        }
        super.onSlideLoad(event, target);
    }


	onLoadedmeta(event, target) {
		const slideElement = target.closest(this.slideSelector);
		if (this.currentSlide && this.currentSlide.getElement() === slideElement) {
			this.forwardCurrentMedia();
		}
	}



    onSlideshowChange(event) {
		this.forwardCurrentMedia();
    }


    onMediaResize(event) {
		const params = event.detail;
        if (isVisible(this.coverTitle)) {
            const style = getComputedStyle(this.coverTitleText);
            const paddingLeft = parseInt(style.paddingLeft, 10);
            const paddingRight = parseInt(style.paddingRight, 10);
            const paddingTop = parseInt(style.paddingTop, 10);
            const paddingBottom = parseInt(style.paddingBottom, 10);
            const textLeft = this.coverTitle.offsetLeft + this.coverTitleText.offsetLeft + paddingLeft;
            const textTop = this.coverTitle.offsetTop + paddingTop;
            const textWidth = this.coverTitleText.offsetWidth - paddingLeft - paddingRight;
            const textHeight = this.coverTitleText.offsetHeight - paddingTop - paddingBottom;
            const left = textLeft - params.left;
            const top = textTop - params.top;

            this.mediaContrastTracker
                .setSourceParams(left, top, textWidth, textHeight, params.scale)
                .setTargetParams(0, 0, textWidth, textHeight)
                .start()
            ;
        } else {
            this.mediaContrastTracker.stop().resetFlags();
        }
    }


	play() {
		super.play();
		this.coverGeometryTracker.start();
        this.mediaContrastTracker.start();
		return this;
	}


	pause() {
		super.pause();
		this.coverGeometryTracker.stop();
        this.mediaContrastTracker.stop();
		return this;
	}


	onInside(entries, observer) {
		for (const entry of entries) {
			const inside = entry.intersectionRatio > 0;
			if (inside !== this.inside) {
				this.inside = inside;
				this.update();
			}
			break;
		}
	}


    update() {
		if (this.status.active) {
	        if (this.inside) {
	            this.play();
	        } else {
	            this.pause();
	        }
		}
        return this;
    }


    enableButton() {
        if (this.slides.length < 2) {
			this.sectionActions.hideAction('next');
            return this;
        }
		this.sectionActions.unhideAction('next');
        return this.toggleButton(true);
    }


    disableButton() {
        return this.toggleButton(false);
    }


    toggleButton(value) {
		this.sectionActions.setActionState('next', value ? 'ready' : 'default');
        return this;
    }


	forwardCurrentMedia() {
		const currentMedia = this.getCurrentMediaElement();
        this.coverGeometryTracker
            .setElement(currentMedia)
        ;
        this.mediaContrastTracker
            .setElement(currentMedia)
        ;
	}


    getCurrentMediaElement() {
		let media;
		if (this.currentSlide.getVideo && this.currentSlide.getVideo().status.metaready) {
			media = this.currentSlide.getVideo().video;
		} else {
			media = this.currentSlide.getImage();
		}
        return media;
    }


}


export default HeaderSlideshow;
