import DefaultPage from '../../../lsa/page/default-page.js';


class ElternSearchPage extends DefaultPage {


	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('ElternSearch', {selector: this.dataSelector('elternSearch'), context: 'default'});

			done();
		});
	}
}


export default ElternSearchPage;
