import PageComponent from '../page/page-component';


class Pictures extends PageComponent {

    prepare(element, done) {
        for (const picture of element.querySelectorAll('[data-async-picture]')) {
			for (const node of picture.querySelectorAll('[data-srcset]')) {
				node.setAttribute('srcset', node.getAttribute('data-srcset'));
				node.removeAttribute('data-srcset');
			}
			picture.removeAttribute('data-async-picture');
        }
        done();
    }

}

export default Pictures;
