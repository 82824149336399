import PageComponentCollection from '../../global/page/page-component-collection';
import {TweenMax} from 'gsap';

class DistancesCircleIndicators extends PageComponentCollection {

	setProgressAnimatorFactory(progressAnimatorFactory) {
		this.progressAnimatorFactory = progressAnimatorFactory;
	}

	constructor({context = null, selector = true, config = {}, status = {},
			duration = 1, color1 = '#0071BC', color2 = '#ED1C24', width = 2, onResizeThreshold = 150
		} = {}) {
			super({context: context, selector: selector, config: config, status: status});
			this.duration = duration;
			this.width = width;
			this.color1 = color1;
			this.color2 = color2;
			this.onResizeThreshold = 150;

			this.isShown = false;
	}

	prepare(element, done) {
		this.circleInner = this.progressAnimatorFactory.newInstance('Circle',
			{element: element.querySelector(this.dataSelector('distancesCircleInner')),
			lineWidth: this.width, color: this.color1, angleOffset: -90});
		this.circleOuter = this.progressAnimatorFactory.newInstance('Circle',
			{element: element.querySelector(this.dataSelector('distancesCircleOuter')),
			lineWidth: this.width, color: this.color2, angleOffset: -90});

		this.innerLabel = element.querySelector(this.dataSelector('distancesCircleInnerLabel'));
		this.outerLabel = element.querySelector(this.dataSelector('distancesCircleOuterLabel'));

		this.events.on(window, 'resize', this.onResize.bind(this), {throttle: this.onResizeThreshold});

		done();
	}

	onResize() {
		this.circleOuter.recreate();
		this.circleInner.recreate();
		this.circleOuter.plot(0, this.outerVal);
		this.circleInner.plot(0, this.innerVal);
	}

	setMaxTime(val) {
		this.maxTime = val;
	}

	setOuter(data) {
		this.outerVal = data.minutes/this.maxTime;
		this.circleOuter.animate(this.circleOuter.getCurrentValue(), this.outerVal, this.duration, false, 'Quad.easeOut');
		this.outerLabel.innerHTML = data.val + 'h';
	}

	setInner(data) {
		this.innerVal = data.minutes/this.maxTime;
		this.circleInner.animate(this.circleInner.getCurrentValue(), this.innerVal, this.duration, false, 'Quad.easeOut');
		this.innerLabel.innerHTML = data.val + 'h';
	}

	//not used at the moment
	setPosition(obj) {
		if(!obj) return;
		this.element.style.left = obj.x + '%';
		this.element.style.top = obj.y + '%';

		TweenMax.to(this.element, 0, {scale: 0.6, opacity: 0, ease: 'Power4.easeOut', force3D: true});
		TweenMax.to(this.element, this.duration, {scale: 1, opacity: 1, ease: 'Power4.easeOut', force3D: true});
	}

	show() {
		if(!this.isShown) {
			this.isShown = true;

			TweenMax.to(this.element, 0, {scale: 0.6, opacity: 0, ease: 'Power4.easeOut', force3D: true});
			TweenMax.to(this.element, this.duration, {scale: 1, opacity: 1, ease: 'Power4.easeOut', force3D: true});
		}
	}
}

export default DistancesCircleIndicators;
