import Factory from '../di/factory';


class GoogleMapFactory extends Factory {

	constructor({container, prefix = '', suffix = 'GoogleMap'}) {
		super({container: container, prefix: prefix, suffix: suffix});
    }

}


export default GoogleMapFactory;
