import pageInteractionMixin from '../utils/page-interaction-mixin';


class Slide extends pageInteractionMixin() {

    constructor({slideshow, element, index, type, subtype, duration}) {
		super();
        this.slideshow = slideshow;
        this.element = element;
        this.index = index;
        this.type = type;
		this.subtype = subtype;
        this.duration = duration;
		this.current = false;
    }


    getDuration() {
        return this.duration;
    }


    setDuration(value) {
        this.duration = value;
        return this;
    }


    load() {
        this.events.trigger(this.element, 'slide:load', {
            slide: this,
            type: this.type,
            index: this.index
        });
        return this;
    }


    beforeSwitchIn(done) {
        done();
    }


    beforeSwitchOut(done) {
        done();
    }


    afterSwitchIn(done) {
        done();
    }


    afterSwitchOut(done) {
        done();
    }


    getIndex() {
        return this.index;
    }


    getType() {
        return this.type;
    }


	getSubtype() {
		return this.subtype;
	}


    getElement() {
        return this.element;
    }


    setCurrent(value) {
        this.current = value;
        return this;
    }


    getCurrent() {
        return this.current;
    }


    play() {
        return this;
    }


    pause() {
        return this;
    }

}

export default Slide;
