import pageInteractionMixin from '../utils/page-interaction-mixin';
import {isArray} from '../utils/types';
import buttonsTemplate from './templates/buttons';


class Dialog extends pageInteractionMixin() {

	constructor({element, showClass = 'active'}) {
		super();
		this.element = element;
        this.showClass = showClass;
		this.disablingContext = false;
		this.opened = false;
		this.ready = false;
		this.buttons = [];
		this.onClose = [];
    }

	init() {
		if (!this.ready) {
			this.titleContainer = this.element.querySelector(this.dataSelector('dialogTitle'));
			this.textContainer = this.element.querySelector(this.dataSelector('dialogText'));
			this.buttonsContainer = this.element.querySelector(this.dataSelector('dialogButtons'));
			this.ready = true;
		}
		return this;
	}


	setContextManager(contextManager) {
		this.contextManager = contextManager;
	}


	open({title = '', text, buttons = [], disableContext = true, onClose = null}) {
		this.init();
		if (this.opened && this.onClose.length) {
			for (const callback of this.onClose) {
				callback();
			}
		}
		this.titleContainer.innerHTML = title;
		this.textContainer.innerHTML = text;
		this.buttons = buttons;
		this.buttonsContainer.innerHTML = buttonsTemplate(buttons);
		this.clickHandler = this.events.on(this.element, this.dataSelector('dialogButtonIndex'), 'click', this.onButtonClick.bind(this));
		if (this.disableContext) {
			this.contextManager.getCurrentContext().deactivate();
		}
		this.disablingContext = disableContext;
		this.opened = true;
		this.onClose = (onClose !== null ? (isArray(onClose) ? onClose : [onClose]) : []);
		this.classList(this.element).add(this.showClass);
	}


	close() {
		this.clickHandler.destroy();
		this.buttons = [];
		if (this.opened) {
			if (this.onClose.length) {
				for (const callback of this.onClose) {
					callback();
				}
				this.onClose = [];
			}
			this.classList(this.element).remove(this.showClass);
			if (this.disablingContext) {
				this.contextManager.getCurrentContext().activate();
			}
			this.disablingContext = false;
			this.opened = false;
		}
	}


	onButtonClick(event, target) {
		const index = this.dataAttr(target).get('dialogButtonIndex');
		if (index >= 0 && index < this.buttons.length) {
			this.buttons[index].callback(this);
		}
	}


	isOpened() {
		return this.opened;
	}

}


export default Dialog;
