import {TweenMax} from 'gsap';
import {getScrollTop} from 'get-scroll';
import getOffsetTop from '../../global/utils/offset-top';
import {getViewportHeight} from '../../global/utils/size';
import pageInteractionMixin from '../utils/page-interaction-mixin';


// TODO: a lot of magic values here, need to be refactored
class ParallaxAnimations extends pageInteractionMixin() {

	constructor({
		speeds = {fast: 0.07, mid: 0.05, slow: 0.03, fast2: 0.06, mid2: 0.035, slow2: 0.015},
		duration = 0.8
	} = {}) {
		super();
		this.speeds = speeds;
		this.duration = duration;
		this.elements = [];
		this.viewportHeight = 0;
	}


	init(elements) {
		for (const element of elements) {
			let speed;
			const speedKey = this.dataAttr(element).get('parallaxSpeed');
			if (isNaN(speedKey)) {
				speed = this.speeds[speedKey] * 0.5;
			} else {
				speed =  speedKey;
			}
			this.elements.push({element: element, speed: speed, start: 0});
		}

		requestAnimationFrame(() => {
			this.updateSizes();
			this.updateElements(false);
		});

		this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.scrollListener = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
	}


	onResize(event) {
		const viewportHeight = getViewportHeight();
		if (viewportHeight !== this.viewportHeight) {
			this.updateSizes(viewportHeight);
			this.updateElements(true);
		}
	}


	onScroll(event) {
		this.updateElements(true);
	}


	updateSizes(viewportHeight = false) {
		this.viewportHeight = viewportHeight === false ? getViewportHeight() : viewportHeight;
		for (let i = 0, end = this.elements.length; i < end; i++) {
			const entry = this.elements[i];
			const top =  getOffsetTop(entry.element);
			entry.start = Math.max(top - this.viewportHeight * 0.25, 0);
		}
	}


	updateElements(animate = true) {
		const scrollTop = getScrollTop();
		for (let i = 0, end = this.elements.length; i < end; i++) {
			const entry = this.elements[i];

			const diff = Math.max( Math.min(scrollTop - entry.start, this.viewportHeight), -this.viewportHeight);
			let y = -diff * entry.speed;
			if (y === -0) {
				y = 0;
			}
			const duration = animate ? this.duration : 0;
			TweenMax.killTweensOf(entry.element);
			TweenMax.to(entry.element, duration, {y: y + 'px', ease: 'Power1.easeOut', force3D: true});
		}
	}


	destroy() {
		this.resizeListener.destroy();
		this.scrollListener.destroy();
		for (let i = 0, end = this.elements.length; i < end; i++) {
			TweenMax.killTweensOf(this.elements[i].element);
		}
		this.elements = null;
	}

}


export default ParallaxAnimations;
