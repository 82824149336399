// http://stackoverflow.com/questions/9705123/how-can-i-get-sin-cos-and-tan-to-use-degrees-instead-of-radians

export function toDeg(angle) {
	return angle * (180 / Math.PI);
}


export function toRad(angle) {
	return angle * (Math.PI / 180);
}
