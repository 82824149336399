import DefaultPage from '../default-page';


class ManifestPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('Footnotes', {context: 'panel'});
			done();
		});
	}


}


export default ManifestPage;
