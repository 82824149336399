import PageComponent from '../../global/page/page-component';


class ExclusionMenu extends PageComponent {

	setColorSequence(colorSequence) {
		this.colorSequence = colorSequence;
	}

    prepare(element, done) {
        this.current = null;
		this.globalOutTimeout = null;
        this.outTimeout = null;
		this.switchColor = !!this.dataAttr(element).get('switchColor');
        this.mouseoverHandler = this.events.on(this.element, this.dataSelector('menuTrigger'), 'touchstart mouseover', this.onOver.bind(this), {passive: true});
        this.mouseoutHandler = this.events.on(this.element, this.dataSelector('menuTrigger'), 'touchend mouseout click', this.onOut.bind(this), {passive: true});
        done();
    }


    clear(done) {
        this.current = null;
        if (this.outTimeout) {
            clearTimeout(this.outTimeout);
        }
        this.mouseoverHandler.destroy();
        this.mouseoutHandler.destroy();
        done();
    }


    onOver(event, target) {
        if (this.outTimeout) {
            clearTimeout(this.outTimeout);
            this.outTimeout = null;
        }
		const index = this.dataAttr(target).get('menuTrigger');
        if (index !== this.current) {
            this.current = index;
			this.out(false);
            this.over(index);
        }
    }


	onOut(event, target) {
        if (this.outTimeout) {
            clearTimeout(this.outTimeout);
        }
        this.outTimeout = setTimeout(() => {
			const index = target ? this.dataAttr(target).get('menuTrigger') : true;
			this.out(index);
        }, 20);
    }


    over(index) {
        this.classList(this.element).add('menuSelected');
		for (const element of this.element.querySelectorAll(this.dataSelector('menuTarget', index))) {
			this.classList(element).add('menuSelected');
		}
        if (this.switchColor) {
            this.colorSequence.next();
        }
    }


    out(index) {
		if (index !== false) {
			this.current = null;
			this.classList(this.element).remove('menuSelected');
		}
		for (const element of this.element.querySelectorAll('.' + this.classPrefix + 'menuSelected')) {
			this.classList(element).remove('menuSelected');
		}
    }

}

export default ExclusionMenu;
