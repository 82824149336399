import PageComponentCollection from '../../global/page/page-component-collection';


class InfographicManager extends PageComponentCollection {


    prepare(element, done) {
		this.startedCount = 0;

		const intersectionOptions = {
		    threshold: 0.5
		};
		this.intersectionObserver = new IntersectionObserver(this.onIntersect.bind(this), intersectionOptions);

		done();
    }


    clear(done) {
		this.intersectionObserver.disconnect();
		done();
    }


	start() {
		for (const infographic of this.components.values()) {
			const element = infographic.getElement();
			element.wrapper = infographic;
			this.intersectionObserver.observe(element);
		}
	}


	onIntersect(entries, observer) {
		for (const entry of entries) {
			if ('wrapper' in entry.target) {
				const infographic = entry.target.wrapper;
				if (!infographic.isPlayed()) {
					infographic.run();
					delete entry.target.wrapper;
					this.intersectionObserver.unobserve(entry.target);
	                this.startedCount++;
					if (this.startedCount === this.count()) {
						this.intersectionObserver.disconnect();
					}
				}
			}
		}
	}


}


export default InfographicManager;
