import PageTransition from '../../global/page/page-transition';


class PanelPageTransition extends PageTransition {

	setLoaderAnimation(loaderAnimation) {
		this.loaderAnimation = loaderAnimation;
	}


	match(event) {
        const request = event.detail;
        if ((request.navigationType === 'link' && this.dataAttr(request.originalTarget).get('contextTarget') === 'panel') ||
            (request.navigationType === 'pop' && 'backUrl' in request.state)) {
			// console.log('panel match', request.navigationType);
            return true;
        }
        return false;
    }


	updateHistory() {
		super.updateHistory();
        this.backUrl = this.dataAttr(this.request.originalTarget).get('backUrl') || ''; // || this.request.previousUrl || '';
		this.backTitle = this.dataAttr(this.request.originalTarget).get('backTitle') || '';
		this.history.mergeState({backUrl: this.backUrl, title: this.backTitle});
		return this;
	}


	updateCurrentHistory() {
		super.updateCurrentHistory();
        if (this.request.state.backUrl) {
            this.backUrl = this.request.state.backUrl;
			this.backTitle = this.request.state.title;
        }
		return this;
	}


	commit(done) {
		this.updateDocumentTitle();
		const panel = this.contextManager.getContext('panel').getElement();
		this.dataAttr(panel).set('backUrl', this.backUrl);
		this.dataAttr(panel).set('backTitle', this.backTitle);
		this
			.raisePageChangeEvent()
			.raiseHashChangeEvent()
		;

		done();
	}


	beginAnimation(done) {
		this.loaderAnimation.play(done, {cover: false});
	}


	endAnimation(done) {
		this.contextManager.push('panel', () => {
			this
				.updateScrollPos()
				.storeScrollPos()
			;
			this.loaderAnimation.pause();
			done();
		});
	}


}


export default PanelPageTransition;
