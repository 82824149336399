import NavigationTracker from './navigation-tracker';


class GoogleTagManagerNavigationTracker extends NavigationTracker {


	init() {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.tracker = ('dataLayer' in window ? window.dataLayer : null);
	}


	doTrack(url, title) {
		if (this.tracker) {
			this.tracker.push({event: 'virtualPageView'});
		} else {
			console.log('page change with gtm:', url, title);
		}
	}


	doTrackEvent(category, action, name = null, value = null) {
		if (this.tracker) {
			console.log('tracking event not configured with google tag manager');
		} else {
			console.log('tracking event with gtm', category, action, name, value);
		}
	}

}


export default GoogleTagManagerNavigationTracker;
