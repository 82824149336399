import DefaultPage from '../default-page';


class DistancesPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('Distances',  {selector: this.dataSelector('distances'), context: 'default'});
			console.log('#bääm');
			// const el = element.querySelector(this.dataSelector('distances'));
			// const distances = this.addNewComponent('Distances');
			// distances.setElement(el);

			done();
		});
	}
}

export default DistancesPage;
