import {TweenMax} from 'gsap';
import {getViewportWidth, getViewportHeight} from '../../../global/utils/size';
import PageComponent from '../../../global/page/page-component';


class Teasers extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}, breakpoint = 760} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.breakpoint = breakpoint;
	}


	prepare(element, done) {
		this.clipPathSupported = !!document.querySelector('html.clippath');
		this.stickySupported = !!document.querySelector('html.csspositionsticky');
		this.isMobile = !!document.querySelector('html.mobile');

		// this.teasersContainer = element.querySelector(this.dataSelector('teasersContainer'));
		// this.reflect = element.querySelector(this.dataSelector('teaser', 'reflect'));
		// this.research = element.querySelector(this.dataSelector('teaser', 'research'));
		//
		// if (this.clipPathSupported && (!this.isMobile || this.stickySupported)) {
		// 	this.researchContainer = this.research.querySelector(this.dataSelector('teaserContainer'));
		// 	this.researchContent = this.research.querySelector(this.dataSelector('teaserContent'));
		//
		// 	this.dummy = {containerY: 50, contentY: -100};
		// 	this.timeline = new TimelineMax({paused: true});
		// 	this.timeline.to(this.dummy, 1, {containerY: -50, contentY: 100, ease: 'Linear.easeNone'});
		//
		// 	// we need to do this to avoid content jumps on mobile with chrome when the address bar is collapsing
		// 	this.viewportWidth = getViewportWidth();
		// 	this.viewportHeight = getViewportHeight();
		//
		// 	if (this.isMobile) {
		// 		const rect = element.getBoundingClientRect();
		// 		this.sizeRatio = rect.height / this.viewportHeight;
		// 		TweenMax.set(element, {height: rect.height + 'px'});
		// 		TweenMax.set([this.teasersContainer, this.reflect, this.research], {height: this.viewportHeight + 'px'});
		// 	}
		// }
		//
		// this.reflectEntered = false;
		// this.researchEntered = false;
		// this.update();
		//
		// this.scrollListener = this.events.on(window, 'window:scroll window:resize', this.onScrollResize.bind(this));
		done();
	}


	clear(done) {
		// this.scrollListener.destroy();
		done();
	}


	onScrollResize(event) {
		this.update(event);
	}


	update(event = null) {
		const viewportWidth = getViewportWidth();
		const viewportHeight = getViewportHeight();
		if (this.clipPathSupported && this.isMobile && event && event.type.toLowerCase() === 'resize' && (viewportWidth !== this.viewportWidth || Math.abs(viewportHeight - this.viewportHeight) > this.viewportHeight * 0.15)) {
			this.viewportWidth = viewportWidth;
			this.viewportHeight = viewportHeight;
			TweenMax.set(this.element, {height: viewportHeight * this.sizeRatio + 'px'});
			TweenMax.set([this.teasersContainer, this.reflect, this.research], {height: this.viewportHeight + 'px'});
		}
		const rect = this.element.getBoundingClientRect();

		const inside  = rect.top <= 0 && rect.bottom >= viewportHeight;
		let percent;
		if (inside) {
			percent = -rect.top / (rect.height - viewportHeight);
		} else {
			percent = rect.bottom < viewportHeight ? 1 : 0;
		}
		if (!this.reflectEntered && rect.top < (viewportHeight / 2) && rect.bottom >= viewportHeight) {
			this.reflectEntered = true;
			this.classList(this.reflect).add('entered');
		}
		if (!this.researchEntered && rect.bottom > 0 && (
			((this.clipPathSupported && (this.stickySupported || !this.isMobile)) && rect.top <= -viewportHeight) ||
			(!(this.clipPathSupported && (this.stickySupported || !this.isMobile)) && rect.top <= -viewportHeight / 2)
			)) {
			this.researchEntered = true;
			this.classList(this.research).add('entered');
		}
		if (this.clipPathSupported && (this.stickySupported || !this.isMobile)) {
			const duration = 0;
			this.timeline.progress(percent);
			if (this.viewportWidth >= this.breakpoint) {
				if (!this.stickySupported) {
					this.classList(this.teasersContainer).toggle('fixed', inside);
				}
				TweenMax.to(this.researchContainer, duration, {y: this.dummy.containerY + '%', force3D: true});
				TweenMax.to(this.researchContent, duration, {y: this.dummy.contentY + '%', force3D: true});
			} else {
				if (!this.stickySupported) {
					this.classList(this.teasersContainer).remove('fixed');
				}
				TweenMax.to(this.researchContainer, duration, {y: '0%', force3D: false});
				TweenMax.to(this.researchContent, duration, {y: '0%', force3D: false});
			}
		}
	}

}


export default Teasers;
