import escape from '../../../global/utils/escape';


export default function tpl(data) {
    return `
        <div class="lsa-infographicBars" data-lsa-graph-container>
            <ul class="lsa-infographicBars__items">
        		${data.map((item, index) => `
                    <li class="lsa-infographicBars__item ${item.highlight ? 'lsa-infographicBars__item--highlighted' : ''}" data-lsa-bar data-lsa-info-index="${index}">
                        <div class="lsa-infographicBars__itemBg" data-lsa-bar-bg></div>
                        <div class="lsa-infographicTooltip lsa-infographicTooltip--bars" data-lsa-tooltip><p>${escape(item.label)}</p></div>
                    </li>
                `).join('')}
        	</ul>
        </div>
    `;
}
