import escape from '../../../global/utils/escape';

export default function tpl(data, tooltipsData, mapUrl, buttonsUrl) {
    return `
        <div class="lsa-infographicMap" data-lsa-graph-container>
            <div class="lsa-infographicMap__zoomButtons" style="background-image: url('${buttonsUrl}')">
                <button class="lsa-infographicMap__zoomButton lsa-infographicMap__zoomButton--zoomIn" data-lsa-zoom="in">
					<span class="lsa-infographicMap__zoomButtonLabel">Karte vergrössern</span>
				</button>
                <button class="lsa-infographicMap__zoomButton lsa-infographicMap__zoomButton--zoomOut" data-lsa-zoom="out">
					<span class="lsa-infographicMap__zoomButtonLabel">Karte verkleinern</span>
				</button>
            </div>

        	<div class="lsa-infographicMap__dots" data-lsa-dots>
        		<div class="lsa-infographicMap__map" data-lsa-map>
        			<div class="lsa-infographicMap__mapBg" style="background-image: url('${mapUrl}')"></div>
        			<ul class="lsa-infographicMap__items">
        				${data.map((item, index) => `
                            <li class="lsa-infographicMap__item lsa-js-notReady" data-lsa-dot data-lsa-info-index="${index}" style="left: ${item.x - item.size / 2}%; top: ${item.y - item.size / 2}%; width: ${item.size}%;"></li>
                        `).join('')}
        			</ul>
        		</div>
        	</div>
            <div class="lsa-infographicMap__tooltips">
        		<ul class="lsa-infographicMap__tooltipsItems" data-lsa-tooltips>
        			${tooltipsData.map((item, index) => `
                        <li class="lsa-infographicMap__tooltipsItem lsa-infographicMap__tooltipsItem--${item.type}" data-lsa-info-index="${index}"
                            data-lsa-tooltip style="left: ${item.left}; top: ${item.top}">
                            <div class="lsa-infographicTooltip lsa-infographicTooltip--map">${escape(item.label)}</div>
                        </li>
                    `).join('')}
        		</ul>
        	</div>
        </div>
    `;
}
