import DefaultPage from '../default-page';


class FactPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			this.addNewComponent('Accordion', {selector: this.dataSelector('accordion'), context: 'default'});
			done();
		});
	}

}


export default FactPage;
