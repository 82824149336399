//import asq from 'asynquence';
import Page from '../../global/page/page';


class DefaultPage extends Page {

	prepare(element, done) {
		this.addNewComponent('SectionCollector');
		this.addNewComponent('Pictures');
		this.addNewComponent('Videos');
        if (document.querySelector('html.no-object-fit')) {
            this.addNewComponent('Covers');
        }
		done();
	}

}

export default DefaultPage;
