import DefaultPage from '../default-page';


class StoryPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			// this.classList(element.closest(this.dataSelector('context', 'panel'))).add('storyPage');
			this.addNewComponent('Footnotes', {context: 'panel'});
			// 16:9 format
			const width = element.getBoundingClientRect().width;
			const height = Math.round(width * 9 / 16);
			this.player = this.addNewComponent('YoutubePlayer', {
				selector: this.dataSelector('player'),
				context: 'panel',
				width: width,
				height: height,
				videoOptions: {
					autoplay: 1,
					enablejsapi: 1,
					hl: 'de',
					loop: 0,
					modestbranding: 1,
					playsinline: 1,
					rel: 0,
					showinfo: 0,
				}
			});
			done();
		});
	}


	// clear(done) {
	// 	super.clear(() => {
	// 		console.log('clearing');
	// 		// this.classList(this.element.closest(this.dataSelector('context', 'panel'))).remove('storyPage');
	// 		done();
	// 	});
	// }

}


export default StoryPage;
