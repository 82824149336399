export default function escape(value, escapeSingleQuote = false, escapeTick = false) {
    value = String(value)
        .replace(/&/g, '&amp;') // first!
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
    ;

    if (escapeSingleQuote) {
        value = value.replace(/'/g, '&#39;');
    }

    if (escapeTick) {
        value = value.replace(/`/g, '&#96;');
    }

    return value;
}
