import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import Cookies from 'js-cookie';
// required for IE
import 'core-js/es6/promise';
import 'picturefill/src/plugins/mutation/pf.mutation.js';

import App from '../../global/app/app';


class LsaApp extends App {

	setDialog(dialog) {
		this.dialog = dialog;
	}


	setNavigationTracker(tracker) {
		this.tracker = tracker;
		tracker.init();
	}



	setGoogleTagManagerNavigationTracker(tracker) {
		this.gtmTtracker = tracker;
		tracker.init();
	}


	setFbNavigationTracker(tracker) {
		this.fbTracker = tracker;
		tracker.init();
	}

	setObNavigationTracker(tracker) {
		this.obTracker = tracker;
		tracker.init();
	}


	setHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	setLoaderAnimation(loaderAnimation) {
		this.loaderAnimation = loaderAnimation;
	}


	setPageSlotManager(pageSlotManager) {
		this.pageSlotManager = pageSlotManager;
	}


	setSectionManager(sectionManager) {
		this.sectionManager = sectionManager;
	}


	setColorSequence(colorSequence) {
		this.colorSequence = colorSequence;
	}


    prepare(element, done) {
        svg4everybody();
        picturefill();

		this.loaderAnimation.init(this.element.querySelector(this.loaderAnimation.getSelector()));
		this.loaderAnimation.activate();

		this.pageSlotManager.addNewPageSlot('', {name: 'default', selector: this.dataSelector('pageSlot', 'default')});
		this.pageSlotManager.addNewPageSlot('', {name: 'panel', selector: this.dataSelector('pageSlot', 'panel')});

		this.contextManager.addNewContext('default');
		this.contextManager.addNewContext('menu');
		this.contextManager.addNewContext('panel');
		this.contextManager.addNewContext('homeMap');
		this.contextManager.setDefaultContext('default', false);

		this.pageManager.appendNewPageTransition('panel');
		this.pageManager.appendNewPageTransition('defaultDirect');
		this.pageManager.appendNewPageTransition('default');

		this.contextSwitcher = this.addNewComponent('ContextSwitcher');
		this.mainMenu = this.addNewComponent('MainMenu');

		this.addComponent(this.colorSequence);
		this.addComponent(this.sectionManager);

		this.footerMenu = this.addNewComponent('ExclusionMenu', {selector: this.dataSelector('footerMenu'), context: 'default'});
		this.addNewComponent('SectionCollector', {selector: this.dataSelector('footer'), context: 'default'});

		done();
    }


	clear(done) {
		clearInterval(this.documentSizeInterval);
		this.scrollListener.destroy();
		this.resizeListener.destroy();
		done();
	}


	start() {
		this.pageManager.initCurrentPages(this.element, () => {
			this.loaderAnimation.pause();

			const ready = () => {
				if (this.pageSlotManager.get('panel').getCurrentPage()) {
					this.contextManager.push('panel');
				} else {
					this.contextManager.getContext('default').activate();
				}

				const hash = location.hash;
				if (hash.length) {
					this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
				}
			};

			const cookiesInfo = this.dataAttr(document.body).get('cookiesNotification');
			if (!Cookies.get(cookiesInfo.name)) {
				Cookies.set(cookiesInfo.name, 1, {expires: Number(cookiesInfo.expire)});
				this.dialog.open({title: cookiesInfo.title, text: cookiesInfo.text, buttons: [
					{
						label: cookiesInfo.buttonLabel,
						callback: (dialog) => {
							dialog.close();
							ready();
						}
					}
				]});
			} else {
				ready();
			}
		});
	}


}

export default LsaApp;
