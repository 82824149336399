import PageComponent from '../../../global/page/page-component';


class SchoolMore extends PageComponent {


    prepare(element, done) {
        this.videoElement = element.querySelector('[data-media="video"]');
        this.video = this.videoElement ? this.videoElement.wrapper : null;
		this.inside = false;
		if (this.video) {
			this.observer = new IntersectionObserver(this.onInside.bind(this), {
				threshold: [0]
			});
			this.observer.observe(element);
		}
        done();
    }


    clear(done) {
		if (this.video) {
			this.observer.disconnect();
		}
        this.videoElement = null;
        this.video = null;
        done();
    }


	start() {
		this.update();
	}


	stop() {
		if (this.video) {
			this.video.pause();
		}
	}


	onInside(entries) {
		for (const entry of entries) {
			if (entry.target === this.element) {
				const inside = entry.intersectionRatio > 0;
				if (inside !== this.inside) {
					this.inside = inside;
					this.update();
				}
			}
		}
	}


    update() {
		if (this.video) {
			if (this.inside && this.status.active) {
	            this.video.play();
	        } else {
	            this.video.pause();
	        }
		}
        return this;
    }


}


export default SchoolMore;
