import imagesLoaded from 'imagesloaded';

import PageComponent from '../../../global/page/page-component';


class ReblogFeatured extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {}} = {}) {
		super({context: context, selector: selector, config: config, status: status});
	}


	setCoverGeometryTracker(coverGeometryTracker) {
		this.coverGeometryTracker = coverGeometryTracker;
		this.coverGeometryTracker.autoRun = false;
	}


	setMediaContrastTracker(mediaContrastTracker) {
		this.mediaContrastTracker = mediaContrastTracker;
		this.mediaContrastTracker.autoRun = false;
	}


	prepare(element, done) {
		this.titleElement = document.querySelector(this.dataSelector('reblogTitle'));

		this.media = this.element.querySelector('img');
		const load = () => {
			if (this.media.currentSrc && this.media.currentSrc.length) {
				const i = new Image();
	            i.src = this.media.currentSrc;
				imagesLoaded([i], () => {
		            this.onLoad();
		        });
			} else {
				setTimeout(load, 20);
			}
		};
		load();
		done();
	}


	clear(done) {
		this.coverGeometryTracker.stop();
		this.mediaContrastTracker.stop().resetFlags();
		if (this.observer) {
			this.observer.disconnect();
			this.mediaResizeListener.destroy();
		}
		done();
	}


	onLoad() {
		if (this.status.ready) {
			this.inside = false;
	        this.coverGeometryTracker.setElement(this.media);
	        this.mediaContrastTracker.setElement(this.media);
			this.mediaResizeListener = this.events.on(this.element, 'media:resize', this.onMediaResize.bind(this));
			this.observer = new IntersectionObserver(this.onInside.bind(this), {
				threshold: 0
			});
			this.observer.observe(this.element);
		}
	}


	onInside(entries, observer) {
		for (const entry of entries) {
			const inside = entry.intersectionRatio > 0;
			if (inside !== this.inside) {
				this.inside = inside;
				this.update();
			}
			break;
		}
	}


	onMediaResize(event) {
		requestAnimationFrame(() => {
			const params = this.coverGeometryTracker.getValues();
	        if (this.inside) {
				const style = getComputedStyle(this.titleElement);
	            const paddingLeft = parseInt(style.paddingLeft, 10);
	            const paddingRight = parseInt(style.paddingRight, 10);
	            const paddingTop = parseInt(style.paddingTop, 10);
	            const paddingBottom = parseInt(style.paddingBottom, 10);
	            const textLeft = this.titleElement.offsetLeft + paddingLeft;
	            const textTop = this.titleElement.offsetTop + paddingTop;
	            const textWidth = this.titleElement.offsetWidth - paddingLeft - paddingRight;
	            const textHeight = this.titleElement.offsetHeight - paddingTop - paddingBottom;
	            const sourceLeft = textLeft - params.left;
	            const sourceTop = textTop - params.top;
	            this.mediaContrastTracker
	                .setSourceParams(sourceLeft, sourceTop, textWidth, textHeight, params.scale)
	                .setTargetParams(0, 0, textWidth, textHeight)
	                .start()
	            ;
	        } else {
	            this.mediaContrastTracker.stop();
	        }
		});
	}


	update() {
		if (this.inside) {
			this.coverGeometryTracker.start();
			this.mediaContrastTracker.start();
		} else {
			this.coverGeometryTracker.stop();
			this.mediaContrastTracker.stop();
		}
	}

}


export default ReblogFeatured;
