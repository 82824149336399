import PageComponent from '../../global/page/page-component';


class SectionManager extends PageComponent {


	setSectionFactory(sectionFactory, defaultTriggerPoints = [80], triggerWindowRatio = 0.1) {
		this.sectionFactory = sectionFactory;
		this.defaultTriggerPoints = defaultTriggerPoints;
		this.triggerWindowRatio = triggerWindowRatio;
	}


	prepare(element, params, done) {
		this.sections = new Map();
		this.contexts = new Map();
		this.observers = {};
		this.currentContext = this.contextManager.getCurrentContext().getName();
		this.currentSection = null;
		this.contextTriggers = document.querySelectorAll(this.dataSelector('contextTrigger'));

		this.contextListener = this.events.on(document, 'context:activate context:deactivate', this.onContextChange.bind(this));
	}


	registerSection(element, actions, triggerPoints) {
		if (!triggerPoints) {
			triggerPoints = this.defaultTriggerPoints;
		}
		const context = this.dataAttr(element).get('sectionContext');
		const modifiers = this.dataAttr(actions).get('actionsModifiers');
		const section = this.sectionFactory.newInstance('', {element: element, actions: actions, modifiers: modifiers, context: context});

		if (!this.contexts.has(context)) {
			this.contexts.set(context, new Set());
		}
		const entry = {section: section, context: context, inside: new Map()};
		this.sections.set(element, entry);
		this.contexts.get(context).add(entry);
		this.setupSectionObservers(entry, triggerPoints);

		return this;
	}


	unregisterSection(element) {
		if (this.sections.has(element)) {
			let entry = this.sections.get(element);
			for (const observer of entry.inside.keys()) {
				observer.unobserve(element);
				entry.inside.delete(observer);
			}
			let section = entry.section;
			this.sections.delete(element);
			this.contexts.get(entry.context).delete(entry);
			if (section === this.currentSection) {
				this.toggleSection(section, false);
			}
			entry.section = null;
			entry.inside = null;
			section = null;
			entry = null;
		}
		return this;
	}


	setupSectionObservers(entry, triggerPoints) {
		const section = entry.section;
		for (const point of triggerPoints) {
			let observer;
			if (!(point in this.observers)) {
				const rootMargin = '-' + point + '% 0% -' + (100 - point - this.triggerWindowRatio) + '% 0%';
				observer = new IntersectionObserver(this.onIntersection.bind(this), {
					rootMargin: rootMargin,
					threshold: [0]
				});
			} else {
				observer = this.observers[point];
			}

			entry.inside.set(observer, false);
			observer.observe(section.getElement());
		}
	}


	onIntersection(entries, observer) {
		for (const observedEntry of entries) {
			const element = observedEntry.target;
			if (this.sections.has(element)) {
				const entry = this.sections.get(element);
				const isInside = observedEntry.intersectionRatio > 0;
				if (entry.inside.has(observer)) {
					entry.inside.set(observer, isInside);
				}
				if (entry.context === this.currentContext) {
					this.toggleSection(entry.section, this.isSectionInside(entry));
				}
			}
		}
	}


	onContextChange(event) {
		const activate = event.type.toLowerCase() === 'context:activate';
		const context = event.detail.context.getName();
		if (this.currentSection) {
			this.toggleSection(this.currentSection, false);
		}
		if (activate) {
			this.currentContext = context;
			if (this.contexts.has(context)) {
				for (const entry of this.contexts.get(context).values()) {
					if (this.isSectionInside(entry)) {
						this.toggleSection(entry.section, true);
						break;
					}
				}
			}
		} else {
			this.currentContext = null;
		}
	}


	isSectionInside(entry) {
		for (const inside of entry.inside.values()) {
			if (inside) {
				return true;
			}
		}
		return false;
	}


	toggleSection(section, enabled) {
		section.toggle(enabled);
		this.setContextTriggerModifiers(section.getModifiers(), enabled);
		if (enabled) {
			if (this.currentSection !== null && this.currentSection !== section) {
				this.toggleSection(this.currentSection, false);
			}
			this.currentSection = section;
		} else if (this.currentSection === section) {
			this.currentSection = null;
		}
		return this;
	}


	setContextTriggerModifiers(modifiers, value) {
		if (modifiers.length) {
			for (const trigger of this.contextTriggers) {
				const classList = this.classList(trigger);
				for (const modifier of modifiers) {
					classList.toggle(modifier, value);
				}
			}
		}
	}

}


export default SectionManager;
