import 'dom4';
import PageComponent from '../../global/page/page-component';


class SectionCollector extends PageComponent {

	setSectionManager(sectionManager) {
		this.sectionManager = sectionManager;
	}


    prepare(element, done) {
		this.sections = [];
		const sections = element.queryAll(this.dataSelector('sectionContext'));
		if (element.matches(this.dataSelector('sectionContext'))) {
			sections.unshift(element);
		}
        for (const section of sections) {
			const triggerPoints = this.dataAttr(section).get('sectionTriggers');
			const actions = section.querySelectorAll(this.dataSelector('actions'));
			for (const actionElement of actions) {
				if (actionElement.closest(this.dataSelector('sectionContext')) === section) {
					this.sections.push(section);
					this.sectionManager.registerSection(section, actionElement, triggerPoints);
					break;
				}
			}
        }
		done();
    }


	clear(done) {
		for (const section of this.sections) {
			this.sectionManager.unregisterSection(section);
		}
		this.sections = null;
		done();
	}

}


export default SectionCollector;
