import ExclusionMenu from '../../menu/exclusion-menu';


class HomeSchools extends ExclusionMenu {

	setMediaSequence(mediaSequence) {
		this.mediaSequence = mediaSequence;
	}


    prepare(element, done) {
		this.videos = {};
        this.googleMap = null;
        this.googleMapContainer = null;
		super.prepare(element, done);
    }


    clear(done) {
        super.clear(() => {
            this.videos = {};
			done();
        });
    }


    over(index) {
        super.over(index);
		if (index < this.mediaSequence.getLength()) {
			this.mediaSequence
	            .pauseTimer()
	            .setIndex(index)
	            .play();
		}
    }

    out(index) {
        super.out(index);
		if (index !== false && index !== true && index < this.mediaSequence.getLength()) {
			this.mediaSequence
	            .pause()
	            .next()
	            .play()
	            .restartTimer();
		}
    }

}


export default HomeSchools;
