import onTransitionEnd from '../../global/utils/transition-end';
import LoaderAnimation from '../../global/animation/loader-animation';


class LsaLoaderAnimation extends LoaderAnimation {

    play(done = () => {}, options = {}) {
        if (this.classList(this.element).contains('loading')) {
            done();
            return;
        }
        const classes = ['loading'];
        if (options.cover) {
            classes.push('cover');
        }
        this.classList(document.body).add('hideMenu');
        this.classList(this.element).add(...classes);
        onTransitionEnd(this.element, done);
    }


    pause(done = () => {}, options = {}) {
        this.classList(document.body).remove('noscroll', 'hideMenu');
        this.classList(this.element).remove('loading', 'cover');
        onTransitionEnd(this.element, done);
    }

}

export default LsaLoaderAnimation;
