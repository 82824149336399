import {TweenMax, TimelineMax} from 'gsap';
import shuffle from '../../global/utils/shuffle';
import getPointerPosition from '../../global/utils/get-pointer-position';
import Infographic from './infographic';
import graphTemplate from './templates/map-infographic';


class MapInfographic extends Infographic {


	constructor({context = null, selector = true, config = {}, status = {played: false, available: false}, duration = 0.8, delay = 0.05, autoHighlightFirst = true, selectedClass = 'selected', tooltipsNoFlyZoneSize = 15} = {}) {
		super({context: context, selector: selector, config: config, status: status});
		this.duration = duration;
        this.delay = delay;
        this.autoHighlightFirst = autoHighlightFirst;
		this.selectedClass = selectedClass;

		// percent of the width from lateral borders when the tooltips cannot be vertically positioned
		this.tooltipsNoFlyZoneSize = tooltipsNoFlyZoneSize;
	}


    initGraph() {
        this.current = null;
        this.isZoomedIn = false;
        this.dataContainer = this.element.querySelector(this.dataSelector('data'));
        this.graphContainer = this.element.querySelector(this.dataSelector('graph'));

        // temporary workaround, we need to enforce datetime in assets' name in css to avoid this
        this.mapUrl = this.dataAttr(this.element).get('mapUrl');
        this.buttonsUrl = this.dataAttr(this.element).get('buttonsUrl');

		if (this.dataAttr(this.element).has('duration')) {
            this.duration = this.dataAttr(this.element).get('duration');
        }

		if (this.dataAttr(this.element).has('delay')) {
            this.delay = this.dataAttr(this.element).get('delay');
        }

		if (this.dataAttr(this.element).has('delay')) {
            this.delay = this.dataAttr(this.element).get('delay');
        }

        this.fetchData();
        this.render();
        this.initEvents();
    }


    deinitGraph() {
        this.deinitEvents();
        this.data = null;
        this.bars = null;
        this.dataContainer = null;
        this.graphContainer = null;
    }


    fetchData() {
        this.data = [];
        for (const element of this.dataContainer.querySelectorAll(this.dataSelector('country'))) {
            this.data.push(this.dataAttr(element).get('country'));
        }
		// quick way to show the smaller elements on top
        this.data.sort((a, b) => (b.size - a.size));
        return this;
	}


	render() {
        const tooltipsData = [];
        let i = 0;
        for (const item of this.data) {
            const height = item.size;
            const type = (this.tooltipsNoFlyZoneSize >= item.x ? 'sideRight' : (100 - this.tooltipsNoFlyZoneSize <= item.x ? 'sideLeft' : 'center'));
            let left;
            let top;
            if (type === 'center') {
                left = item.x + '%';
                top = (item.y - height / 2) + '%';
            } else {
                top = item.y + '%';
                if (type === 'sideRight') {
                    left = (item.x + item.size / 2) + '%';
                } else {
                    left = (item.x - item.size / 2) + '%';
                }
            }
            tooltipsData.push({
                type: type,
                left: left,
                top: top,
                label: this.data[i].label
            });
            i++;
        }
		this.graphContainer.innerHTML = graphTemplate(this.data, tooltipsData, this.mapUrl, this.buttonsUrl);
        this.graphContainer = this.graphContainer.querySelector(this.dataSelector('graphContainer'));

		this.dotsContainer = this.graphContainer.querySelector(this.dataSelector('dots'));
		this.dots = this.graphContainer.querySelectorAll(this.dataSelector('dot'));
		this.tooltipsContainer = this.graphContainer.querySelector(this.dataSelector('tooltips'));
		this.tooltips = this.tooltipsContainer.querySelectorAll(this.dataSelector('tooltip'));
		this.dotsContainer = this.graphContainer;
		this.innerContainer = this.graphContainer.querySelector(this.dataSelector('map'));
        return this;
	}



    initEvents() {
        this.overHandler = this.events.on(this.graphContainer, this.dataSelector('dot'), 'mouseover touchstart', this.onMouseOver.bind(this));
        this.outHandler = this.events.on(this.graphContainer, this.dataSelector('dot'), 'mouseout', this.onMouseOut.bind(this));
        this.zoomInHandler = this.events.on(this.element, this.dataSelector('zoom', 'in'), 'mousedown touchstart', this.onZoomIn.bind(this), {capture: true});
        this.zoomOutHandler = this.events.on(this.element, this.dataSelector('zoom', 'out'), 'mousedown touchstart', this.onZoomOut.bind(this), {capture: true});

        this.downHandler = this.events.on(this.element, 'mousedown touchstart', this.onMouseDown.bind(this));
        this.upHandler = this.events.on(this.element, 'mouseup touchend', this.onMouseUp.bind(this));
        this.moveHandler = this.events.on(this.element, 'mousemove touchmove', this.onMouseMove.bind(this));
        this.touchHandler = this.events.on(document, 'touchstart', this.onTouchStart.bind(this));
    }


    deinitEvents() {
        this.overHandler.destroy();
        this.outHandler.destroy();
        this.zoomInHandler.destroy();
        this.zoomOutHandler.destroy();
        this.downHandler.destroy();
        this.upHandler.destroy();
        this.moveHandler.destroy();
        this.touchHandler.destroy();
    }


    play() {
        const timeline = new TimelineMax();
        // animate the dots in random order
        const dots = Array.prototype.slice.call(this.dots, 0);
        shuffle(dots);

		for (let i = 0; i < dots.length; i++) {
			const dot = dots[i];
            timeline.addCallback(() => { this.classList(dot).remove('notReady'); }, this.delay * i);
		}
        timeline
            .eventCallback('onComplete', () => {
                this.status.available = true;
            })
            .play();
    }


	onZoomIn(event) {
		event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
		if(this.status.available && !this.isZoomedIn) {
            this.x = 0;
            this.y = 0;
            this.lastX = 0;
            this.lastY = 0;
			this.classList(this.innerContainer).add('zoomIn');
			this.classList(this.tooltipsContainer).add('zoomIn');
            TweenMax.to([this.innerContainer, this.tooltipsContainer], this.duration / 3, {scale: 2, ease: 'Power3.easeOut'});
			this.mapW = parseInt( this.innerContainer.clientWidth, 10);
			this.mapH = parseInt( this.innerContainer.clientHeight, 10);
            this.isZoomedIn = true;
		}
        return false;
	}


	onZoomOut(event) {
		event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
		if(this.status.available && this.isZoomedIn) {
			this.innerContainer.classList.remove('zoomIn');
			this.tooltipsContainer.classList.remove('zoomIn');
            TweenMax.to([this.innerContainer, this.tooltipsContainer], this.duration / 3, {scale: 1, x: '0px', y: '0px', ease: 'Power3.easeOut'});
            this.isZoomedIn = false;
		}
        return false;
	}


    onMouseOver(event, target) {
        if (this.status.available) {
            event.stopPropagation();
            event.preventDefault();
            this.selectElement(this.dataAttr(target).get('infoIndex'));
        }
	}


	onMouseOut(event) {
        if (this.status.available) {
            this.deselectCurrentElement();
        }
	}


    onTouchStart(event) {
		if (this.current !== null) {
			this.deselectCurrentElement();
        }
	}


	onMouseDown(event) {
        if (this.status.available && this.isZoomedIn) {
            event.preventDefault();
            this.firstPointerPosition = getPointerPosition(event);
            this.mouseDown = true;
        }
	}


    onMouseUp(event) {
        if (this.status.available && this.mouseDown) {
            this.mouseDown = false;
            this.x = this.lastX;
            this.y = this.lastY;
        }
	}


	onMouseMove(event) {
		if (this.status.available && this.isZoomedIn && this.mouseDown) {
            event.preventDefault();
            const currentPointerPosition = getPointerPosition(event);
            this.lastX = Math.max((-this.mapW / 2), Math.min((this.mapW / 2), (currentPointerPosition.x - this.firstPointerPosition.x + this.x)));
            this.lastY = Math.max((-this.mapH / 2), Math.min((this.mapH / 2), (currentPointerPosition.y - this.firstPointerPosition.y + this.y)));
            TweenMax.set([this.innerContainer, this.tooltipsContainer], {x: this.lastX + 'px', y: this.lastY});
        }
	}


	selectElement(id) {
		if(this.current === id) {
            return;
        }
		this.classList(this.graphContainer).add(this.selectedClass);
		if (this.current !== null) {
			this.classList(this.dotsContainer.querySelector(this.dataSelector('infoIndex', this.current))).remove(this.selectedClass);
			this.classList(this.tooltipsContainer.querySelector(this.dataSelector('infoIndex', this.current))).remove(this.selectedClass);
		}
		this.current = id;
		this.classList(this.dotsContainer.querySelector(this.dataSelector('infoIndex', this.current))).add(this.selectedClass);
		this.classList(this.tooltipsContainer.querySelector(this.dataSelector('infoIndex', this.current))).add(this.selectedClass);
	}


	deselectCurrentElement() {
		this.classList(this.graphContainer).remove(this.selectedClass);
		if (this.current !== null) {
			this.classList(this.dotsContainer.querySelector(this.dataSelector('infoIndex', this.current))).remove(this.selectedClass);
			this.classList(this.tooltipsContainer.querySelector(this.dataSelector('infoIndex', this.current))).remove(this.selectedClass);
		}
        this.current = null;
	}

}

export default MapInfographic;
