import DefaultPage from '../default-page';


class ToolLandingPage extends DefaultPage {

	prepare(element, done) {
		super.prepare(element, () => {
			//const sectionActions = this.addNewComponent('SectionActions', {selector: this.dataSelector('slideshowSection'), context: 'default'});
			//const slideshow = this.addNewComponent('BasicSlideshow', {selector: this.dataSelector('slideshowSection'), context: 'default'});
			//slideshow.setSectionActions(sectionActions);

			if (element.querySelector(this.dataSelector('externalVideo'))) {
				this.addNewComponent('ExternalVideo', {selector: this.dataSelector('externalVideo'), context: 'default'});
			}

			done();
		});
	}

}


export default ToolLandingPage;
