import PageComponentCollection from '../../global/page/page-component-collection';

class Distances extends PageComponentCollection {

	injectIntersectionTriggers(intersectionTriggers) {
		this.intersectionTriggers = intersectionTriggers;
	}

	injectDataParser(dataParser) {
		this.dataParser = dataParser;
	}

	prepare(element, done) {
		this.dataParser.parseCarKmArray(this.dataAttr(element).get('carKmArray'));
		this.dataParser.parseCarTimeArray(this.dataAttr(element).get('carTimeArray'));
		this.dataParser.parseTrainTimeArray(this.dataAttr(element).get('trainTimeArray'));
		this.dataParser.parseIdCityArray(this.dataAttr(element).get('idCityArray'));

		this.dataParser.findLongestJourney();

		const sliderValues = [];
		for(let i=0; i<this.dataParser.getConnectionsNo(); i++) {
				sliderValues.push(i);
		}

		this.slider = this.addNewComponent('DistancesSlider', {selector: this.dataSelector('distancesSlider'), values: sliderValues});

		this.map = this.addNewComponent('DistancesMap', {selector: this.dataSelector('distancesMap')});

		this.info = this.addNewComponent('DistancesInfo', {selector: this.dataSelector('distancesInfo')});

		this.circleIndicators = this.addNewComponent('DistancesCircleIndicators', {selector: this.dataSelector('distancesCircles')});
		this.circleIndicators.setMaxTime(this.dataParser.getMaxTime());

		this.sliderListener = this.events.on(element, this.dataSelector('distancesSlider'), 'slider:change', this.onSliderChange.bind(this));
		this.cityListener = this.events.on(element, this.dataSelector('distancesMap'), 'map:cityclick', this.onCityClick.bind(this));

		done();
	}

	start() {
		this.chooseFirst();
	}

	chooseFirst() {
		const currentData = this.dataParser.getDataByPosition(1);
		this.updateMap(currentData);
		this.updateLabels(currentData);
		this.updateCircles(currentData);
	}

	onSliderChange(event) {
		this.currentConnection = event.detail.value;
		const currentData = this.dataParser.getDataByPosition(this.currentConnection);
		this.updateMap(currentData);
		this.updateLabels(currentData);
		this.updateCircles(currentData);
	}

	onCityClick(event) {
		const clickedCity = event.detail.cityid;
		const currentData = this.dataParser.getDataByFromCityId(clickedCity);
		this.slider.resetValue(currentData.position);
		this.updateMap(currentData);
		this.updateLabels(currentData);
		this.updateCircles(currentData);
	}

	updateMap(currentData) {
		this.map.showCurrentConnection(currentData);
	}

	updateLabels(currentData) {
		this.info.showCityLabels(this.dataParser.getCityNameById(currentData.from), this.dataParser.getCityNameById(currentData.to));
		this.slider.setValueLabel(currentData.val);
	}

	updateCircles(currentData) {
		this.circleIndicators.setOuter(this.dataParser.getTrainDataById(currentData.basePosition));
		this.circleIndicators.setInner(this.dataParser.getCarDataById(currentData.basePosition));
		this.circleIndicators.show();
	}
}

export default Distances;
