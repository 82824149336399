import PageComponent from '../../global/page/page-component';

class DistancesInfo extends PageComponent {

	constructor({context = null, selector = true, config = {}, status = {},
		showClass ='show'}) {
		super({context: context, selector: selector, config: config, status: status});
		this.showClass = showClass;
	}

	prepare(element, done) {
		this.labelFrom = element.querySelector(this.dataSelector('distancesCityFrom'));
		this.labelTo = element.querySelector(this.dataSelector('distancesCityTo'));
		done();
	}

	showCityLabels(from, to) {
		this.classList(this.element).add(this.showClass);
		this.labelFrom.innerHTML = from;
		this.labelTo.innerHTML = to;
	}
}

export default DistancesInfo;
