import pageInteractionMixin from '../../global/utils/page-interaction-mixin';


class Section extends pageInteractionMixin() {

	constructor({element, actions, modifiers, context, activeClass = 'active', enableEvent = 'enable', disableEvent = 'disable'}) {
		super();
		this.element = element;
		this.actions = actions;
		this.modifiers = modifiers;
		this.context = context;
		this.enabled = false;
		this.activeClass = activeClass;
		this.enableEvent = enableEvent;
		this.disableEvent = disableEvent;
	}


	getElement() {
		return this.element;
	}


	getContext() {
		return this.context;
	}


	getModifiers() {
		return this.modifiers;
	}


	enable() {
		return this.toggle(true);
	}


	disable() {
		return this.toggle(false);
	}


	toggle(enabled) {
		if (enabled !== this.enabled) {
			this.enabled = enabled;
			this.events.trigger(this.element, 'section:' + (enabled ? this.enableEvent : this.disableEvent), {
				section: this,
				sectionsMenu: this.menu
			});
			if (this.actions) {
				this.classList(this.actions).toggle(this.activeClass, enabled);
			}
		}
		return this;
	}

}


export default Section;
